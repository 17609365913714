import React from 'react';
import { BooleanCell } from '../../../../components/common/BooleanCell';
import { TraditionalLevelEnum } from '../../../abroad/road-map-step/interfaces';
import { t } from 'i18next';
import { DayPickerRangeSelector } from '../../../../components/common/DayRangePicker/indext';
import AllExcel from '../../../../components/common/AllExcel';
import { UserSearchStudent } from '../user-search';
import moment from 'moment';
import { Classroom } from '../../../booking-dashboard/interfaces';
import { Campus } from '../../../abroad/campus/interfaces';
import { UseClassroomAll } from '../../../admin/classroom/services';
import { showSimpleAlert } from '../../../../commonHooks/alert';
import { UseActivityAll } from '../../../abroad/activity/services';
import { UseCampusAll } from '../../../abroad/campus/services';
import { DayHourData } from '../list-trail-class/table-hour-resume';
import { Statistics } from '../list-trail-class/utils';
import { WidgetTrailClassResume } from '../list-trail-class/wideget';
import { UseSurveySectionResponseFilterModules } from '../../store/survey-section-res-filter.store';
import { FindResumeSurveySectionUseCase } from '../../../admin/survey-section/modules/survey-section-response/services';
import { SurveyResponseReport } from '../../interface';
import { useThemeMode } from '../../../../../_metronic/partials';
import { BlockHour } from '../../../abroad/block-hours/interfaces';
import { ModalSurveySectionResponse } from '../../../admin/survey-section/modules/survey-section-response/components/modal';
import { UseSurveySectionResponseModule } from '../../../admin/survey-section/modules/survey-section-response/store/main';
import { UseUserTypePaginate } from '../../../admin/user/services';
import { User } from '../../../admin/user/interfaces';

const SurveySectionResponseReportList: React.FC = () => {
  const { mode } = useThemeMode()
  const {
    before,
    after,
    level,
    stars,
    teacher,
    typeClass,
    campus,
    typeClassroom,
    activity,
    isActive,
    classroom,
    student,
    setDate,
    setStars,
    setTeacher,
    setTypeClass,
    setTypeClassroom,
    setIsActive,
    setActivity,
    setCampus,
    setClassroom,
    setStudent,
    setLevel
  } = UseSurveySectionResponseFilterModules();

  const {
    modal,
    setSelectedToView
  } = UseSurveySectionResponseModule();
  const [dataResume, setDataResume] = React.useState<any>();
  const [modeTable, setModeTable] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [summary, setSummary] = React.useState<DayHourData[]>();
  const [activityData, setActivityData] = React.useState<any[]>([]);
  const [campusData, setCampusData] = React.useState<Campus[]>([]);
  const [teacherData, setTeacherData] = React.useState<User[]>([]);
  const [statistics, setStatistics] = React.useState<Statistics | null>(null);

  const fetchData = async () => {
    setIsLoading(true);
    const response = await FindResumeSurveySectionUseCase();
    setDataResume(response);
    setIsLoading(false);
  };

  const fetchAllActivity = async () => {
    try {
      let classroomResponse = await UseActivityAll();
      setActivityData(classroomResponse)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar actividades!', icon: 'error' })
      throw 'Error'
    }
  };

  const fetchAllCampus = async () => {
    try {
      let response = await UseCampusAll();
      setCampusData(response);
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar actividades!', icon: 'error' })
      throw 'Error'
    }
  };

  const fetchTeacher = async () => {
    try {
      let teacher = await UseUserTypePaginate('TEACHER')
      setTeacherData(teacher);
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar profesores!', icon: 'error' });
      throw 'Error'
    }
  };

  const theadName = [
    'Dia',
    "6:00",
    "7:00",
    "8:00",
    "9:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00"
  ];

  React.useEffect(() => {
    fetchAllActivity();
    fetchAllCampus();
    fetchTeacher();
  }, []);

  const filterBlockHour = (blockHour: BlockHour[]) => {
    if (!blockHour || blockHour.length < 1) return '';

    return blockHour[0].start + ' - ' + blockHour[blockHour.length - 1].end
  };

  return (<>
    <div className={`card `}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Clases</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Gestión de Clases</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
        </div>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <button
            onClick={() => setIsActive(!isActive)}
            className='btn btn-sm btn-light-secondary  mr-8 p-2'
          // data-bs-toggle='modal'
          // data-bs-target='#kt_modal_invite_friends'
          >
            <BooleanCell value={isActive} />
          </button>
        </div>
      </div>
      {/* end::Header */}

      <div className='py-3 row'>
        {/* begin::Filters */}
        <div className='mb-5 d-flex align-items-center overflow-x-auto px-4'>
          <div className='col-1 mx-3'>
            <label className='form-label mb-0 me-2'>Actividades</label>
            <select
              name='activity'
              value={activity}
              onChange={(e) => setActivity(e.target.value)}
              className='form-select'
              aria-label='Default select example'
            >
              <option value={undefined}></option>
              {activityData.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
            </select>
          </div>

          <div className='col-1'>
            <label className='form-label mb-0'>Level</label>
            <select
              name='level'
              value={level}
              onChange={(e) => setLevel(e.target.value)}
              className='form-select'
              aria-label='Default select example'
            >
              <option value={undefined}></option>
              {Object.keys(TraditionalLevelEnum).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
          </div>

          <div className="col-1 mx-3">
            <label className='form-label mb-0 me-2'>Tipo salon</label>
            <select
              name={'typeClassroom'}
              value={typeClassroom}
              onChange={(e) => setTypeClassroom(e.target.value)}
              className="form-select"
              aria-label="Default select example"
            >
              <option value={undefined}></option>
              <option value={'VIRTUAL'}>Virtual</option>
              <option value={'FACE'}>Presencial</option>
            </select>
          </div>

          <div className='col-1 mx-3'>
            <label className='form-label mb-0 me-2'>Estrellas</label>
            <select
              name='stars'
              value={stars}
              onChange={(e) => setStars(parseInt(e.target.value))}
              className='form-select'
              aria-label='Default select example'
            >
              <option value={undefined}></option>
              {[1, 2, 3, 4, 5].map((x) => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </select>
          </div>

          <div className='col-1 mx-3'>
            <label className='form-label mb-0 me-2'>Campus</label>
            <select
              name='campus'
              value={campus}
              onChange={(e) => setCampus(e.target.value)}
              className='form-select'
              aria-label='Default select example'
            >
              <option value={undefined}></option>
              {campusData.map((x) => (
                <option key={x.id} value={x.id}>
                  {x.name}
                </option>
              ))}
            </select>
          </div>

          <div className='col-1 mx-3'>
            <label className='form-label mb-0 me-2'>Tipo de clase</label>
            <select
              name='typeClass'
              value={typeClass}
              onChange={(e) => setTypeClass(e.target.value)}
              className='form-select'
              aria-label='Default select example'
            >
              <option value={undefined}></option>
              <option value={'CLASS'}>Normal</option>
              <option value={'WORKSHOP'}>Workshop</option>
            </select>
          </div>

          <div className='col-1 mx-3'>
            <label className='form-label mb-0 me-2'>Profesor</label>
            <select
              name='teacher'
              value={teacher}
              onChange={(e) => setTeacher(e.target.value)}
              className='form-select'
              aria-label='Default select example'
            >
              <option value={undefined}></option>
              {teacherData.map((x, i) => (
                <option key={x.id} value={x.id}>{x.fullName}</option>
              ))}
            </select>
          </div>

        </div>
        {/* end::Filters */}
      </div>
      {/* begin::Body */}
      <div className='py-3 row'>
        {/* begin::Table container */}
        <div className='mb-10 col-3'>
          <UserSearchStudent
            setStudent={(e) => setStudent(e.id)}
          />
          {summary && <AllExcel
            dataExcel={summary}
            filename={`classes` + moment().format('yyyymmddhhmmsss')}
            sheetName='Estudiantes'
            theadName={theadName}
            type='all'
            backgroundColor={'green'}
            titleColor={'white'}
          />}
          <DayPickerRangeSelector
            after={after}
            before={before}
            changeDate={(e) => setDate({
              after: e.after,
              before: e.before
            })}
          />

        </div>
        <div className='mb-10 col-9'>

          <div className="btn-group" role="group" aria-label="Basic example">
            <button onClick={() => setModeTable(false)} type="button" className="btn btn-primary">Resumen</button>
            <button onClick={() => setModeTable(true)} type="button" className="btn btn-primary">Lista de reservas</button>
            <button disabled={isLoading} onClick={() => fetchData()} type="button" className="btn btn-primary">
              {isLoading ? "Cargando.." : "Buscar"}
            </button>
          </div>
          {statistics && dataResume && dataResume.result.length > 0 && !modeTable && summary && <WidgetTrailClassResume
            data={summary}
            length={dataResume?.result.length}
            statistics={statistics} />}
          <div className='table-responsive' style={{ marginTop: 0 }}>
            {isLoading ?
              <span className='indicator-progress d-flex justify-content-center  align-items-center ms-5'>
                <span className='spinner-border spinner-border-lg align-middle text-primary' />
              </span>
              :
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted  text-start'>
                    <th className='min-w-140px text-start'>Nivel</th>
                    <th className='min-w-140px text-start'>Estudiante</th>
                    <th className='min-w-120px text-start'>Fecha y hora de la clase</th>
                    <th className='min-w-120px  text-start'>Profesor</th>
                    <th className='min-w-120px text-start'>Actividad</th>
                    <th className='min-w-120px text-start'></th>
                  </tr>
                </thead>
                <tbody>
                  {dataResume && dataResume?.result.map((x: SurveyResponseReport) => (
                    <tr key={x.id}
                      className={`p-0 rounded`}
                    >
                      <td className='p-0'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className=' fw-bold text-hover-primary fs-6'>
                              {t(x.student_setting.level)}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {[1, 2, 3, 4, 5].map((value) => (
                                <label key={value} className={`me-1 cursor-pointer`}>
                                  <input
                                    type='radio'
                                    name='star-rating'
                                    value={value}
                                    checked={x.stars === value}
                                    style={{ display: 'none' }} // Ocultar el radio button
                                    disabled
                                  />
                                  <i
                                    className={`fa-${x.stars >= value ? 'solid' : 'regular'
                                      } fa-star d-inline-block`}
                                    style={{ fontSize: 12, color: mode === 'light' ? '#2d30e1' : 'white' }}
                                  ></i>
                                </label>
                              ))}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='p-0'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='fw-bold text-hover-primary fs-6'>
                              {x.student_setting.user.fullName}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {x.student_setting.user.email}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='p-0'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='fw-bold text-hover-primary fs-6'>
                              {x?.appointment.start}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {filterBlockHour(x?.appointment.blockHour)}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td className='text-start'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='fw-bold text-hover-primary fs-6'>
                              {x?.appointment.teacher.fullName}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {x.appointment.teacher.email}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='text-start'>
                        <div className='d-flex align-items-center'>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='fw-bold text-hover-primary fs-6'>
                              {x?.appointment.activity.name}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {x.appointment.activity.toWorkshop}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <button
                            onClick={() => setSelectedToView({
                              isActive: true,
                              //@ts-ignore
                              appointment: x.appointment,
                              answers: x.answers,
                              stars: x.stars,
                              //@ts-ignore
                              student_setting: x.student_setting
                            })}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i style={{ color: 'grey', fontSize: 17 }} className="fas fa-eye"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>

              </table>
            }
          </div>

        </div>
      </div>
    </div>
    <ModalSurveySectionResponse show={modal} handleClose={() => alert()} />

  </>)
};

export { SurveySectionResponseReportList };