import React  from 'react';
import { CheckWritingExItem } from './components/item';
import { UseCheckWritingModule } from './store';
import { CheckWritingSingleExItem } from './components/item-single';
import { ModeTypeExercise } from '../../../book/components/RenderExerciseBook';
import { CheckWritingType } from './interfaces';


type Props = {
    userMode?: ModeTypeExercise;
    type?: CheckWritingType;
};

const CheckWritingEx: React.FC<Props> = ({userMode, type}) => {
    let {
        questions,
        themes,
        answer
    } = UseCheckWritingModule();

    return (<>
        {type === CheckWritingType.CHECK_WRITING_DESCRIPTION ?
            <CheckWritingExItem
                userMode={userMode}
                theme={themes}
                question={questions}
                response={answer}
            />
        : type === CheckWritingType.CHECK_WRITING_SINGLE &&
            <CheckWritingSingleExItem
                userMode={userMode}
                question={questions}
                responses={answer?.answer}
            />
        }
    </>);
};
export { CheckWritingEx };