import React, { useEffect } from 'react'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { CreateModal } from '../modal'
import { useQueryClient } from '@tanstack/react-query'
import { useUserState } from '../../hooks/useUserRepo'
import { UseUserModule } from '../../store/main'
import { User } from '../../interfaces'
import { BooleanCell } from '../../../../../components/common/BooleanCell'
import * as Swal from 'sweetalert2'
import { UserDelete } from '../../services'
import { showSimpleAlert } from '../../../../../commonHooks/alert'
import GenericPaginator from '../../../../../components/common/Paginator'
import supabase from '../../../../../supabase/supabase';
import IconFa from '../../../../../components/common/Icon/indext'
import { ButtonSelector } from '../../../../../components/common/ButtonSelector'
import useAuth from '../../../../../api/main'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ActionsUserModal } from '../modal/actionsmodal'
import { CredentialsModal } from '../modal/credentials.modal'
type PaginatorSetter = {
  currentPage: number;
  items: number;
  total: number;
  totalPage: number;
}
type Props = {

}
const UserTableList: React.FC<Props> = ({ }) => {

  const { t } = useTranslation();

  const queryClient = useQueryClient()
  const {
    paginator,
    modal,
    limit,
    currentPage,
    isActive,
    where,
    type,
    setCurrentPage,
    setLimit,
    setIsActive,
    setSelectedToEdit,
    setSelectedToView,
    setModal,
    setWhere,
    setType,
    setToAdd,
    setSelectedToCredentials,
    usersToActions,
    addUser,
    removeUser,
    setActionsModal,
    setActionsMode,
    actionsModal,
    modalCredential
  } = UseUserModule()
  const { data, isLoading } = useUserState();
  const { typeUser } = useAuth.getState()

  let [page, setPage] = React.useState<PaginatorSetter>({
    currentPage: 1,
    items: 0,
    total: 0,
    totalPage: 0
  });

  const checkIsSelected = (id: string) => {
    return usersToActions.some((x) => x.id === id)
  }
  const deleteItem = async (id: string) => {
    //@ts-ignore
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await UserDelete(id)
          showSimpleAlert({ message: 'Ok!', icon: 'success' })
        } catch (e) {

        }
      }
    })
  }
  const changePage = (number: number) => {
    setCurrentPage(number)
  };
  const changeLimit = (number: number) => {
    setLimit(number)
  }
  const setTypeUser = (type: any) => {
    setType(type)
  }
  // const test = async () => {
  //   supabase
  // .channel('any')
  // .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .subscribe()
  // }
  // useEffect(()=>{
  //   test()
  // },[])
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['user'] })
  }, [isActive, where, currentPage, limit, type])
  return (<>
    <div className={`card `}>

      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Users</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Gestión de usuarios </span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
        >

          {(typeUser === 'ADMIN' || typeUser === 'ROOT') && <ButtonSelector
            type='ROOT'
            text='Super Admin'
            isActive={type === 'ROOT' ? true : false}
            setSelector={(e) => setType(e)}
            icon='fas fa-user-cog'
          />}
          {(typeUser === 'ADMIN' || typeUser === 'ROOT') && <ButtonSelector
            type='ADMIN'
            text='Admin'
            isActive={type === 'ADMIN' ? true : false}
            setSelector={(e) => setType(e)}
            icon='fas fa-users-cog'
          />}
          {(typeUser === 'ADMIN' || typeUser === 'ROOT') && <ButtonSelector
            type='ASSISTANT'
            text='Asistente'
            isActive={type === 'ASSISTANT' ? true : false}
            setSelector={(e) => setType(e)}
            icon='fas fa-users-cog'
          />}
          {(typeUser === 'ADMIN' || typeUser === 'ROOT') && <ButtonSelector
            type='ASSISTANT_WELCOME'
            text='Asistente Welcome'
            isActive={type === 'ASSISTANT_WELCOME' ? true : false}
            setSelector={(e) => setType(e)}
            icon='fas fa-users-cog'
          />}
          <ButtonSelector
            type='TEACHER'
            text='Tutor'
            isActive={type === 'TEACHER' ? true : false}
            setSelector={(e) => setType(e)}
            icon='fas fa-chalkboard-teacher'
          />

          {
            usersToActions.length > 0 &&
            <div className="btn-group">
              <button className="btn btn-secondary btn-sm" type="button">
                Acciones
              </button>
              <button type="button" className="btn  btn-sm btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                <span className="visually-hidden">Toggle Dropdown</span>
              </button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="#"
                  onClick={() => {
                    setActionsModal(true)
                    setActionsMode('CHAT')
                  }}
                >Vincular al chat</a></li>
              </ul>
            </div>}

          {/* <ButtonSelector
              type='STUDENT'
              text='Estudiante'
              isActive={type==='STUDENT'?true:false}
              setSelector={(e)=>setType(e)}
              icon='fas fa-user-tag'
        /> */}
          <div className='position-relative my-1'>
            <KTSVG
              path='/media/svg/icons/earth.svg'
              className='svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-10'
            />
            <input
              type='text'
              onChange={(e) => setWhere(e.target.value)}
              className='form-control form-control-sm form-control-solid w-350px ps-10'
              name='Search'
              value={where}
              placeholder='Search'
            />
          </div>
          <OverlayTrigger overlay={<Tooltip>Add new {t(`${type}`)}</Tooltip>} placement='bottom'>
            <button
              onClick={() => setToAdd()}
              className='btn btn-sm btn-light-primary mr-8'
              disabled={!(typeUser === 'ROOT' || typeUser === 'ADMIN' || typeUser === 'ASSISTANT' || typeUser === 'ASSISTANT_WELCOME')}
            >
              <KTIcon iconName='plus' className='fs-3' />
            </button>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>{`Filter by ${isActive ? "unactive" : "active"}`}</Tooltip>} placement='bottom'>
            <button
              onClick={() => setIsActive(!isActive)}
              className='btn btn-sm btn-light-secondary  mr-8 p-2'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
            >
              <BooleanCell value={isActive} />
            </button>
          </OverlayTrigger>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {isLoading && <div className="text-center">
          <img
            className="rounded mx-auto d-block"
            width={'30%'}
            src={toAbsoluteUrl('/media/svg/icons/loading.svg')} alt='' />
        </div>}
        {/* begin::Table container */}
        {!isLoading && data && <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>

            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-140px'>Nombre</th>
                <th className='min-w-120px'>Descripción</th>
                <th className='min-w-120px'>Active</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>

              {data.result.sort((a: User, b: User) => a.fullName.localeCompare(b.fullName)).map((x: User) => (
                <tr key={x.id} className='p-0'>
                  <td className='p-0'>
                    <div className='d-flex align-items-center'>
                      <OverlayTrigger overlay={<Tooltip>Add to actions</Tooltip>} placement='bottom'>
                        {(x.id && checkIsSelected(x.id)) ?
                          <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={() => removeUser(x.id ?? '')}
                          >
                            <i className="bi bi-check2-square" style={{ color: "grey", fontSize: "17px" }}></i>
                          </button> :
                          <button className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={() => addUser(x)}
                          >
                            <i className="bi bi bi-square" style={{ color: "green", fontSize: "17px" }}></i>
                          </button>
                        }
                      </OverlayTrigger>

                      <div className='symbol symbol-45px me-5'>
                        <img src={toAbsoluteUrl('/media/svg/icons/earth.svg')} alt='' />
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {x.fullName}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {x.email}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className='p-0'>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {x.phoneNumber}
                    </span>
                  </td>
                  <td className='text-start'>
                    <BooleanCell value={x.isActive} />
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <OverlayTrigger overlay={<Tooltip>View</Tooltip>} placement='bottom'>
                        <a
                          title='View'
                          href='#'
                          onClick={() => setSelectedToView(x)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i style={{ color: 'grey', fontSize: 17 }} className="fas fa-eye"></i>
                        </a>
                      </OverlayTrigger>
                      <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>} placement='bottom'>
                        <a
                          title='Edit'
                          href='#'
                          onClick={() => setSelectedToEdit(x)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i style={{ color: 'blue', fontSize: 17 }} className="fas fa-pen"></i>
                        </a>
                      </OverlayTrigger>
                      <OverlayTrigger overlay={<Tooltip>Delete</Tooltip>} placement='bottom'>
                        <a
                          title='Delete'
                          href='#'
                          onClick={() => deleteItem(x.id ?? 'xxx')}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        >
                          <i style={{ color: 'red', fontSize: 17 }} className="fa fa-eye-slash"></i>
                        </a>
                      </OverlayTrigger>
                      {type === "TEACHER" &&
                      <>
                        <OverlayTrigger overlay={<Tooltip>Trail attendance</Tooltip>} placement='bottom'>
                          <a
                            href={`trail-attendance/${x.id}`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="bi bi-arrow-right-short" style={{ color: "blue", fontSize: "17px" }}></i>
                          </a>
                        </OverlayTrigger>
                        
                        <OverlayTrigger overlay={<Tooltip>Editar bloque horario</Tooltip>} placement='bottom'>
                          <a
                            href={`table-time/${x.id}`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <i className="bi bi-calendar-range" style={{ color: "blue", fontSize: "17px" }}></i>
                          </a>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip>Credentials</Tooltip>} placement='bottom'>
                          <button
                            style={{ cursor: (typeUser === 'ADMIN' || 
                              typeUser === 'ASSISTANT' || typeUser === 'ASSISTANT_WELCOME' ) ? 'pointer' : 'not-allowed' }}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            onClick={() => {
                              setSelectedToCredentials(x)
                            }
                            }
                            disabled={
                              typeUser !== 'ADMIN' && 
                              typeUser !== 'ASSISTANT' &&
                              typeUser !== 'ASSISTANT_WELCOME'
                            }
                          >
                            <i className='bi bi-key' style={{ color: 'red' }} />
                          </button>
                        </OverlayTrigger>
                      </>
                      }
                    </div>
                  </td>
                </tr>))}

            </tbody>
            {/* end::Table body */}
          </table>
          <GenericPaginator
            limit={limit}
            currentPage={paginator.current_page}
            items={paginator.result.length}
            total={paginator.total}
            totalPage={paginator.total_page}
            changePage={changePage}
            changeLimit={changeLimit}
          />
          {/* end::Table */}
        </div>}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
    <CreateModal show={modal} handleClose={() => alert()} />
    <ActionsUserModal show={actionsModal} handleClose={() => alert()} />
    <CredentialsModal show={modalCredential} handleClose={() => alert()} />
  </>
  )
}

export { UserTableList }
