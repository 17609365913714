import React, { useRef, useState } from "react";
import { UseBookingWindows } from "../store";
import CustomButton from "../../../components/common/custom-button";
import { useThemeMode } from "../../../../_metronic/partials";
import { UseMyRoadmapModule } from "../../profile/components/my-road-step/store";
import { SequenceType } from "../../abroad/road-map-step/interfaces";
import { useTranslation } from "react-i18next";
import { LearningPathType } from "../../abroad/learning-path/interfaces";
import { useNavigate } from 'react-router-dom';
import { UseBookingModule } from "../../me-booking/store";
import useTrailTask from "../../dashboard/components/student/store";
interface Props {
  
}

interface  CheckClassType {
  
}

const BookingButton: React.FC<Props> = ({ }) => {
  const { mode } = useThemeMode()
  const { t } = useTranslation();
  const textInputRef = useRef<HTMLInputElement | null>(null);
  let { setClassType, setStep } = UseBookingWindows()
  const { settings } = UseMyRoadmapModule();
  const {currentLesson} =  useTrailTask();
  const navigate = useNavigate();
  const {lessonHoursThisWeek, workShopHoursThisWeek} = UseBookingModule()
  const [applyWorkshop, setApplyWorkshop] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (settings && settings.typeStep && settings.typeStep === SequenceType.STANDARD) {
      setApplyWorkshop(true);
    }
  }, [settings ]);

  const handleCheckClassType: () => {notReviewData: boolean, blockedClick: boolean} = React.useCallback(() => {
    if (!settings) {
      return { notReviewData: true, blockedClick: true };
    }

    if ((applyWorkshop && settings.tasksInReviewData < 1) || (settings.typeStep === SequenceType.STANDARD && !currentLesson)) {
      return { notReviewData: true, blockedClick: true }
    }

    if (settings.limitTasksPending) {
      return { notReviewData: false, blockedClick: true }
    };

    return  { notReviewData: false, blockedClick: false }
  }, [settings, applyWorkshop, currentLesson]);
  
  return (<>
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '280px', maxHeight: '280px' }}>
      {settings && (settings?.typeCurrentPath && settings.typeCurrentPath !== LearningPathType.PRISMATIC) ?
        <div className="d-flex flex-column justify-content-center align-items-center">
          <CustomButton
            title={(handleCheckClassType().blockedClick && handleCheckClassType().notReviewData) ? 'Do the book exercises!'
              :handleCheckClassType().blockedClick ? `${settings.numberTasksPending} ${t('PENDING_TASK')}` :'Lesson'}
            type='xl'
            color={(handleCheckClassType().blockedClick) ? 'DANGER' : mode === 'dark' ? 'SECONDARY' : 'PRIMARY'}
            handleClick={() => {
              if(handleCheckClassType().blockedClick) {
                navigate(`/courses/${settings?.currentCourse}`);
                setStep(0)
              } else {
                setClassType('CLASS')
              } 
            }}
            // buttonDisabled={handleCheckClassType().blockedClick}
          />
          {applyWorkshop &&
            <CustomButton
              title='Workshop'
              type='xl'
              color={mode === 'dark' ? 'SECONDARY' : 'PRIMARY'}
              handleClick={() => setClassType('WORKSHOP')}
            />
          }
        </div> :
        <CustomButton
          title='Workshop'
          type='xl'
          color={mode === 'dark' ? 'SECONDARY' : 'PRIMARY'}
          handleClick={() => setClassType('WORKSHOP')}
        />
      }
    </div>
    {settings && settings.hoursActivity && settings.hoursUnits && 
      <>
        {settings.typeStep === SequenceType.STANDARD ?
        <>
          <p className=" champ-light text-warning-emphasis" onClick={()=>console.log(settings.typeStep)}>You've made {lessonHoursThisWeek}/{settings?.hoursUnits} weekly lessons. </p>
          <p className=" champ-light text-warning-emphasis" onClick={()=>console.log(settings.typeStep)}>You've made {workShopHoursThisWeek}/{settings?.hoursActivity} weekly workshops.</p>
        </>
        :
        <p className=" champ-light text-warning-emphasis" onClick={()=>console.log(settings.classAvailable)}>You've made {workShopHoursThisWeek+lessonHoursThisWeek}/{settings.classAvailable} weekly classes.</p>
      }
      </>
    }
  </>);
};

export default BookingButton;