 import {create} from 'zustand';
import { WritingCheckQuestionsAnswer,WritingCheckQuestions,  quiz  } from '../interfaces';
 
interface WritingCheckListInterface {
  mode?: 'WRITING_CHECK_LIST' | 'TIME_TO_SPEAK';
  questions:WritingCheckQuestions[]
  title:string;
  instructions:string;
  percentage:number
  answer:WritingCheckQuestionsAnswer[];
  responseStudent?:WritingCheckQuestionsAnswer[];
  exerciseId?:string;
  setQuestions:(
    exerciseId: string,
    instructions: string,
    value: WritingCheckQuestions[], 
    mode: 'WRITING_CHECK_LIST' | 'TIME_TO_SPEAK')=>void;
  setResponseStudent:(value: WritingCheckQuestionsAnswer[])=>void;
}

export const UseWritingCheckListModule = create<WritingCheckListInterface>( (set) => ({
    status:'ANSWER',
    title:"Este es un titulo de quiz",
    instructions:"esta es una instrucción",
    questions:[],
    answer:[],
    percentage:0,
    setQuestions:(exerciseId, instructions, value, mode)=>{
      set({questions: value, mode, exerciseId, instructions, responseStudent: undefined});
    },
    setResponseStudent:(value)=>{
      set({responseStudent: value});
    }
}));