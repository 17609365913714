import React, { useEffect, useState } from 'react';
import './index.css';
import { CheckWritingQuestionIE, CheckWritingQuestionAnswerIE, CheckWritingTheme, CheckWritingAnswerAndThemesIE } from '../interfaces';
import CheckWritingHeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/CheckWritingHeaderCard';
import { ModeTypeExercise } from '../../../../book/components/RenderExerciseBook';
import { UseBookStudentModule } from '../../../../book/modules/screen/store/main';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { AddCheckWrittingResponse } from '../services';
import { ButtonTryAgain } from '../../common/button-try-again/buttonTryAgain';
import { UseCheckWritingModule } from '../store';
import { ButtonSaveExercise } from '../../common/button-save-exercise/buttonSaveExercise';
import { UseBookContainerModule } from '../../../../book/store/main';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import useAuth from '../../../../../api/main';

type Props = {
  userMode?: ModeTypeExercise
  question: CheckWritingQuestionIE[]
  theme: CheckWritingTheme[]
  response?: CheckWritingAnswerAndThemesIE;
};
const divStyle = {
  backgroundColor: '#3f8bff',
  minWidth: '530px',
  maxWidth: '530px',
  minHeight: '420px',
  borderRadius: '30px',
  borderColor: 'rgb(255, 255, 255)',
  borderStyle: 'solid',
  padding: '27px 10px 40px 20px'
};

export interface ThemeAnswer {
  title: string
  order: number
  text?: string
}

const CheckWritingExItem: React.FC<Props> = ({
  userMode,
  question,
  theme,
  response
}: Props) => {
  const [allAnsweredThemes, setAllAnsweredThemes] = useState<boolean>(false);
  const [answers, setAnswers] = useState<CheckWritingQuestionAnswerIE[]>([]);
  const [answersThemes, setAnswersThemes] = useState<ThemeAnswer[]>([]);
  const [score, setScore] = useState<number | null>(null);
  const [showFeedback, setShowFeedback] = React.useState<boolean>(true);    
  const [hasFeedback, setHasFeedback] = useState<boolean>(false)
  const [selectedExercise, setSelectedExercise] = useState<any>()

  const {
    task,
    lessonData,
    stepBookItem,
    currentTaskId,
    setStepBookItem,
    addCompletedExerciseId,
    onChangeTaskByAnswer,
    setIsLoadingSubmit
  } = UseBookStudentModule();

  const {typeUser} = useAuth();

  const { exerciseId, instructions } = UseCheckWritingModule();
  useEffect(() => {
    if (response) {
      if (response.answer && response.answer.length > 0) {
        setAnswers(response.answer.map(q => ({
          title: q.title,
          text: q.text,
          order: q.order,
          isTrue: false
        })));
      }
      if (response.answersThemes && response.answersThemes.length > 0) {
        setAnswersThemes(response.answersThemes.map((x) => ({
          title: x.title,
          text: x.text,
          order: x.order
        })));
        setSelected(response.answersThemes[0] ? 0 : null);
      }
    } else {
      setAnswers(question.map(q => ({
        title: q.title,
        text: '',
        order: q.order,
        isTrue: false
      })));
      setAnswersThemes(theme.map((x) => ({
        title: x.title,
        text: '',
        order: x.order
      })));
      setSelected(theme.length > 0 ? 0 : null);
    }
  }, [question, theme]);

  useEffect(() => {
    // Verifica si todas las preguntas han sido respondidas
    const allAnsweredCheck = answersThemes.every(answer => answer.text !== '');
    setAllAnsweredThemes(allAnsweredCheck);

    if (allAnsweredCheck) {
      calculateScore();
    }
  }, [answersThemes]);
  
  useEffect(()=>{
    if (typeUser === 'STUDENT' && task && task.check_writing.length > 0) {
      const currentExercise = task.check_writing.find(x=>x.exercises.id === exerciseId)
      if(currentExercise){
        setHasFeedback(currentExercise.teacherObservation)
        setSelectedExercise(currentExercise)
      }
    }
},[task, exerciseId])

  const handleInputChange = (title: string, value: string, isTrue: boolean) => {
    const existingAnswer = answers.find(answer => answer.title === title && answer.text === value);

    if (existingAnswer) {
      setAnswers(answers.filter(answer => !(answer.title === title && answer.text === value)));
    } else {
      setAnswers([...answers, { title, text: value, isTrue, order: 0 }]);
    }
  };

  const resetExercise = () => {
    setAllAnsweredThemes(false);
    // setAllAnswered(false);
    setScore(null);
    setAnswers(question.map(q => ({
      title: q.title,
      text: '',
      order: q.order,
      isTrue: false
    })));

    setAnswersThemes(theme.map((x) => ({
      title: x.title,
      text: '',
      order: x.order
    })));
    setSelected(theme.length > 0 ? 0 : null);
  };

  const calculateScore = () => {
    const correctAnswers = answers.filter(answer => answer.isTrue).length;
    const percentage = (correctAnswers / question.length) * 100;
    setScore(percentage);
    // Aquí puedes pasar el porcentaje a otro estado o función si lo necesitas
  };
  
  const [selected, setSelected] = useState<number | null>(null);
  const finderText = (index: number): boolean => {
    return answersThemes.some((x) => x.order === index && (x.text && x.text?.length > 0))
  }

  const selectQuestion = (index: number) => {
    setSelected(index);
  };

  const handleChangeText = (text: string) => {
    if (selected !== null) {
      let newAnswers = [...answersThemes];
      newAnswers[selected] = { ...newAnswers[selected], text };
      setAnswersThemes(newAnswers);
    }
    let _allAnsweredTheme = answersThemes.some((x) => x.text === undefined || x.text.length < 1);
    setAllAnsweredThemes(!_allAnsweredTheme)
  };

  const handleSubmit = async () => {
    if (userMode === 'STUDENT' && currentTaskId && exerciseId) {
      try {
        setIsLoadingSubmit(true)
        const response = await AddCheckWrittingResponse({
          isActive: true,
          isReviewed: false,
          isPending: false,
          answers: answers,
          task: currentTaskId ?? '',
          exercises: exerciseId,
          percentage: 100, //score ?? 0, se manda 100 porque no es un ejercicio que no tiene respuestas validad.
          answerThemes: answersThemes
        });
        onChangeTaskByAnswer(response, 'check_writing');
      } catch (e) {
        showSimpleAlert({ message: 'Error al cargar respuesta', icon: 'error' })
      } finally {
        setIsLoadingSubmit(false)
      }
    }
    if (lessonData && lessonData.evaluation &&
      lessonData.evaluation.evaluation_item) {
      addCompletedExerciseId(exerciseId ?? "")
      if (lessonData?.evaluation?.evaluation_item?.length > stepBookItem + 1) {
        setStepBookItem(exerciseId ?? '')
      } else {
        setStepBookItem('FINISH_LESSON')
      }
    } 
  };

  const { setOffDraggable, showButtons } = UseBookContainerModule()

  return (
    <>
      <div className="container-fluid " style={{ display: 'flex' }}>  
        <div style={divStyle}>
          <div className='d-flex justify-content-between'>
            <CheckWritingHeaderCard />
            {showButtons &&
              <OverlayTrigger
                overlay={
                  <Tooltip className='tooltip-inverse'> 
                      <div className='fs-8 champ-bold d-flex justify-content-start text-primary'>{hasFeedback?"feedback from teacher":"No ha sido calificado"}</div>
                  </Tooltip>
                } 
                placement='top'
                offset={[0, 10]}
              >
                <button 
                  type='button'
                  className={`border border-0 bg-transparent ${hasFeedback? "cursor-pointer": "cursor-"}`}
                  disabled={!hasFeedback}
                >
                  <img alt="transcript"
                    onClick={()=>setShowFeedback(!showFeedback)}
                    src={toAbsoluteUrl('/media/svg/michigan-icons/elements/white/text_audio.svg')}
                    style={{ width: '20px' }} 
                  />
                </button>
              </OverlayTrigger>
            }
          </div>
          <div className='scroll-y overflow-y-auto mb-4 px-2 py-2' style={{ maxHeight: '70px', maxWidth: '567px' }}>
            <span className=" text-white  mb-0 poppins-light fs-6">{instructions}</span><br />
          </div>
          <div className="container">
            <div className="row py-2">
              <div className='col-6 '>
                <div className='row scrollable-div'   >
                  {question.map((q, questionIndex) => (
                    <div className='col-12' key={q.title}>
                      <div>
                        <p className="champ-bold mb-0 text-white" style={{ fontSize: 17 }}>{q.title}</p>
                        {q.options.map((option, optionIndex) => (
                          <div className='d-flex align-items-center' key={option.title}>
                            <input
                              checked={!!answers.find(answer => answer.title === q.title && answer.text === option.title)}
                              type='checkbox'
                              className='round-checkbox'
                              onChange={() => handleInputChange(q.title, option.title, option.isTrue)}
                            />
                            <span
                              className="poppins-light text-white ms-2"
                              style={{ fontSize: 15 }}
                            >
                              {option.title}
                            </span><br />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='col-6'>
                <div className='row'>
                  <div className="scroll-y overflow-y-auto py-2 mb-4" style={{maxHeight:'85px', maxWidth:'523px'}}>
                  <div className="col-12">
                    {theme.map((x, index) => (
                      <p key={index} className="text-white mb-0 p-0 poppins-light" style={{ padding: 0, fontSize: 17, cursor: 'pointer' }} onClick={() => selectQuestion(index)}>
                        {finderText(x.order)
                          ?
                          <i style={{ color: 'white', fontSize: 12, paddingRight: 5 }} className="fa fa-check"></i>
                          :
                          <i style={{ color: 'white', fontSize: 12, paddingRight: 5 }} className="fa fa-circle"></i>
                        }
                        {x.title}
                      </p>
                    ))}
                  </div>
                  </div>
                  <div className="col-12">
                    <textarea
                      value={selected !== null ? answersThemes[selected].text : ''}
                      onChange={(e) => handleChangeText(e.target.value)}
                      onMouseEnter={() => setOffDraggable(true)}
                      onMouseLeave={() => setOffDraggable(false)}
                      className="form-control rounded-4 text-white" placeholder="Type here.."
                      style={{ background: "#3f8bff", height: "150px" }} id="exampleFormControlTextarea1"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(allAnsweredThemes && showButtons) &&
            <div className='d-flex justify-content-between align-items-center'>
              <ButtonTryAgain onAction={resetExercise} />
              <ButtonSaveExercise
                isChecked={allAnsweredThemes}
                onNext={() => { }}
                onSubmit={handleSubmit}
                exerciseId={exerciseId}
              />
            </div>
          }
        </div>
      </div>
      {(showFeedback && selectedExercise) &&
        <div 
          className='rounded-top-9 container-fluid d-flex mx-auto px-0'
          style={{ 
            marginTop: '-40px', 
            zIndex:-1,
            borderRadius: '30px',
            border: 'solid rgb(255, 255, 255)',
            width: '100%',
            maxWidth: '530PX',
            height: '185px',
            backgroundColor: '#3f8bff',
            paddingTop: '50px'
          }}
        >
          <div className='container champ-bold mb-0 text-white'>
            <div className='row'>
              <div className="col-12 mb-3 ">
                <div className="input-group">
                  <span className="input-group-text pe-5 ts-1" id="basic-addon1">Feedback</span>
                  <textarea  className="form-control poppins-light" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" disabled value={selectedExercise.teacherObservation}/>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className="col-4 mb-3">
                <div className="input-group">
                  <span className="input-group-text pe-5" id="basic-addon2">Score</span>
                  <input type="text" className="form-control poppins-light" style={{ maxWidth: '55px' }} placeholder="Nota" aria-label="Nota" aria-describedby="basic-addon2" disabled value={selectedExercise.percentage}/>
                </div>
              </div>
              <div className="col-6 mb-3 ms-auto">
                <div className="input-group">
                  <span className="input-group-text pe-5" id="basic-addon3">Teacher</span>
                  <input type="text" className="form-control poppins-light" placeholder="Nota" aria-label="Nota" aria-describedby="basic-addon3" disabled value={selectedExercise.teacher}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export { CheckWritingExItem };