import React, { useEffect, useState } from 'react'
import { BooleanCell } from '../../../../components/common/BooleanCell'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import { DayPickerRangeSelector } from '../../../../components/common/DayRangePicker/indext'
import { Campus } from '../../../../../app/modules/abroad/campus/interfaces'
import { UseCampusAll } from '../../../../../app/modules/abroad/campus/services'
import { UseBlockHourAll } from '../../../../../app/modules/abroad/block-hours/services'
import { BlockHour } from '../../../../../app/modules/abroad/block-hours/interfaces'
import { Classroom } from '../../../../../app/modules/admin/classroom/interfaces'
import { t } from 'i18next'
import { UseTrailClassByWorkShopPaginate } from '../../services/appoitment-report'
import { ListToToWorkshop } from '../../interface'
import moment from 'moment'
import AllExcel from '../../../../components/common/AllExcel'
import { UseTrailClassFilterModules } from '../../store/trail-class-filter.store'
import { calculatePercentages, calculateStatistics, countAppointmentsByDay, Statistics } from './utils'
import { UserSearchStudent } from '../user-search'
import { DayHourData } from './table-hour-resume'
import { TraditionalLevelEnum } from '../../../abroad/road-map-step/interfaces'

type PaginatorSetter = {
    currentPage: number;
    items: number;
    total: number;
    totalPage: number;
}
export interface DateRange {
    before: Date
    after: Date
}
type Props = {

}
const TrailClassReportByWorkshopTableList: React.FC<Props> = ({ }) => {


    const [dataResume, setDataResume] = React.useState<ListToToWorkshop[]>();
    const [dataBlockHour, setDataBlockHour] = React.useState<BlockHour[]>([]);
    const [campusData, setCampusData] = React.useState<Campus[]>([]);


    const {
        typeClassroom,
        blockHour,
        campus,
        isActive,
        after,
        before,
        withStudents,
        setCurrentPage,
        setLimit,
        setIsActive,
        setStudent,
        setActivity,
        setTeacher,
        setDate,
        setClassroom,
        setBlockHour,
        setTypeClassroom,
        setCampus,
        setWithStudents,
        level,
        setLevel
    } = UseTrailClassFilterModules()

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const fetchData = async () => {
        setIsLoading(true)
        try {
            let response = await UseTrailClassByWorkShopPaginate();
            setDataResume(response)
        } catch (error) {
            console.error("Error invalidating queries:", error)
        } finally {
            setIsLoading(false)
        }
    }


    const handleChangeBlockHour = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setBlockHour(parseInt(e.target.value));
    };

    const handleChangeTypeClassroom = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setTypeClassroom(e.target.value);
    };
    const handleChangeCampus = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCampus(e.target.value);
    };

    const handleChangeWithStudents = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setWithStudents(e.target.value as "YES" | "NO" | "");
    };

    const fetchBlocksHours = async () => {
        try {
            const response = await UseBlockHourAll();
            setDataBlockHour(response);
        } catch (e) {
            showSimpleAlert({ message: 'Error al encontrar bloques horarios!', icon: 'error' })
            throw 'Error'
        }
    };


    const fetchAllCampus = async () => {
        try {
            let response = await UseCampusAll();
            setCampusData(response);
        } catch (e) {
            showSimpleAlert({ message: 'Error al encontrar actividades!', icon: 'error' })
            throw 'Error'
        }
    };


    const theadName = [
        'Taller',
        "Clases Ofertadas",
        "Reservadas",
        "Asitencia",
        "Inasistencia"
    ];

    React.useEffect(() => {
        fetchBlocksHours();
        fetchAllCampus();
    }, []);

 

    return (<>




        <div className={`card `}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Clases</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>Gestión de Clases</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >
                </div>



                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >



                    <button
                        onClick={() => setIsActive(!isActive)}
                        className='btn btn-sm btn-light-secondary  mr-8 p-2'
                    // data-bs-toggle='modal'
                    // data-bs-target='#kt_modal_invite_friends'
                    >
                        <BooleanCell value={isActive} />
                    </button>
                </div>
            </div>
            {/* end::Header */}

            <div className='py-3 row'>
                {/* begin::Filters */}
                <div className='mb-5 d-flex align-items-center overflow-x-auto px-4'>

 

                    <div className='mb-10 col-2'>
                        <label className='form-label mb-3'>Level</label>
                        <select
                            name='level'
                            value={level}
                            onChange={(e)=>setLevel(e.target.value)}
                            className='form-select'
                            aria-label='Default select example'
                        >
                            {Object.keys(TraditionalLevelEnum).map((x) => (
                                <option key={x} value={x}>
                                    {t(x)}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-auto d-flex align-items-center mx-3">
                        <label className='form-label mb-0 me-2'>Tipo salon</label>
                        <div style={{ display: 'inline-block', width: 'auto', minWidth: '100px', maxWidth: '200px' }}>
                            <select
                                name={'typeClassroom'}
                                value={typeClassroom}
                                onChange={handleChangeTypeClassroom}
                                className="form-select form-select-sm"
                                aria-label="Default select example"
                            >
                                <option value={undefined}></option>
                                <option value={'VIRTUAL'}>Virtual</option>
                                <option value={'FACE'}>Presencial</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-auto d-flex align-items-center mx-3">
                        <label className='form-label mb-0 me-2'>Bloque horario</label>
                        <div style={{ display: 'inline-block', width: 'auto', minWidth: '100px', maxWidth: '200px' }}>
                            <select
                                name='dataBlockHour'
                                value={blockHour}
                                onChange={handleChangeBlockHour}
                                className='form-select form-select-sm pe-0'
                                aria-label='Default select example'
                            >
                                <option value={undefined}>
                                    {' '}
                                </option>
                                {dataBlockHour?.map((x) => (
                                    <option key={x.id} value={x.order}>
                                        {`${x.start} ${x.end}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="col-auto d-flex align-items-center mx-3">
                        <label className='form-label mb-0 me-2'>Taller/Unidad</label>
                        <select
                            name='withStudents'
                            value={withStudents}
                            onChange={handleChangeWithStudents}
                            className='form-select form-select-sm '
                            aria-label='Default select example'
                        >
                            <option value={undefined}>
                                {' '}
                            </option>
                            <option value={"YES"}>Talleres</option>
                            <option value={"NO"}>Lección</option>
                        </select>
                    </div>

                    <div className="col-auto d-flex align-items-center mx-3">
                        <label className='form-label mb-0 me-2'>Sede</label>
                        <select
                            name='campus'
                            value={campus}
                            onChange={handleChangeCampus}
                            className='form-select form-select-sm'
                            aria-label='Default select example'
                        >
                            <option value={undefined}>
                                {' '}
                            </option>
                            {campusData?.map((x) => (
                                <option key={x.id} value={x.id}>
                                    {x.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {/* end::Filters */}
            </div>
            {/* begin::Body */}
            <div className='py-3 row'>
                {/* begin::Table container */}
                <div className='mb-10 col-3'>
                    <UserSearchStudent
                        setStudent={(e) => setStudent(e.id)}
                    />
                    {dataResume && <AllExcel
                        dataExcel={dataResume}
                        filename={`Resumen De Talleres` + moment().format('yyyymmddhhmmsss')}
                        sheetName='Talleres'
                        theadName={theadName}
                        type='all'
                        backgroundColor={'green'}
                        titleColor={'white'}
                    />}
                    <DayPickerRangeSelector
                        after={after}
                        before={before}
                        changeDate={(e) => setDate({
                            after: e.after,
                            before: e.before
                        })}
                    />

                </div>
                <div className='mb-10 col-9'>

                    <div className="btn-group" role="group" aria-label="Basic example">
                        <button disabled={isLoading} onClick={() => fetchData()} type="button" className="btn btn-primary">
                            {isLoading?"Cargando..":"Buscar"}
                        </button>
                    </div>
 
                    <div className='table-responsive' style={{ marginTop: 0 }}>
                        {isLoading ?
                            <span className='indicator-progress d-flex justify-content-center  align-items-center ms-5'>
                                <span className='spinner-border spinner-border-lg align-middle text-primary' />
                            </span>
                            : dataResume &&
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className='fw-bold text-muted  text-start'>
                                        <th className='min-w-140px text-start'>Actividad</th>
                                        <th className='min-w-140px text-start'>Clases ofertadas</th>
                                        <th className='min-w-140px text-start'>Total reservadas</th>
                                        <th className='min-w-120px text-start'>Asitencias</th>
                                        <th className='min-w-120px text-start'>Inasistencias</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataResume?.map((x: ListToToWorkshop) => (
                                        <tr key={x.activityName}
                                            className={`p-0 rounded`}
                                        >
                                             <td className='p-0'>
                                                <div className='d-flex align-items-center'>

                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className=' fw-bold text-hover-primary fs-6'>
                                                            {x.activityName}
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='p-0'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className='fw-bold text-hover-primary fs-6'>
                                                            {x.totalUniqueAppointments}
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='p-0'>
                                                <div className='d-flex align-items-center'>

                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className=' fw-bold text-hover-primary fs-6'>
                                                            {x.totalClasses}
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='p-0'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className='fw-bold text-hover-primary fs-6'>
                                                             {x.totalAssistantTeacherTrue}
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='p-0'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className='fw-bold text-hover-primary fs-6'>
                                                            {x.totalAssistantTeacherFalse}
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
 

                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        }
                    </div>

                </div>
            </div>
        </div>

    </>
    )
}

export { TrailClassReportByWorkshopTableList }
