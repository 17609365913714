import React, { useEffect } from 'react'


import { useQueryClient } from '@tanstack/react-query'


import moment from 'moment'

import { t } from 'i18next'
import AllExcel from '../../../../components/common/AllExcel'
import { useThemeMode } from '../../../../../_metronic/partials'
import { KTIcon, KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import useAuth from '../../../../api/main'
import { UseStudentReportFilterModule } from '../../store/student-filter.store'
import { BooleanCell } from '../../../../components/common/BooleanCell'
import { FilterStudentSetting, FilterStudentSettingTwo, SettingModalityType } from '../../../admin/user/interfaces'
import { UseStudentFilterReport } from '../../services'
import { ResponseList, StudentListReport } from '../../interface'
import { TraditionalLevelEnum } from '../../../abroad/road-map-step/interfaces'

export type StudentSettingsResponse = {
    response: any
    newMode: 'ADD' | 'EDIT' | 'VIEW'
}

type Props = {}
const StudentFilterReportTableList: React.FC<Props> = ({ }) => {
    const queryClient = useQueryClient()
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [responseList, setResponseList] = React.useState<ResponseList>()

    const { mode: colorNav } = useThemeMode();
    const {
        modality,
        filterStudent,
        before,
        after,
        isActive,
        setAfter,
        setBefore,
        setIsActive,
        setFilterStudent,
        items,
        setModality,
        setLevel,
        level

    } = UseStudentReportFilterModule()
    const { typeUser } = useAuth.getState()
    const filterList = async () => {
        try {
            setIsLoading(true)
            let response = await UseStudentFilterReport();
            setResponseList(response)
        } catch {
            setIsLoading(false)
        } finally {
            setIsLoading(false)
        }
    }

    React.useEffect(() => {
        filterList()
    }, [after, before, isActive, modality, level]);

    const theadName = [
        'Fecha Inicio Contrato',
        'Fecha Fin Contrato',
        'Estado Welcome',
        'Nivel',
        'Modalidad',
        'Horas de actividades',
        'Horas de unidades',
        'Email',
        'Activo',
        'Nombre',
        'Cédula',
        'Teléfono'
    ];

    return (
        <>
            <div className={`card `}>
                {/* begin::Header */}

                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Reportería</span>
                        <span className='text-muted mt-1 fw-semibold fs-7'>Estudiantes </span>
                    </h3>
                    <div
                        className='card-toolbar'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                        title='Click to add a user'
                    >

                        <div className='row'>
                            {responseList && responseList.levels_counts.map((x) => <div className='col-3'>
                                <p className='bold'>{t(x.level)}:<strong>{x.count}</strong></p></div>)}
                        </div>



                        <button
                            onClick={() => setIsActive(!isActive)}
                            className='btn btn-sm btn-light-secondary  mr-8 p-2'
                        // data-bs-toggle='modal'
                        // data-bs-target='#kt_modal_invite_friends'
                        >
                            <BooleanCell value={isActive} />
                        </button>
                    </div>

                    <div className='row' style={{ width: '100%' }}>
                        <div className='col-3'>
                            {(filterStudent === FilterStudentSetting.DATE_CREATED ||
                                filterStudent === FilterStudentSetting.DATE_FINISH ||
                                filterStudent === FilterStudentSetting.DATE_START) && <>
                                    <div className='d-flex justify-content-center align-items-center me-2'>
                                        <label className='me-1'>Desde: </label>
                                        <input
                                            name='teacher'
                                            value={before ? moment(before).format('YYYY-MM-DD') : undefined}
                                            onChange={(e) => setBefore(moment(e.target.value).toDate())}
                                            className='form-select-sm'
                                            type='date'
                                            aria-label='Default select example'
                                        />
                                    </div>
                                    <div className='d-flex justify-content-center align-items-center me-2'>
                                        <label className='me-1'>Hasta: </label>
                                        <input
                                            name='teacher'
                                            value={after ? moment(after).format('YYYY-MM-DD') : undefined}
                                            onChange={(e) => setAfter(moment(e.target.value).toDate())}
                                            className='form-select-sm'
                                            type='date'
                                            aria-label='Default select example'
                                        />
                                    </div>
                                </>
                            }

                        </div>
                        <div className='col-3'>
                            <select
                                name='teacher'
                                value={filterStudent}
                                onChange={(e) => setFilterStudent(e.target.value as FilterStudentSetting)}
                                className='form-select-sm'
                                aria-label='Default select example'
                            >
                                {Object.keys(FilterStudentSettingTwo).map((x) => (
                                    <option key={x} value={x}>
                                        {x}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='col-3'>
                            <select
                                name='level'
                                value={level}
                                onChange={(e) => setLevel(e.target.value as FilterStudentSetting)}
                                className='form-select-sm'
                                aria-label='Default select example'
                            >
                                {Object.keys(TraditionalLevelEnum).map((x) => (
                                    <option key={x} value={x}>
                                        {x}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='col-3'>
                            <select
                                name='modality'
                                value={modality}
                                onChange={(e) => setModality(e.target.value as SettingModalityType)}
                                className='form-select-sm'
                                aria-label='Default select example'
                            >
                                {Object.keys(SettingModalityType).map((x) => (
                                    <option key={x} value={x}>
                                        {x}
                                    </option>
                                ))}
                            </select>

                        </div>
                        <div className='col-2 p-5'>
                            {responseList && responseList.students && <AllExcel
                                dataExcel={responseList.students}
                                filename={`ReporteEstudiantes`}
                                sheetName='Estudiantes'
                                theadName={theadName}
                                type='all'
                                backgroundColor={colorNav === 'light' ? 'green' : ''}
                                titleColor={colorNav === 'light' ? '#FFFFFF' : 'green'}
                            />}
                        </div>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}

                <div className='card-body py-3'>
                    {isLoading && (
                        <div className='text-center'>
                            <img
                                className='rounded mx-auto d-block'
                                width={'30%'}
                                src={toAbsoluteUrl('/media/svg/icons/loading.svg')}
                                alt=''
                            />
                        </div>
                    )}
                    {/* begin::Table container */}
                    {!isLoading && responseList && (
                        <div className='table-responsive'>
                            {/* begin::Table */}
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                {/* begin::Table head */}
                                <thead>
                                    <tr className='fw-bold text-muted'>
                                        <th className='min-w-140px'>Name</th>
                                        <th className='min-w-120px'>Level</th>
                                        <th className='min-w-120px'>Modalidad</th>
                                        <th className='min-w-120px'>Number</th>
                                        <th className='min-w-120px'>Active</th>
                                        <th className='min-w-120px'>Inicio de contrato</th>
                                        <th className='min-w-120px'>Fin de contrato</th>
                                    </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                    {responseList?.students.map((x: StudentListReport) => (
                                        <tr key={x.document_id} className='p-0'>
                                            <td className='p-0'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='symbol symbol-45px me-5'>
                                                        <img src={toAbsoluteUrl('/media/svg/icons/earth.svg')} alt='' />
                                                    </div>
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                            {x.fullname}
                                                        </a>
                                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                            {x.email}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='p-0'>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                    {t(x.level)}
                                                </span>
                                            </td>
                                            <td className='p-0'>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                    {t(x.modality)}
                                                </span>
                                            </td>
                                            <td className='p-0'>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                    {x.phonenumber}
                                                </span>
                                            </td>
                                            <td className='text-start'>
                                                <BooleanCell value={x.active} />
                                            </td>
                                            <td className='p-0'>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                    {moment(x.start).format('YYYY-MM-DD')}
                                                </span>
                                            </td>
                                            <td className='p-0'>
                                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                    {moment(x.finish).format('YYYY-MM-DD')}
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                {/* end::Table body */}
                            </table>

                            {/* end::Table */}
                        </div>
                    )}
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>

        </>
    )
}

export { StudentFilterReportTableList }

