import React, {useEffect, useState} from 'react'
import {BlockHour} from '../interfaces'
import {UseUserTypePaginate} from '../../../admin/user/services'
import {showSimpleAlert} from '../../../../commonHooks/alert'
import {TeacherProfileByDay, UseBlockHourAll, TeacherProfileTotalHours, updateTeacherProfileSalary, TeacherProfileBlockHoursAll} from '../services'
import {CreateModal} from './modal'
import {UseBlockHourModule} from '../store/main'
import {TemplateTableTimeDay} from '../../template-table-time/interfaces'
import {useParams} from 'react-router-dom'
import {UseUserModule} from '../../../admin/user/store/main'

const Timetable: React.FC = () => {
  const [teacher, setTeacher] = React.useState<any[]>([])
  const [teacherData, setTeacherData] = React.useState<boolean>()
  const {id} = useParams()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [initialLoaded, setInitialLoaded] = React.useState<boolean>(false)
  let {itemSelected} = UseUserModule()
  const {
    resetData,
    totalHours,
    dataBlockHour,
    teacherId,
    modal,
    monday,
    tuesday,
    thursday,
    wednesday,
    friday,
    setModal,
    setMonday,
    saturday,
    setTuesday,
    setThursday,
    setWednesday,
    setFriday,
    setSaturday,
    setTeacherId,
    setInitialHours,
    setDataBlockHour,
  } = UseBlockHourModule()

  const fetchTeacher = async () => {
    try {
      let countries = await UseUserTypePaginate('TEACHER')
      setTeacher(countries)
    } catch (e) {
      showSimpleAlert({message: 'Error al encontrar profesores!', icon: 'error'})
      throw 'Error'
    }
  }

  const fetchData = async () => {
    if (id) {
      setLoading(true)
      
      const mondayResponse = await TeacherProfileByDay(id, TemplateTableTimeDay.MONDAY)
      setSalary(mondayResponse.valueHour)
      setMonday(mondayResponse.monday)
      
      const tuesdayResponse = await TeacherProfileByDay(id, TemplateTableTimeDay.TUESDAY)
      setTuesday(tuesdayResponse.tuesday)
      
      const wednesdayResponse = await TeacherProfileByDay(id, TemplateTableTimeDay.WEDNESDAY)
      setWednesday(wednesdayResponse.wednesday)
      
      const thursdayResponse = await TeacherProfileByDay(id, TemplateTableTimeDay.THURSDAY)
      setThursday(thursdayResponse.thursday)
      
      const fridayResponse = await TeacherProfileByDay(id, TemplateTableTimeDay.FRIDAY)
      setFriday(fridayResponse.friday)
      
      const saturdayResponse = await TeacherProfileByDay(id, TemplateTableTimeDay.SATURDAY)
      setSaturday(saturdayResponse.saturday)
      
      setLoading(false)
      let total =
        (mondayResponse ? (mondayResponse.monday ? mondayResponse.monday.length : 0) : 0) +
        (tuesdayResponse ? (tuesdayResponse.tuesday ? tuesdayResponse.tuesday.length : 0) : 0) +
        (wednesdayResponse
          ? wednesdayResponse.wednesday
            ? wednesdayResponse.wednesday.length
            : 0
          : 0) +
        (thursdayResponse
          ? thursdayResponse.thursday
            ? thursdayResponse.thursday.length
            : 0
          : 0) +
        (fridayResponse ? (fridayResponse.friday ? fridayResponse.friday.length : 0) : 0) +
        (saturdayResponse ? (saturdayResponse.saturday ? saturdayResponse.saturday.length : 0) : 0)
      setInitialHours(total, id)
    }
  }

  
  const fetchBlocksHours = async () => {
    try {
      const response = await UseBlockHourAll()
      setDataBlockHour(response)
    } catch (e) {
      showSimpleAlert({message: 'Error al encontrar bloques horarios!', icon: 'error'})
      throw 'Error'
    }
  }
  
  React.useEffect(() => {
    fetchTeacher()
  }, [])
  
  // React.useEffect(() => {
  //     changeHours();
  // }, [totalHours]);
  
  let changeHours = () => {
    TeacherProfileTotalHours({
      hours: totalHours,
      user: teacherId,
    })
  }
  
  React.useEffect(() => {
    if (teacherId && !initialLoaded) {
      fetchData()
      setInitialLoaded(false)
      setTeacherData(true)
    }
  }, [teacherId])
  
  React.useEffect(() => {
    if (id) {
      setTeacherId(id)
    }
  }, [id])
  
  React.useEffect(() => {
    fetchBlocksHours()
  }, [])
  
  const handleTeacherChange = (e) => {
    const selectedId = e.target.value
    setTeacherId(selectedId)
    resetData()
  }
  
  const products = [
    {
      title: 'Cup & Green',
      imageUrl: '/media/stock/600x400/img-17.jpg',
      description: 'Visually stunning',
      rating: 4.2,
      data: [],
    },
  ]
  
  const [salary, setSalary] = useState<number>(0)
  
  const handleChangePayment = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    setSalary(isNaN(value) ? 0 : value);
  };
  
  const submitNewSalary = async() =>{
    if(!id)return
    await updateTeacherProfileSalary(id, salary)
  }
  
  return (
    // <div>
    //   <RoadMapWidget2 className={''} products={products}  />
    // </div>
    <div className={`card `}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Table time</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Gestión de Horarios </span>
        </h3>
      </div>
      {loading? 
        <div 
        className="d-flex justify-content-center align-items-center" 
        style={{ 
          width: '80vw', 
          height: '70vh', 
        }}
      >
        <span className="indicator-progress">
          <span className="spinner-border spinner-border-lg align-middle text-primary"/> 
        </span> 
      </div>
      :  
        <div className='card-body py-3'>
          <div className='row mb-5'>
            <div className='col'>
              <label className='form-label mb-0'>Tutor</label>
              <select
                name='teacher'
                disabled
                className='form-control'
                aria-label='Default select example'
                onChange={handleTeacherChange}
                value={id}
              >
                <option value='' selected>
                  Seleccione un tutor
                </option>
                {teacher.map((x, i) => (
                  <option value={x.id} key={i}>
                    {x.fullName || `${x.firstName} ${x.lastName}`}
                  </option>
                ))}
              </select>
            </div>
            <div className='col'>
              <label className='form-label mb-0'>Payment/hour</label>
              <div className='input-group'>
                <span className='input-group-text'>$COP</span>
                <input
                  type='number'
                  min={0}
                  className='form-control'
                  value={salary}
                  onChange={handleChangePayment}
                />
              </div>
            </div>
            <div className='col-auto d-flex align-items-end'>
              <button className='btn btn-primary' onClick={submitNewSalary}>Submit Salary</button>
            </div>
          </div>
          <h1>Weekly Timetable</h1>
          <table className='table table-row-dashed table-row-gray-300  gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th>
                  <h4 className='align-items-start flex-column'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <span className='card-label fw-bold fs-5 mb-1'>Hours</span>
                    </div>
                    <span className='text-muted mt-1 fw-semibold fs-7 d-flex align-items-center justify-content-center'>
                      {totalHours}
                    </span>
                  </h4>
                </th>
                <th>
                  <h4 className='align-items-start flex-column'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <span className='card-label fw-bold fs-5 mb-1'>Monday</span>
                      <a href='#' onClick={() => setModal(true, 'MONDAY')} className='ms-2'>
                        <i className='bi bi-arrow-down-left-circle-fill' />
                      </a>
                    </div>
                    <span className='text-muted mt-1 fw-semibold fs-7 d-flex align-items-center justify-content-center'>
                      {monday ? monday.length : 0}
                    </span>
                  </h4>
                </th>
                <th>
                  <h4 className='align-items-start flex-column'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <span className='card-label fw-bold fs-5 mb-1'>Tuesday</span>
                      <a href='#' onClick={() => setModal(true, 'TUESDAY')} className='ms-2'>
                        <i className='bi bi-arrow-down-left-circle-fill' />
                      </a>
                    </div>
                    <span className='text-muted mt-1 fw-semibold fs-7 d-flex align-items-center justify-content-center'>
                      {tuesday ? tuesday.length : 0}
                    </span>
                  </h4>
                </th>
                <th>
                  <h4 className='align-items-start flex-column'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <span className='card-label fw-bold fs-5 mb-1'>Wednesday</span>
                      <a href='#' onClick={() => setModal(true, 'WEDNESDAY')} className='ms-2'>
                        <i className='bi bi-arrow-down-left-circle-fill' />
                      </a>
                    </div>
                    <span className='text-muted mt-1 fw-semibold fs-7 d-flex align-items-center justify-content-center'>
                      {wednesday ? wednesday.length : 0}
                    </span>
                  </h4>
                </th>
                <th>
                  <h4 className='align-items-start flex-column'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <span className='card-label fw-bold fs-5 mb-1'>Thursday</span>
                      <a href='#' onClick={() => setModal(true, 'THURSDAY')} className='ms-2'>
                        <i className='bi bi-arrow-down-left-circle-fill' />
                      </a>
                    </div>
                    <span className='text-muted mt-1 fw-semibold fs-7 d-flex align-items-center justify-content-center'>
                      {thursday ? thursday.length : 0}
                    </span>
                  </h4>
                </th>
                <th>
                  <h4 className='align-items-start flex-column'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <span className='card-label fw-bold fs-5 mb-1'>Friday</span>
                      <a href='#' onClick={() => setModal(true, 'FRIDAY')} className='ms-2'>
                        <i className='bi bi-arrow-down-left-circle-fill' />
                      </a>
                    </div>
                    <span className='text-muted mt-1 fw-semibold fs-7 d-flex align-items-center justify-content-center'>
                      {friday ? friday.length : 0}
                    </span>
                  </h4>
                </th>
                <th>
                  <h4 className='align-items-start flex-column'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <span className='card-label fw-bold fs-5 mb-1'>Saturday</span>
                      <a href='#' onClick={() => setModal(true, 'SATURDAY')} className='ms-2'>
                        <i className='bi bi-arrow-down-left-circle-fill' />
                      </a>
                    </div>
                    <span className='text-muted mt-1 fw-semibold fs-7 d-flex align-items-center justify-content-center'>
                      {saturday ? saturday.length : 0}
                    </span>
                  </h4>
                </th>
              </tr>
            </thead>
            <tbody>
              {dataBlockHour &&
                dataBlockHour?.map((hour: BlockHour) => (
                  <tr key={hour.id}>
                    <td className='d-flex align-items-center justify-content-center'>
                      {hour.start} - {hour.end}
                    </td>
                    {teacherData ? (
                      <>
                        <td>
                          {monday && monday?.some((x) => x.order === hour.order) ? (
                            <div className='text-white bg-success d-flex align-items-center justify-content-center rounded'>
                              {monday.sort((a, b) => a.order - b.order)[0].id === hour.id && (
                                <i className='bi bi-arrow-right-circle-fill me-2 text-white' />
                              )}
                              {hour.start} - {hour.end}
                            </div>
                          ) : (
                            <div className='bg-secondary d-flex align-items-center justify-content-center rounded'>
                              {hour.start} - {hour.end}
                            </div>
                          )}
                        </td>
                        <td>
                          {tuesday && tuesday?.some((x) => x.order === hour.order) ? (
                            <div className='text-white bg-success d-flex align-items-center justify-content-center rounded'>
                              {tuesday.sort((a, b) => a.order - b.order)[0].id === hour.id && (
                                <i className='bi bi-arrow-right-circle-fill me-2 text-white' />
                              )}
                              {hour.start} - {hour.end}
                            </div>
                          ) : (
                            <div className='bg-secondary d-flex align-items-center justify-content-center rounded'>
                              {hour.start} - {hour.end}
                            </div>
                          )}
                        </td>
                        <td>
                          {wednesday && wednesday?.some((x) => x.order === hour.order) ? (
                            <div className='text-white bg-success d-flex align-items-center justify-content-center rounded'>
                              {wednesday.sort((a, b) => a.order - b.order)[0].id === hour.id && (
                                <i className='bi bi-arrow-right-circle-fill me-2 text-white' />
                              )}
                              {hour.start} - {hour.end}
                            </div>
                          ) : (
                            <div className='bg-secondary d-flex align-items-center justify-content-center rounded'>
                              {hour.start} - {hour.end}
                            </div>
                          )}
                        </td>
                        <td>
                          {thursday && thursday?.some((x) => x.order === hour.order) ? (
                            <div className='text-white bg-success d-flex align-items-center justify-content-center rounded'>
                              {thursday.sort((a, b) => a.order - b.order)[0].id === hour.id && (
                                <i className='bi bi-arrow-right-circle-fill me-2 text-white' />
                              )}
                              {hour.start} - {hour.end}
                            </div>
                          ) : (
                            <div className='bg-secondary d-flex align-items-center justify-content-center rounded'>
                              {hour.start} - {hour.end}
                            </div>
                          )}
                        </td>
                        <td>
                          {friday && friday?.some((x) => x.order === hour.order) ? (
                            <div className='text-white bg-success d-flex align-items-center justify-content-center rounded'>
                              {friday.sort((a, b) => a.order - b.order)[0].id === hour.id && (
                                <i className='bi bi-arrow-right-circle-fill me-2 text-white' />
                              )}
                              {hour.start} - {hour.end}
                            </div>
                          ) : (
                            <div className='bg-secondary d-flex align-items-center justify-content-center rounded'>
                              {hour.start} - {hour.end}
                            </div>
                          )}
                        </td>
                        <td>
                          {saturday && saturday?.some((x) => x.order === hour.order) ? (
                            <div className='text-white bg-success d-flex align-items-center justify-content-center rounded'>
                              {saturday.sort((a, b) => a.order - b.order)[0].id === hour.id && (
                                <i className='bi bi-arrow-right-circle-fill me-2 text-white' />
                              )}
                              {hour.start} - {hour.end}
                            </div>
                          ) : (
                            <div className='bg-secondary d-flex align-items-center justify-content-center rounded'>
                              {hour.start} - {hour.end}
                            </div>
                          )}
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <div className='bg-secondary d-flex align-items-center justify-content-center rounded'>
                            {hour.start} - {hour.end}
                          </div>
                        </td>
                        
                        <td>
                          <div className='bg-secondary d-flex align-items-center justify-content-center rounded'>
                            {hour.start} - {hour.end}
                          </div>
                        </td>
                        
                        <td>
                          <div className='bg-secondary d-flex align-items-center justify-content-center rounded'>
                            {hour.start} - {hour.end}
                          </div>
                        </td>
                        
                        <td>
                          <div className='bg-secondary d-flex align-items-center justify-content-center rounded'>
                            {hour.start} - {hour.end}
                          </div>
                        </td>
                        
                        <td>
                          <div className='bg-secondary d-flex align-items-center justify-content-center rounded'>
                            {hour.start} - {hour.end}
                          </div>
                        </td>
                        
                        <td>
                          <div className='bg-secondary d-flex align-items-center justify-content-center rounded'>
                            {hour.start} - {hour.end}
                          </div>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
          <CreateModal show={modal} handleClose={() => alert()} />
        </div>
      }
    </div>
  )
}
export default Timetable
