import {create} from 'zustand';
import moment from 'moment'
import { StudentListReport } from '../interface';
import { FilterStudentSetting } from '../../admin/user/interfaces';

type DateRange ={
    before:Date
    after:Date
}

interface UseStudentReportFilterModuleIE {
    items?: StudentListReport[]
    filterStudent?:FilterStudentSetting
    modality?:string
    isActive:boolean,
    before?:Date,
    after?:Date
    level?:string
    
    setItems:(items:StudentListReport[])=>void;
    setModality:(value:string)=>void;
    setIsActive:(value:boolean)=>void;
    setAfter:(value:Date)=>void;
    setBefore:(value:Date)=>void;
    setLevel:(value:string)=>void;
    setFilterStudent:(value:FilterStudentSetting)=>void;
}

export const UseStudentReportFilterModule = create<UseStudentReportFilterModuleIE>( (set) => ({

    isActive:true,
    setIsActive:(value:boolean)=>{
        set({isActive:value})
    },

    setLevel:(value:string)=>{
        set({level:value})
    },
    setModality:(value:string)=>{
        set({modality:value})
    },
    setAfter:(value:Date)=>{
        set({
            after:value 
        })
    },
    setFilterStudent:(value:FilterStudentSetting)=>{
        set({
            filterStudent:value 
        })
    },
    setBefore:(value:Date)=>{
        set({
            after:value 
        })
    },
    setItems:(value:StudentListReport[])=>{
        set({items:value})
    }
}));