import React, {useEffect, useState} from 'react'
import WritingDownHeaderCard from '../../../../../components/abroad/e-book/beyong-the-reading/ui/WritingDownHeaderCard'
import {ModeSpeakOut} from '../store'
import {UseBookContainerModule} from '../../../../book/store/main'
import {ButtonSaveExercise} from '../../common/button-save-exercise/buttonSaveExercise'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {UseBookStudentModule} from '../../../../book/modules/screen/store/main'
import useAuth from '../../../../../api/main'

type Props = {
  tips: string[]
  mode: ModeSpeakOut
  instruction: string
  error: string | null
  description: string
  onSubmit: () => void | Promise<void>
  setDescription: (value: string) => void
  exerciseId: string
}

const ExerciseTimeAndWriting: React.FC<Props> = ({tips, mode, instruction, error, onSubmit, description, setDescription, exerciseId,}) => {
  const {setOffDraggable, showButtons} = UseBookContainerModule()
  const {task} = UseBookStudentModule();
  const {typeUser} = useAuth();
  const [hasFeedback, setHasFeedback] = useState<boolean>(false)  
  const [showFeedback, setShowFeedback] = useState<boolean>(false)
  const [selectedExercise, setSelectedExercise] = useState<any>()
  const handleShowFeedBack = ()=>{
    setShowFeedback(!showFeedback)
    if(showFeedback) return
  }

  useEffect(()=>{
    if (typeUser === 'STUDENT' && task && task.speak_out.length > 0) {
      const currentExercise = task.speak_out.find(x=>x.exercises.id === exerciseId)
      if(currentExercise){
        setSelectedExercise(currentExercise)
        setHasFeedback(currentExercise.teacherObservation)
      }
    }
},[task, exerciseId])

  return (
    <>
      <div className='container-fluid d-flex '>
        <div style={divStyle}>
          <div className='d-flex justify-content-between'>
            <WritingDownHeaderCard typeExercise={mode} />
            {showButtons && (
              <OverlayTrigger
                overlay={
                  <Tooltip className='tooltip-inverse'>
                      <div className='fs-8 champ-bold d-flex justify-content-start text-primary'>{hasFeedback?"feedback from teacher":"No ha sido calificado"}</div>
                  </Tooltip>
                }
                placement='top'
                offset={[0, 10]}
              >
              <button 
                type='button'
                className={`border border-0 bg-transparent ${hasFeedback? "cursor-pointer": "cursor-"}`}
                disabled={!hasFeedback}
              >
                <img alt="transcript"
                  onClick={handleShowFeedBack}
                  src={toAbsoluteUrl('/media/svg/michigan-icons/elements/white/text_audio.svg')}
                  style={{ width: '20px' }} 
                />
              </button>
              </OverlayTrigger>
            )}
          </div>
          <div>
            <p className='poppins-light text-white'>{instruction} </p>
          </div>
          <div className='row p-2'>
            <div
              className='col-5 d-flex flex-column custom-scrollable overflow-auto  '
              style={{height: 200}}
            >
              {tips && tips.map((x, i) => (
                <div className='mb-4 d-flex' key={i}>
                  <div
                    className={`rounded-circle me-2`}
                    style={{
                      minWidth: '6px',
                      maxWidth: '6px',
                      minHeight: '6px',
                      maxHeight: '6px',
                      backgroundColor: '#d0ff00',
                      marginTop: '7px',
                    }}
                  />
                  <span className='poppins-light text-white'>{x}</span>
                </div>
              ))}
            </div>
            <div className='col-7 d-flex flex-column'>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onMouseEnter={() => setOffDraggable(true)}
                onMouseLeave={() => setOffDraggable(false)}
                className='poppins-light'
                rows={6}
                style={{
                  borderRadius: '20px',
                  borderColor: 'white',
                  background: '#3f8bff',
                  color: 'white',
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  fontSize: 15,
                  padding: '10px',
                  width: '100%',
                  height: '100%',
                  outline: 'none',
                  resize: 'none', // Opcional: Desactiva la capacidad de redimensionar el área de texto
                }}
                placeholder='Type here...'
              />
              {error && <div className='text-secondary poppins-bold'>{error}</div>}
            </div>
          </div>
          {showButtons && !error && description.length > 0 && (
            <div className='d-flex justify-content-end align-items-center me-2'>
              <ButtonSaveExercise
                isChecked={true}
                onNext={() => {}}
                onSubmit={onSubmit}
                exerciseId={exerciseId}
              />
            </div>
          )}
        </div>
      </div>
      {showFeedback && selectedExercise && (
        <div
          className='rounded-top-9 container-fluid d-flex mx-auto px-0'
          style={{
            marginTop: '-40px',
            zIndex: -1,
            borderRadius: '30px',
            border: 'solid rgb(255, 255, 255)',
            width: '100%',
            maxWidth: '625px',
            height: '185px',
            backgroundColor: '#3f8bff',
            paddingTop: '50px',
          }}
        >
          <div
            className='container champ-bold mb-0 text-white'
          >
            <div className='row'>
              <div className='col-12 mb-3 '>
                <div className='input-group'>
                  <span className='input-group-text pe-5 ts-1' id='basic-addon1'>
                    Feedback
                  </span>
                  <textarea
                    className='form-control poppins-light'
                    placeholder='Username'
                    aria-label='Username'
                    aria-describedby='basic-addon1'
                    disabled
                    value={selectedExercise.teacherObservation}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-4 mb-3'>
                <div className='input-group'>
                  <span className='input-group-text pe-5' id='basic-addon2'>
                    Score
                  </span>
                  <input
                    type='text'
                    className='form-control poppins-light'
                    style={{maxWidth: '55px'}}
                    placeholder='Nota'
                    aria-label='Nota'
                    aria-describedby='basic-addon2'
                    disabled
                    value={selectedExercise.percentage}
                  />
                </div>
              </div>
              <div className='col-6 mb-3 ms-auto'>
                <div className='input-group'>
                  <span className='input-group-text pe-5' id='basic-addon3'>
                    Teacher
                  </span>
                  <input
                    type='text'
                    className='form-control poppins-light'
                    placeholder='Nota'
                    aria-label='Nota'
                    aria-describedby='basic-addon3'
                    disabled
                    value={selectedExercise.teacher}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {ExerciseTimeAndWriting}

const divStyle = {
  backgroundColor: '#3f8bff',
  width: '625px',
  height: '400px',
  borderRadius: '30px',
  borderColor: 'rgb(255, 255, 255)',
  borderStyle: 'solid',
  padding: '27px 10px 40px 20px',
}
