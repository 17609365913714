import React from 'react';
import { BookPage } from '../../../admin/book/modules/book-page/interfaces';

type Props = {
  pagesBook: BookPage[];
  numberPage: number;
};

const RenderBookPage: React.FC<Props> = ({ pagesBook, numberPage }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  return (<>
    {pagesBook.sort((a, b) => a.page - b.page).map((x) => (
      <div
        ref={containerRef}
        className='d-flex justify-content-center py-1'
        >
        <img
          className='w-100 h-auto'
          key={x?.id}
          src={x?.file.location}
          alt=""
        />
      </div>
    ))}
  </>);
};

export { RenderBookPage };