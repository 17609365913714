import React from 'react'
import * as Yup from 'yup'
import {useFormik, } from 'formik'
import { useQueryClient } from '@tanstack/react-query'
import { showSimpleAlert } from '../../../../../../commonHooks/alert'
import { SequenceType, TraditionalLevelEnum } from '../../../../../abroad/road-map-step/interfaces'
import { RoadMap } from '../../../../../abroad/road-map/interfaces'
import { UseRoadMapAll } from '../../../../../abroad/road-map/services'
import { RoadMapStepByRoadMap } from '../../../../../abroad/road-map-step/services'
import { useTranslation } from 'react-i18next'
import {IsStartStep, PromotionHistoryType } from '../../../../student-actions/promotion-history/interfaces'
import { UseTrailModule } from '../../../../user/store/student/main'
import { FindHoursExperienceByStudent, UpgradeStudentManual } from '../../../../user/services/student/UpgradeStudentService'
import { StudentSetting } from '../../../../history-writing-response/interfaces'
import {SelectLessonsForm} from './selectLessons'
import { Chapter } from '../../../../../abroad/learning-path/modules/chapter/interfaces'
import { FinishPlacementTestUseCase } from '../../../../qualify/modules/placement-test/services';
import moment from 'moment';
import { UseQualifyPlacementModule } from '../../../../qualify/modules/placement-test/store/main'
export type FormFields = {
    fromStart?: "Yes" | "No"
    promotionType: PromotionHistoryType | undefined;
    isActive: boolean;
    // hours: number;
    level: TraditionalLevelEnum;
    date: string;
    newCurrentStep: string;
    aprovedLessons?: string[]
    studentSetting?: string //el id nada mas
    hasLessons: boolean
    observation?: string
    additionalHours: number
}

const entitySchema = Yup.object().shape({
  promotionType: Yup.string()
    .required('Type of update is required'),
  level: Yup.string()
    .required('Level is required'),
  date: Yup.string()
    .required('Date is required'),
  newCurrentStep: Yup.string()
    .required('New current step is required'),
});

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
  student: StudentSetting
  taskId?: string,
  typeModal: 'PLACEMENT' | 'STUDENT'
}
//Creado porque roadMapStep.roadMap no puede ser string como esta en otros lados del codigo
  
export type RoadMapStep  = {
  id?: string;
  description?:string;
  name:string;
  isActive: boolean;
  roadMap: RoadMap;
  chapter: Chapter;
  order: number;
  hours: number;
  lessons: number;
  hoursToSee: number;
  level: TraditionalLevelEnum;
  sequence: SequenceType;
  levelToSee: TraditionalLevelEnum;
  finalPresentation: boolean;
  createdAt?:string;
  updatedAt?:string;
  observation?:string;
}

const PromotionHistoryForm: React.FC<Props>  = (props:  Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient()
  const {itemSelectedUpgrade, mode, resetData} = UseTrailModule();
  const [roadMap, setRoadMap] = React.useState<RoadMap[]>([]);
  const [roadMapStep, setRoadMapStep] = React.useState<RoadMapStep[]>([]);
  const [roadMapId, setRoadMapId] = React.useState<string>('');
  const [upgradeType, setUpgradeType] = React.useState<PromotionHistoryType|"">("")
  const [isFromStart, setIsFromStart] = React.useState<boolean | null>(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [showAdditionalForm, setShowAdditionalForm] = React.useState<boolean>(false);
  const [selectedChapter, setSelectedChapter] = React.useState<RoadMapStep>()
  const {levelRecommended, reload, setReload} = UseQualifyPlacementModule()


  const {isValid, values, errors, touched, setFieldValue, handleChange, handleSubmit, validateField} =  useFormik<FormFields>({
    initialValues: {
        isActive: (mode==='EDIT' || mode==='VIEW')? itemSelectedUpgrade?.isActive ?? true : true,
        level: (mode==='EDIT' || mode==='VIEW')? itemSelectedUpgrade?.level ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.PRE_A1_1,
        date: (mode==='EDIT' || mode==='VIEW')? moment().format('YYYY-MM-DD') ?? moment().format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        newCurrentStep: (mode==='EDIT' || mode==='VIEW')? itemSelectedUpgrade?.newCurrentStep ?? '' : '',
        promotionType: undefined,
        fromStart: undefined,
        hasLessons: false,
        observation: "Sin observación",
        additionalHours: 0
    },
    validationSchema: entitySchema,
    
    onSubmit:async (formData)=> {
      if(props.mode==='ADD'){
        try{
          setLoading(true);
          await UpgradeStudentManual({
            studentSetting: props.student?.id??'xxx', 
            newCurrentStep: formData.newCurrentStep, 
            promotionType: upgradeType!== '' ? upgradeType : PromotionHistoryType.UPGRADE, 
            date: formData.date, 
            hasLessons: false, 
            taskId: props.taskId, 
            additionalHours: formData.additionalHours,
            description: formData.observation,
          })
          if (props.typeModal === 'PLACEMENT' && props.taskId) {
            await FinishPlacementTestUseCase(props.taskId);
          }
          resetData();
          setLoading(false)
          setReload(!reload)
          queryClient.invalidateQueries({ queryKey: ['user', 'promotion-history'] })
          }catch(e){
            // showSimpleAlert({message: `${e}`, icon: "into"})
            setLoading(false);
          }
        }
        else{
          try{
            setLoading(true);
            await UpgradeStudentManual({
              studentSetting: props.student?.id??'xxx', 
              newCurrentStep: formData.newCurrentStep, 
              promotionType: upgradeType!== '' ? upgradeType : PromotionHistoryType.UPGRADE, 
              date: formData.date, 
              hasLessons: formData.hasLessons, 
              taskId: props.taskId,
              additionalHours: formData.additionalHours,
              description: formData.observation
            })
            if (props.typeModal === 'PLACEMENT' && props.taskId) {
              await FinishPlacementTestUseCase(props.taskId);
            }
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['user', 'promotion-history'] })
            }catch(e){
              setLoading(false);
            }
        }
      },
    });
    
    React.useEffect(() => {
      const fetchRoadMap = async () => {
        try {
          let roadMapData = await UseRoadMapAll();
          setRoadMap(roadMapData)
        } catch (e) {
          showSimpleAlert({ message: 'Error al encontrar Road Map!', icon: 'error' })
          throw 'Error';
        }
      };
      fetchRoadMap();
    }, []);
    
    React.useEffect(() => {
      if (props.student) {
        const findHoursExperience = async () => {
          try {
            await FindHoursExperienceByStudent(props.student?.id??'xxx');
          } catch (e) {
            showSimpleAlert({ message: 'Error al encontrar las experiencias del estudiante!', icon: 'error' })
            throw 'Error';
          }
        };
        findHoursExperience();
      };
    }, [props.student?.id]);
    
    React.useEffect(() => {
      if (roadMapId) {
        const fetchRoadMapStep = async () => {
          try {
            let response = await RoadMapStepByRoadMap(roadMapId);
            setRoadMapStep(response);            
          } catch (e) {
            showSimpleAlert({ message: 'Error al encontrar Road Map Step!', icon: 'error' })
            throw 'Error';
          }
        };
        fetchRoadMapStep();
      }
    }, [roadMapId]);
    
    const handleRoadMapChange = (e) => {
      const selectedId = e.target.value;
      setRoadMapId(selectedId);
    };
    
    const filterLevels = () => {
      const levelValues = roadMapStep
        if(props.student){
          const currentIndex = levelValues.findIndex((x) => x.level === props.student.currentStep.level);
      
          if (upgradeType === PromotionHistoryType.UPGRADE) {
            return levelValues.slice(isFromStart? currentIndex + 1: currentIndex + 1); // Filtrar niveles superiores (y permitir el nivel actual cuando no va a comenzar desde el comienzo del nivel)
          } else if (upgradeType === PromotionHistoryType.DOWNGRADE) {
            return levelValues.slice(0, isFromStart? currentIndex + 1 : currentIndex + 1); // Filtrar niveles inferiores
          }
          return levelValues; // Si no hay upgradeType, mostrar todos los niveles, igual no debería entrar aca nunca
      } else {
        return levelValues;
      }
    }
    
    const handleSetChapter = (stepId:string) => {
      const step = roadMapStep.find(x => x.id === stepId);
      if (step) {
        setSelectedChapter(step);
        setTimeout(() => {
          setFieldValue('level', step.level);
          validateField('level'); 
        }, 0);
        return;
      }
      setFieldValue('level', TraditionalLevelEnum.PRE_A1_1);
      return;
    };
    
    return (
    <div id='kt_account_profile_details' className='collapse show'>
      {showAdditionalForm && upgradeType && selectedChapter? (
        <SelectLessonsForm 
          submitPromotion={handleSubmit}
          roadMapStep = {selectedChapter}  
          loading= {loading} 
          setLoading={setLoading}
          formData={values}
          setShow={setShowAdditionalForm}
          studentSetting={props.student.id}
          upgradeType={upgradeType}
          typeModal={props.typeModal}
          taskId={props.taskId??""}
        />
      ) : (
      <form onSubmit={handleSubmit} noValidate className='form poppins-bold'>
        <div className='card-body border-top p-9 row'>
          <div className="table-responsive">
            <table className="table">
              <thead className='poppins-bold'>
                <tr>
                  {/* <th>Level</th> */}
                  {/* <th>Hours</th> */}
                  <th>Road Map</th>
                  <th>Nivel</th>
                  <th>Nivel Recomendado</th>
                </tr>
              </thead>
              <tbody className='poppins-light'>
                <tr>
                  {/* <td>{t(props.student?.level as string)}</td>
                  <td>{props.student?.hours}</td> */}
                  <td>{props.student?.currentStep?.roadMap?.name}</td>
                  <td>{props.student?.currentStep?.name}</td>
                  <td>{t(levelRecommended)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='mb-10 col-12 poppins-bold'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>
          <div className='mb-1 col-6'>
            <label className='form-label mb-0'>Upgrade or Downgrade</label>
            <select
              name='promotionType'
              value={values.promotionType}
              onChange={(e) => {
                handleChange(e);
                const selectedValue = e.target.value;
                if (selectedValue === PromotionHistoryType.UPGRADE || selectedValue === PromotionHistoryType.DOWNGRADE) {
                  setUpgradeType(selectedValue as PromotionHistoryType);
                } else {
                  setUpgradeType('');
                }
              }}
              className='form-select poppins-light'
              aria-label='Default select example'
            >
              <option value='' selected/>
              {Object.keys(PromotionHistoryType).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.promotionType && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.promotionType}</span>
                </div>
              </div>
            )}
          </div>
          
          <div className='mb-1 col-6'>
            <label className='form-label mb-0'>Empieza desde el comienzo?</label>
            <select
              disabled={mode === 'VIEW'}
              name='fromStart'
              value={values.fromStart}
              onChange={(e) => {
                setFieldValue('fromStart', e.target.value)
                const selectedValue = e.target.value;
                if (selectedValue === IsStartStep.YES){
                  setIsFromStart(true);
                } else if (selectedValue === IsStartStep.NO){
                  setIsFromStart(false);
                } else {
                  setIsFromStart(null);
                }
              }}
              className='form-select poppins-light'
              aria-label='Default select example'
            >
              <option value='' selected/>
              {Object.keys(IsStartStep).map((x) => (
                <option key={x} value={x}>
                  {t(x)}
                </option>
              ))}
            </select>
            {errors.fromStart && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.fromStart}</span>
                </div>
              </div>
            )}
          </div>
          {upgradeType && isFromStart !== null &&
            <>
              <div className='mb-1 col-6'>
                <label className='form-label mb-0'>Date Change</label>
                <input
                  type='date'
                  className='form-control poppins-light form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'date'}
                  value={values.date}
                  onChange={handleChange}
                  />
                {touched.date && errors.date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.date}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-6'>
              </div>
              
              <div className='mb-1 col-6'>
                <label className='form-label mb-0'>Road Map</label>
                <select
                  name='roadMap'
                  className='form-select poppins-light'
                  aria-label='Default select example'
                  onChange={handleRoadMapChange}
                  value={roadMapId}
                >
                  <option value='' selected></option>
                  {roadMap?.map((x, i) => (
                    <option value={x.id} key={i}>
                      {t(x.name)}
                    </option>
                  ))}
                </select>
              </div>
              
              <div className='mb-1 col-6'>
                <label className='form-label mb-0'>Road Map Step</label>
                <select
                  name='newCurrentStep'
                  className='form-select poppins-light'
                  aria-label='Default select example'
                  onChange={(e) => {
                    handleSetChapter(e.target.value);
                    handleChange(e);
                  }}
                  value={values.newCurrentStep}
                >
                  <option value={''}></option>
                  {filterLevels().map((x, i) => (
                    <option value={x.id} key={i}>
                      {x.name}
                    </option>
                  ))}
                </select>
                
                {errors.newCurrentStep && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{errors.newCurrentStep}</span>
                    </div>
                  </div>
                )}
              </div>
              
              <div className='mb-1 col-12'>
                <label className='form-label mb-0'>Observación</label>
                <textarea
                  className='form-control poppins-light'
                  name='observation'
                  value={values.observation}
                  onChange={(e)=>setFieldValue('observation', e.target.value)}
                />
              </div>
            </>
          }
        </div>
        
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          { isFromStart === null ?
          <>
          </>
          : isFromStart ?
            <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW' || !isValid} >
              {!loading && 'Save'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          :
            <button type='button' className='btn btn-primary' onClick={()=>{
              setShowAdditionalForm(true)
              setFieldValue('hasLessons', true)
            }} 
              disabled={!isValid}>
              Set Steps
            </button>
          }
        </div>
      </form>
      )}
    </div>
  )
}

export {PromotionHistoryForm}
