
import useAuth from '../../../api/main' 
import { UseAppointmentModule } from '../../admin/appointment/store/main'
import { api } from '../../auth/services/auth.services' 
import { showSimpleAlert } from '../../../commonHooks/alert' 
import moment from 'moment'
import { ClassroomType } from '../../../../app/modules/admin/classroom/interfaces'
import { ClassTypeBooking } from '../../booking-window/store' 
import { UseTrailClassFilterModules } from '../store/trail-class-filter.store'

const { token,id, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}

export interface BookingFind {
  after:string
  before:string
  blockHour?: number;
  campus?:string;
  classroomType?: ClassroomType;
  classType?: ClassTypeBooking;
}

export interface BookingFindValidate {
  date:string
  tutor?:string
  room?:String
}


export const UseAppointmentAllPaginate = async (): Promise<any> => {
  try {

    const { setPaginator, limit, currentPage, where,activity, isActive, after, before, 
      classroom, teacher, typeClassroom, blockHour, campus, withStudents} = await UseAppointmentModule.getState()
    
    let url = `/dashboard/appointment_resume?isActive=${isActive?1:0}&limit=${limit}&page=${currentPage}&after=${after}&before=${before}`;

    if (classroom) {
      url += `&room=${classroom}`
    };
    if (typeClassroom) {
      url += `&typeClassroom=${typeClassroom}`
    };
    if (blockHour) {
      url += `&blockHour=${blockHour}`
    };
    if (teacher) {
      url += `&teacher=${teacher}`
    };
    if (activity) {
      url += `&activity=${activity}`
    };
    if(campus){
      url+= `&campus=${campus}`
    }
    if(withStudents !== ''){
      url+= `&hasStudents=${withStudents}`
    }
    const response: any = await api.get(
      `${url}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      //console.log(e.response)
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}





export const UseTrailClassAllPaginate = async (): Promise<any> => {
  try {

    const { limit, currentPage, where,activity, isActive, after, before, 
      classroom, student, typeClassroom, blockHour, campus, withStudents,level} = await UseTrailClassFilterModules.getState()
    
    let url = `/dashboard/trail_class_resume?isActive=${isActive?1:0}&limit=${limit}&page=${currentPage}&after=${after}&before=${before}&level=${level}`;

    if (classroom) {
      url += `&room=${classroom}`
    };
    if (typeClassroom) {
      url += `&typeClassroom=${typeClassroom}`
    };
    if (blockHour) {
      url += `&blockHour=${blockHour}`
    };
    if (student) {
      url += `&student=${student}`
    };
    if (activity) {
      url += `&activity=${activity}`
    };
    if(campus){
      url+= `&campus=${campus}`
    }
    if(withStudents !== ''){
      url+= `&hasStudents=${withStudents}`
    }
    const response: any = await api.get(
      `${url}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      //console.log(e.response)
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UseTrailClassByWorkShopPaginate = async (): Promise<any> => {
  try {

    const { limit, currentPage, where,activity, isActive, after, before, 
      classroom, student, typeClassroom, blockHour, campus, withStudents,level} = await UseTrailClassFilterModules.getState()
    
    let url = `/dashboard/find_trail_workshop?isActive=${isActive?1:0}&limit=${limit}&page=${currentPage}&after=${after}&before=${before}`;

    if (classroom) {
      url += `&room=${classroom}`
    };
    if (typeClassroom) {
      url += `&typeClassroom=${typeClassroom}`
    };
    if (blockHour) {
      url += `&blockHour=${blockHour}`
    };
    if (student) {
      url += `&student=${student}`
    };
    if (activity) {
      url += `&activity=${activity}`
    };
    if(campus){
      url+= `&campus=${campus}`
    }
    if(withStudents !== ''){
      url+= `&hasStudents=${withStudents}`
    }
    if (level) {
      url += `&level=${level}`
    }
    const response: any = await api.get(
      `${url}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
      //console.log(e.response)
      e.response.data.message.map((x)=>{
        //@ts-ignore
        showSimpleAlert({ message: x, icon: 'error' })
      })
      throw 'Error'
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

