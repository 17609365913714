import React from 'react';
import { Booking } from '../../../admin/appointment/interfaces';
import useAuth from '../../../../api/main';
import CardCourse from '../../../../components/common/expresive/card-course';
import BookingWindows from '../../../booking-window';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { useThemeMode } from '../../../../../_metronic/partials';
import { useTranslation } from 'react-i18next';
import { WelcomeHeaderModule } from '../../../../components/common/widgets/statistics/WelcomeHeaderModule';
import { AdvertisementsCard } from '../Advertisements';
import { LinkComponent } from '../LinkComponent';
import { UseMyRoadmapModule } from '../../../profile/components/my-road-step/store';
import { UseEvaluationCourseModule } from '../../../courses/screen/store/main';
import { useQueryClient } from '@tanstack/react-query';
import UseTranslateTimeZone from '../../../../hooks/useTranslateTimeZone';
import { CourtesyRegisterDataModal } from './modal';
import { GetCourtesyDataUseCase } from './services';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApprovedPlacementTestRequest, FindRequestPlacementTest } from '../../../admin/placement-test/services';
import { PlacementTestRequestStatus } from '../../../admin/placement-test/interfaces';
import { UseModeCourseModule } from '../../../courses/components/front-page/store/main';

type Props = {

}
const CourtesyStudentDashboardMain: React.FC<Props> = ({ }: Props) => {

  const { mode } = useThemeMode();
  const navigate = useNavigate();
  const location = useLocation();
  const [windowMode, setWindowMode] = React.useState<'NORMAL' | 'WELCOME' | 'VERIFICATION' | 'PLACEMENT_TEST'>('NORMAL');
  const { settings, courtesy, setCourtesy } = UseMyRoadmapModule();
  let { profile, notifications, fullName, email, code, typeUser } = useAuth();
  const { setCurrentTask } = UseEvaluationCourseModule();
  const { modeCourseChecked } = UseModeCourseModule();

  const [loading, setLoading] = React.useState<boolean>(true);

  const setterWindowsMode = () => {
    if (settings?.verification == 'PENDING' || settings?.verification == 'IN_PROCESS') {
      setWindowMode('VERIFICATION')
      return
    }
    if (settings?.welcomeDay == 'PENDING' || settings?.welcomeDay == 'IN_PROCESS') {
      setWindowMode('WELCOME')
      return
    }
    if (settings?.placementTest == 'PENDING' || settings?.placementTest == 'IN_PROCESS') {
      setWindowMode('PLACEMENT_TEST')
      return
    }
    setWindowMode('NORMAL')
  }

  const fetchCourtesy = React.useCallback(async () => {
    if (settings && typeUser === 'COURTESY') {
      const response = await GetCourtesyDataUseCase(settings.codeContract);
      setCourtesy(response);
    };
  }, [settings]);

  React.useEffect(() => {
    if (settings?.banner) {
      setLoading(false)
    }
    setterWindowsMode();
    fetchCourtesy();
  }, [settings]);

  React.useEffect(() => {
    modeCourseChecked();
  }, [location]);


  // Se movio para start booking windows porque solo se hace al principio, no todas las veces 
  // para saber cuantas clases por semana tiene el estudiante
  // const findStarterList = async () => {
  //   let starterList = await UseFindPaginatedTrailClass({
  //     before: moment(day)/*.subtract(7,'d')*/.startOf('week').format('YYYY-MM-DD').toString(),
  //     after: moment(day)/*.add(12,'d')*/.endOf('week').format('YYYY-MM-DD').toString()
  //   });
  //   const numberClassWorkshops = starterList?.result.filter((x) => x.isSuspense === false && x?.activityType === 'WORKSHOP')
  //   const numberClassLessons = starterList?.result.filter((x) => x.isSuspense === false && x?.activityType === 'LESSON')

  //   setLessonHoursThisWeek(numberClassLessons.length)
  //   setWorkshopHoursThisWeek(numberClassWorkshops.length);

  //   setMyAgenda(starterList?.result)
  //   let list: any[] = [];
  //   if (starterList?.result && starterList?.result?.length > 0) {
  //     starterList.map((x) => {
  //       list.push(moment(x.start))
  //     })
  //   }
  //   setAvailableList(list);
  // };

  React.useEffect(() => {
    if (windowMode !== 'NORMAL' && windowMode !== 'VERIFICATION' && settings?.taskId) {
      setCurrentTask(settings?.taskId);
    }
  }, [windowMode, settings]);

  return (
    <div className="card p-5 student-dashboard-container" >
      <div className='row'>
        <div className='col-12 '>
          <WelcomeHeaderModule
            title={`${fullName}`}
            leftIcon={'/media/svg/michigan-icons/header/blue/lobby_blue.svg'}
            svgIcon={`/media/svg/michigan-icons/notifications/white/${notifications.length > 0 ? 'notifications2_white' : 'one'}.svg`}
            background={mode != 'dark' ? '#2d30e1' : '#1e1e2d'}
            colorTitle={mode === 'dark' ? '#2d30e1' : '#2d30e1'}
            routeBack='/notifications'
          />
        </div>
        <div className='col-md-6 col-lg-6 mb-4'>
          <div className="row">
            <div className='col-lg-8 col-md-8 col-sm-12'>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <a href="/me-booking" className={`card text-decoration-none mb-5`} style={{ backgroundColor: mode === 'light' ? '#1cebcc' : '#14141a', borderRadius: 50 }}>
                  <div className='card-body pt-3 pb-3 row'>
                    <div className='col-8 d-flex flex-column justify-content-center' style={{ color: mode === 'light' ? '#2d30e1' : '#1cebcc' }}>
                      <span className='poppins-bold fs-5'>You don't have</span>
                      <span className='champ-bold fs-1'>
                        {`classes scheduled`}
                      </span>
                      <span className='poppins-light fs-6'>{''}</span>
                    </div>
                    <div className='col-4 d-flex justify-content-center align-items-center'>
                      <img src={toAbsoluteUrl(`/media/svg/michigan-icons/header/blue/my_booking_eye.svg`)} alt="" style={{ width: '150px' }} className='p-2' />
                    </div>
                  </div>
                </a>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 h-100">
                <div
                  className="col-12 h-100"
                  style={
                    mode === 'light' ? {
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      border: '2px solid #2d30e1', // Added border style
                      borderRadius: 50,
                      maxHeight: '280px',
                      overflow: 'hidden',
                      paddingTop: 0,
                    } : {
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#14141a',
                      flexDirection: 'column',
                      borderRadius: 50,
                      maxHeight: '280px',
                      overflow: 'hidden',
                      paddingTop: 0
                    }
                  }
                >
                  <div className='d-flex cursor-pointer' style={{ height: '280px', maxHeight: '280px' }} onClick={() => navigate(`/courses/${settings?.currentCourse}`)}>
                    <div className='col-6 d-flex align-items-center justify-content-center'>
                      <span className='poppins-bold fs-4' style={{ marginLeft: '-35px', color: '#2d30e1' }}>{typeUser === 'COURTESY' ? 'Explorers' : 'Placement Test'}</span>
                    </div>
                    <div className='col-6 d-flex align-items-center'>
                      <img src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/booking_blue.svg`)} alt="" style={{ width: '225px' }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row col-lg-4 col-md-4 col-sm-12 me-0 pe-0'>
              <LinkComponent
                url='/my-courses'
                colorTitle='#2d30e1'
                title='Road Map'
                background='#1cebcc'
                className='col-lg-12 col-md-12 col-sm-6 container-link-component-item py-2'
                icon='/media/svg/michigan-icons/header/blue/roadmap_blue.svg'
              />
              <LinkComponent
                url='/faq'
                colorTitle='#2d30e1'
                title='Faqs'
                background='#1cebcc'
                className='col-lg-12 col-md-12 col-sm-6 container-link-component-item py-2'
                icon='/media/svg/michigan-icons/header/blue/faqs_blue.svg'
              />
            </div>
          </div>
        </div>
        <div className=" row col-md-6 col-lg-6 col-sm-12 mb-4" >
          <div className='col-md-6 col-lg-6 col-sm-12 col-xs-12 py-2 ps-0' style={{ objectFit: 'contain' }}>
            {loading ?
              <div className="d-flex justify-content-center align-items-center"
                style={
                  mode != 'dark' ? {
                    height: 350,
                    borderRadius: '50px',
                    background: 'white',
                    padding: 3
                  } : {
                    height: '100%',
                    borderRadius: '50px',
                    background: '#1e1e2d',
                    padding: 3,
                    border: `1px solid`,
                    borderColor: '#2d30e1'
                  }
                }>
                <div className="spinner-grow text-red" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
              : <>
                {typeUser === 'COURTESY' ? <>
                  {windowMode == 'NORMAL' &&
                    <CardCourse
                      className={settings?.numberTasksPending && settings.numberTasksPending > 0 ? 'breathing-blue-effect' : undefined}
                      title='Imagen del curso'
                      imageUrl={toAbsoluteUrl(`${settings?.banner}`)}
                      url={`/courses/${settings?.currentCourse}`}
                    />
                  }
                  {windowMode == 'WELCOME' &&
                    <CardCourse
                      className='breathing-blue-effect'
                      title='Welcome Day'
                      imageUrl={toAbsoluteUrl('/media/svg/michigan-icons/welcome_day.png')}
                      url={(settings?.taskId && settings?.evaluationId) ? `/evaluation/screen/course/${settings.evaluationId}` : '#'}
                    />
                  }
                  {windowMode == 'VERIFICATION' &&
                    <CardCourse
                      className='breathing-blue-effect'
                      title='Verificacion'
                      imageUrl='https://media.istockphoto.com/id/531013983/vector/delivery-verification-banner-design.jpg?s=612x612&w=is&k=20&c=9J6WCOjjGVlQmHHPu0mD6ElFEL7RaqclyfFn3MdxnDA='
                      url={'#'}
                    />
                  }
                </> : typeUser === 'PLACEMENT_TEST' &&
                <CardCourse
                  className='breathing-blue-effect'
                  title='Placement Test'
                  imageUrl={toAbsoluteUrl('/media/svg/michigan-icons/placement_test.png')}
                  url={(settings?.taskId && settings?.evaluationId) ? `/evaluation/screen/course/${settings.evaluationId}` : '#'}
                />
                }
              </>
            }
          </div>
          <div className='col-md-6 col-lg-6 col-sm-12 col-xs-12 py-2' style={{ objectFit: 'contain' }} >
            <AdvertisementsCard />
          </div>
        </div>
      </div>
      {courtesy && !courtesy.isUsed &&
        <CourtesyRegisterDataModal show />
      }
    </div>
  )
};

export { CourtesyStudentDashboardMain }