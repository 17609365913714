// Word.tsx
import React from 'react';
import { useDrag } from 'react-dnd';
import { WordItem } from './types';

interface WordProps {
  word: WordItem;
  isUsed: boolean; // Nuevo
  exerciseId: string
  selectedWord: WordItem | null
  setSelectedWord: (selectedWord: WordItem | null)=>void
}

const Word: React.FC<WordProps> = ({ word,isUsed, exerciseId,selectedWord, setSelectedWord}) => {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: `word-${exerciseId}`, 
    item: word,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: !isUsed,
  }), [exerciseId, word, isUsed]);

  const handleClick = () => {
    if(isUsed) {
      setSelectedWord(null)
    }else {
      setSelectedWord(word); // Selecciona la palabra
    }
  };
  
  const isSelected = selectedWord?.id === word.id
  return (    
    <div
      ref={dragRef}
      className='champ-light'
      onClick={handleClick}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: isUsed ? 'default' : 'move', // Cambia el cursor si la palabra está utilizada
        margin: '0 5px',
        padding: '10px 5px 5px 10px',
        backgroundColor:isSelected?'white': isUsed?'#d0ff00':'#2b22e5', // Fondo transparente
        border:isSelected? '1px black': isUsed?'1px solid black':'', // Borde blanco
        color:isSelected?'black':isUsed?'black':'white', // Texto blanco
        borderRadius: '30px',
        fontSize:12,
        // textDecoration:isUsed?'line-through':''
      }}
    >
      {word.text}
    </div>
  );
};

export default Word;
