import React from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { SanitizeHtml } from "../../../courses/components/media/html";
import AudioPlayer from "./AudioPlayer";
import { UseAudioInteractionModule } from "./store/main";
import { AudioInteractionEnum } from "../../../admin/exercises/templates-exercise/book/audio-interaction/interfaces";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {};

const AudioInteraction: React.FC<Props> = () => {
  const {currentContent} = UseAudioInteractionModule();
  const [showContent, setShowContent] = React.useState<boolean>(false);
  const [htmlContent, setHtmlContent] = React.useState<string | null>(null);
  
  React.useEffect(() => {
    const fetchHtmlContent = async () => {
      try {
        const response = await fetch(currentContent?.htmlContent?.location ?? '');
        const htmlText = await response.text();
        const sanitizedHtml = SanitizeHtml(htmlText);
        setHtmlContent(sanitizedHtml);
      } catch (error) {
        console.error("Error al cargar el HTML:", error);
      }
    };
    fetchHtmlContent();
  }, [currentContent]);

  return (<>
    <div>
      <div className='container-fluid d-flex justify-content-center '>
        <div
          className='d-flex justify-content-center align-items-center'
          style={{
            borderRadius: '30px',
            border: `solid ${currentContent?.borderColor ?? 'rgb(255, 255, 255)'}`,
            width: '350px',
            height: '60px',
            backgroundColor: currentContent?.colorPrimary ?? '#3f8bff',
            zIndex: 1
          }}>
          <AudioPlayer
            audioUrl={currentContent?.audio?.location}
            color={currentContent?.borderColor ?? 'rgb(255, 255, 255)'}
          />
          <button className="btn p-0 ms-1"
            disabled={!currentContent?.htmlContent}>
            <OverlayTrigger 
              overlay={
                <Tooltip className='tooltip-inverse'> 
                  <div className='fs-8 champ-bold d-flex justify-content-start text-primary'>Click para ver la conversación</div>
                </Tooltip>} placement='right'>
                <img alt="transcript"
                  className='cursor-pointer'
                  onClick={() => setShowContent(!showContent)}
                  src={toAbsoluteUrl('/media/svg/michigan-icons/elements/white/text_audio.svg')}
                  style={{ width: '20px' }} 
                />
            </OverlayTrigger>
          </button>
        </div>
      </div>
    </div>
    {showContent &&
      <div className='container-fluid d-flex' style={{ marginTop: '-45px' }}>
        <div className="rounded-top-9"
          style={{
            borderRadius: '30px',
            border: 'solid rgb(255, 255, 255)',
            width: '350px',
            height: '410px',
            backgroundColor: '#3f8bff',
          }}>
          {currentContent &&
            <div className="" style={{ marginTop: '45px', marginLeft: '20px', marginRight: '20px', marginBottom: '15px' }}>
              <div className="d-flex align-items-center">
                <img alt="audio-interaction"
                  className='cursor-pointer'
                  src={
                    toAbsoluteUrl(`/media/svg/michigan-icons/ebook/${currentContent.type === AudioInteractionEnum.READING ? 'reading_white'
                      : currentContent.type === AudioInteractionEnum.CONVERSATION ? 'conversation_white'
                        : currentContent.type === AudioInteractionEnum.IN_A_NUTSHELL ? 'in_a_nutshell_white'
                          : currentContent.type === AudioInteractionEnum.NOW_PRONOUNCE && 'now_pronounce_white'}.svg`)}
                  style={{ width: '35px' }} />
                <span className="champ-bold text-white ms-4 mt-1 scroll-y fs-2" style={{ maxHeight: '95px' }}>{currentContent?.title}</span>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: htmlContent || '' }}
                style={{
                  maxHeight: '245px',
                  marginTop: '7px',
                }}
                className="scroll-y"
              />
            </div>}
        </div>
      </div>
    }
  </>)
};

export { AudioInteraction };