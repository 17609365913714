import React from 'react';

type Props = {
  message: string;
  colorText: string;
  color: string;
  showBreath: boolean;
};

const MessageHeader: React.FC<Props> = ({ message, color, colorText, showBreath }) => {
  const [showMessage, setShowMessage] = React.useState<boolean>(true);
  return (<>
    {showMessage &&
      <div className={`d-flex justify-content-between align-items-center rounded ${showBreath && 'breathing-blue-effect'}`}
        style={{
          backgroundColor: color
        }}>
        <span className='poppins-light' style={{ color: colorText }}>
          {message}
        </span>
        <button type='button' className='btn' onClick={() => setShowMessage(!showMessage)}>
          <i className="fa-solid fa-circle-xmark" style={{ color: colorText }}></i>
        </button>
      </div>
    }
  </>)
};

export { MessageHeader };