import { showSimpleAlert } from "../../../commonHooks/alert";
import { UseModuleCompleteSentence } from "../../../components/abroad/e-book/beyong-the-reading/ExerciseOne/store/main";
import { UseModuleExerciseHighlightWord } from "../../../components/abroad/e-book/beyong-the-reading/ExerciseSeven/store/main";
import { ExercisesType } from "../../admin/exercises/interfaces";
import { CorrectSentenceItemQuestionsAnswer } from "../../courses/exercises/CorrectSentence/interfaces";
import { UseCorrectSentenceItemModule } from "../../courses/exercises/CorrectSentence/store";
import { ClueResponse, UseModuleExerciseCrossword } from "../../courses/exercises/Crossword/store/main";
import { WritingCheckQuestionsAnswer } from "../../courses/exercises/WrintingCheckList/interfaces";
import { UseWritingCheckListModule } from "../../courses/exercises/WrintingCheckList/store";
import { UseBoosterExercise } from "../../courses/exercises/booster/main/main";
import { UseCheckWritingModule } from "../../courses/exercises/check-writing/store";
import { UseCoordinatesEleven } from "../../courses/exercises/coordinates/main/main";
import { UseGroupWordsModules } from "../../courses/exercises/group-words/main/main";
import { MultipleSelectionQuestionAnswerIE } from "../../courses/exercises/multiple-selection/interfaces";
import { UseMultipleSelectionModule } from "../../courses/exercises/multiple-selection/store";
import { AnswerOrderSentences } from "../../courses/exercises/order-sentence/interfaces";
import { UseOrderSentencesModule } from "../../courses/exercises/order-sentence/store";
import { UseParagraphDragDropModule } from "../../courses/exercises/paragraph-drag-drop/store";
import { UseSpeakOutModule } from "../../courses/exercises/speak-out/store";
import { UseTrueFalseModule } from "../../courses/exercises/true-false/store";
import { UseTrueOptionsModule } from "../../courses/exercises/true-options/store";
import { TrueWordsItemQuestionsAnswer } from "../../courses/exercises/true-words/interfaces";
import { UseTrueWordsModule } from "../../courses/exercises/true-words/store";
import { transformStringToJson } from "../modules/test/store/main";

export const setAnswersEbook = (task: any, type: ExercisesType, exerciseId: string) => { //tipar bien Exercise con todos los ejercicios que puede retornar

    const { setAnswer: setCrossword } = UseModuleExerciseCrossword.getState();
    const { setResponseStudent: setOrderSentence } = UseOrderSentencesModule.getState();
    const { setResponseStudent: setWritingCheckList } = UseWritingCheckListModule.getState();
    const { setStudentAnswers: setHighlightWord } = UseModuleExerciseHighlightWord.getState();
    const { setAnswer: setCorrectSentence } = UseCorrectSentenceItemModule.getState();
    const { setAnswer: setTrueWords } = UseTrueWordsModule.getState();
    const { setAnswer: setTrueFalse } = UseTrueFalseModule.getState();
    const { setResponseStudent: setTrueOptions } = UseTrueOptionsModule.getState();
    const { setAnswer: setMultipleSelection } = UseMultipleSelectionModule.getState();
    // const {setContent} = UseAudioInteractionModule.getState();
    const { setAnswer: setGroupWords } = UseGroupWordsModules.getState();
    const { setAnswer: setCoordinates } = UseCoordinatesEleven.getState();
    const { setStudentResponse: setBooster } = UseBoosterExercise.getState();
    const { setAnswer: setCheck } = UseCheckWritingModule.getState();
    const { setAnswer: setCompleteSentence } = UseModuleCompleteSentence.getState();
    const { setStudentAnswer: setSpeakOut } = UseSpeakOutModule.getState();
    const { setAnswerStudent: setDrag } = UseParagraphDragDropModule.getState();

    switch (type) {
        case ExercisesType.CROSSWORD:
            const sentencesCross = transformStringToJson(
                task,
                'crossword',
                'answer',
                'camp',
                exerciseId
            );
            setCrossword(
                sentencesCross as ClueResponse[],
            );
            break;
        case ExercisesType.ORDER_SENTENCES:
            const answer = transformStringToJson(
                task, 'order_sentences', 'answer', 'camp', exerciseId
            );
            setOrderSentence(
                answer as AnswerOrderSentences[]
            );
            break;
        case ExercisesType.WRITING_CHECK_LIST:
            let questionsWriting = transformStringToJson(
                task,
                'writing_check',
                'answer',
                'camp', exerciseId
            );
            // questionsWriting = {...questionsWriting, teacherObservation: }
            setWritingCheckList(
                questionsWriting as WritingCheckQuestionsAnswer[]
            );
            break;
        case ExercisesType.HIGHLIGHT_WORD:
            const categories = transformStringToJson(
                task,
                'highlight_word',
                'answer',
                'camp', exerciseId
            );
            setHighlightWord(
                categories,
            );
            break;
        case ExercisesType.TRUE_OPTIONS:
            const optionsTrueOptions = transformStringToJson(
                task, 'true_option', 'answers', 'camp', exerciseId
            );
            setTrueOptions(
                optionsTrueOptions
            );
            break;
        case ExercisesType.MULTIPLE_SELECTION:
            const questions = transformStringToJson(
                task, 'multiple_selection', 'answers', 'camp', exerciseId
            );
            setMultipleSelection(
                questions as MultipleSelectionQuestionAnswerIE[]
            );
            break;
        case ExercisesType.DRAG_DROP: // falta el response
            const words = transformStringToJson(
                task, 'drag_drop', 'true_answer', 'camp', exerciseId
            );
            setDrag(
                words
            );
            break;
        case ExercisesType.CORRECT_SENTENCES:
            const question = transformStringToJson(
                task, 'correct_sentence', 'answer', 'camp', exerciseId
            );
            setCorrectSentence(
                question as CorrectSentenceItemQuestionsAnswer[]
            );
            break;
        case ExercisesType.TRUE_WORDS:
            const sentencesTrueWords = transformStringToJson(
                task, 'true_words', 'answers', 'camp', exerciseId
            );
            setTrueWords(
                sentencesTrueWords as TrueWordsItemQuestionsAnswer[]
            );
            break;
        case ExercisesType.HISTORY_WRITING: // falta el result
            const questionHistory = transformStringToJson(
                task, 'history_writing', 'questions', 'camp', exerciseId
            );
            // setQuestionsHistory(
            //     questionHistory as  QuestionHistoryWriting[]
            // );
            break;
        case ExercisesType.TRUE_FALSE:
            const answerTrueFalse = transformStringToJson(
                task, 'true_false', 'answer', 'camp', exerciseId
            );
            setTrueFalse({
                answer: answerTrueFalse,
                percentage: task?.true_false[0]?.percentage ?? 0
            });
            break;
        case ExercisesType.AUDIO_INTERACTION:
            // setContent(
            //     task.audio_interaction[0]
            // );
            break;
        case ExercisesType.GROUP_WORDS:
            const groups = transformStringToJson(
                task, 'group_word', 'answers', 'camp', exerciseId
            );
            setGroupWords(groups);
            break;
        case ExercisesType.COORDINATES:
            const rowCoordinates = transformStringToJson(
                task, 'coordinates', 'answers', 'camp', exerciseId
            );
            setCoordinates(
                rowCoordinates
            );
            break;
        case ExercisesType.SPEAK_OUT:
            
            if (task.speak_out && task.speak_out.length > 0) {
                console.log("speak_out",task.speak_out.find(x=>x.exercises.id === exerciseId).description);
                setSpeakOut({
                    title: task.speak_out.find(x=>x.exercises.id === exerciseId).name,
                    description: task.speak_out.find(x=>x.exercises.id === exerciseId).description
                });
            }
            break;
        case ExercisesType.BOOSTER:
            if (task.booster && task.booster.length > 0) {
                setBooster(
                    task.booster[0].answer
                );
            }
            break;
        case ExercisesType.CHECK_WRITING:
            const questionsCheck = transformStringToJson(
                task, 'check_writing', 'answers', 'camp', exerciseId
            );
            const questionsTheme = transformStringToJson(
                task, 'check_writing', 'answerThemes', 'camp', exerciseId
            );
            setCheck({
                answer: questionsCheck,
                answersThemes: questionsTheme,
            });
            break;
        case ExercisesType.COMPLETE_SENTENCES:
            if (task && task.complete_sentence && task.complete_sentence.length > 0) {
                const questionCom = transformStringToJson(
                    task, 'complete_sentence', 'answer', 'camp', exerciseId
                );
                setCompleteSentence(
                    questionCom, task.complete_sentence[0].percentage
                );
            }
            break;
        default:
            return showSimpleAlert({ message: 'Tipo de ejercicio no permitido', icon: 'error' });
    }
};