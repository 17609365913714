import React, { useEffect, useMemo } from 'react';
import { MainCardContent } from '../../prismatics/component/main-content';
import { HeaderCourses } from '../header';
import { UseModeCourseModule } from '../front-page/store/main';
import { UseEvaluationTestModule } from '../../../admin/evaluation/modules/test/store/main';
import { UseEvaluationCourseModule } from '../../screen/store/main';
import { ExercisesType, VariantsExercisesType } from '../../../admin/exercises/interfaces';
import BasicVocabularyFullExercise from '../../exercises/basic-vocabulary/full-screen';
import BasicVocabularyCompletionFullScreenExercise from '../../exercises/basic-vocabulary-completion/full-screen';
import { TypeDragDrop } from '../../../admin/exercises/templates-exercise/drag-drop-questions/interfaces';
import { ParagraphDragDrop } from '../../exercises/paragraph-drag-drop';
import { WordBoxContent } from '../../exercises/paragraph-drag-drop/word-box-content';
import TrueFalseFullExercise from '../../exercises/true-false/full-screen';
import HistoryWritingFullExercise from '../../exercises/history-wrinting/full-screen';
import MovieWritingFullExercise from '../../exercises/movie-writing/full-screen';
import { LearningPathType } from '../../../abroad/learning-path/interfaces';
import { EvaluationType } from '../../../admin/evaluation/interfaces';
import { TitleContentCourse } from '../../../../components/common/TitleContentCourse';
import RenderingContentFullScreen from '../../exercises/RenderingContentFullScreen';
import { GlossaryDrawer, TipsDrawer } from '../../../../../_metronic/partials';
import AudioTestFullScreenExercise from '../../exercises/audio-test/full-screen'
import FinalEvaluationStats from '../finalStats';
import { getTrueQuizAnswers } from '../../utils/getTrueQuizAnswers';
import { boolean } from 'yup';

type Props = {
  colorPrimary: string;
  colorSecondary: string;
  logoCourse: string;
  nameTopic: string;
  typeEvaluation: EvaluationType;
  typeCourse: LearningPathType;
};

const ContainerFather: React.FC<Props> = ({
  colorPrimary,
  colorSecondary,
  logoCourse,
  nameTopic,
  typeEvaluation,
}) => {
  const { modeCourseChecked, modeCourse, modeTest, modeViewAnswer, setColor } = UseModeCourseModule();
  const { stepEvaluation, questionsEvaluation, evaluationLesson, showEndEvaluation, setShowEndEvaluation, resetEvaluationResponses } = UseEvaluationCourseModule();
  const { stepEvaluationTest, questionsEvaluationTest } = UseEvaluationTestModule();
  const memoryQuestion = useMemo(()=>({
    ...questionsEvaluation
  }),[questionsEvaluation])
  const [showContent, setShowContent] = React.useState<boolean>();
  useEffect(() => {
    modeCourseChecked();
  }, [modeCourseChecked]);

  useEffect(() => {
    setShowEndEvaluation(false)
    resetEvaluationResponses();
    if (modeViewAnswer) {
      if (evaluationLesson?.evaluation_item) {
        getTrueQuizAnswers(evaluationLesson?.evaluation_item); 
      }
    }
  }, [evaluationLesson, modeViewAnswer]);

  useEffect(() => {
    setColor(colorPrimary)
  },[colorPrimary]
)

  const renderExercise = React.useCallback((type: 'left' | 'right') => {
    if (typeEvaluation !== EvaluationType.SINGLE_QUIZ) {
      if(showEndEvaluation || modeViewAnswer){
        return(
          <FinalEvaluationStats
            colorSecondary={colorSecondary}
            renderType={type}
          />
        )
      } else if(modeCourse && !modeTest && stepEvaluation + 1 === memoryQuestion?.order) {
        switch (memoryQuestion?.exercise?.type) {
          case ExercisesType.SIMPLE_SELECTION:
            if (memoryQuestion.exercise?.variant === VariantsExercisesType.FULL_SCREEN) {
              return (
                <BasicVocabularyFullExercise
                  titleContent={memoryQuestion?.exercise?.name ?? 'Michigan Master'}
                  colorSecondary={colorSecondary}
                  descriptionContent={memoryQuestion?.exercise?.description ?? ''}
                  renderType={type}
                />
              );
            }
            break;
          case ExercisesType.SIMPLE_SELECTION_COMPLETION:
            if (memoryQuestion.exercise?.variant === VariantsExercisesType.FULL_SCREEN) {
              return (
                <BasicVocabularyCompletionFullScreenExercise 
                  titleContent={memoryQuestion?.exercise?.name ?? 'Michigan Master'}
                  colorSecondary={colorSecondary}
                  descriptionContent={memoryQuestion?.exercise?.description ?? ''}
                  renderType={type}
                />
              );
            }
            break;
          case ExercisesType.DRAG_DROP:
            if (memoryQuestion.exercise?.variant === VariantsExercisesType.FULL_SCREEN) {
              if (memoryQuestion?.exercise?.drag_drop_questions?.length > 0) {
                switch (memoryQuestion.exercise.drag_drop_questions[0]?.type) {
                  case TypeDragDrop.WORD_BOX_V1:
                    return ( //todo: revisar feedback
                      <ParagraphDragDrop
                        colorSecondary={colorSecondary}
                        titleContent={memoryQuestion?.exercise?.name ?? ""}
                        descriptionContent={memoryQuestion?.exercise?.description ?? ""}
                        renderType={type}
                        showContent={(e) => setShowContent(e)}
                      />
                    );
                  case TypeDragDrop.WORD_BOX_V2:
                    return ( //todo: revisar feedback
                      <WordBoxContent
                        colorSecondary={colorSecondary}
                        titleContent={memoryQuestion?.exercise?.name ?? ""}
                        descriptionContent={memoryQuestion?.exercise?.description ?? ""}
                        renderType={type}
                      />
                    );
                }
              }
            }
            break;
          case ExercisesType.TRUE_FALSE:
            if (memoryQuestion.exercise?.variant === VariantsExercisesType.FULL_SCREEN) {
              return (
                <TrueFalseFullExercise
                  titleContent={memoryQuestion?.exercise?.name ?? ""}
                  descriptionContent={memoryQuestion?.exercise?.description ?? ""}
                  colorSecondary={colorSecondary}
                  renderType={type}
                />
              );
            }
            break;
          case ExercisesType.HISTORY_WRITING:
            if (memoryQuestion.exercise?.variant === VariantsExercisesType.FULL_SCREEN) {
              return ( 
                <HistoryWritingFullExercise
                  titleContent={memoryQuestion?.exercise?.name ?? ""}
                  descriptionContent={memoryQuestion?.exercise?.description ?? ""}
                  colorSecondary={colorSecondary}
                  renderType={type}
                />
              );
            }
            break;
          case ExercisesType.MOVIE_WRITING:
            if (memoryQuestion.exercise?.variant === VariantsExercisesType.FULL_SCREEN) {
              return ( 
                <MovieWritingFullExercise
                  colorSecondary={colorSecondary}
                  renderType={type}
                />
              );
            }
            break;
          case ExercisesType.AUDIO_TEST:
            if (memoryQuestion.exercise?.variant === VariantsExercisesType.FULL_SCREEN) {
              return ( 
                <AudioTestFullScreenExercise
                  titleContent={memoryQuestion?.exercise?.name ?? 'Michigan Master'}
                  colorSecondary={colorSecondary}
                  descriptionContent={memoryQuestion?.exercise?.description ?? ''}
                  renderType={type}
                />
              );
            }
            break;
          default:
            return null;
        }
      } else if (modeTest && !modeCourse && stepEvaluationTest + 1 === questionsEvaluationTest?.order) {
        switch (questionsEvaluationTest?.exercise?.type) {
          case ExercisesType.SIMPLE_SELECTION:
            if (questionsEvaluationTest.exercise?.variant === VariantsExercisesType.FULL_SCREEN) {
              return ( 
                <BasicVocabularyFullExercise
                  titleContent={questionsEvaluationTest?.exercise?.name ?? 'Michigan Master'}
                  colorSecondary={colorSecondary}
                  descriptionContent={memoryQuestion?.exercise?.description ?? ""}
                  renderType={type}
                />
              );
            }
            break;
          case ExercisesType.SIMPLE_SELECTION_COMPLETION:
            if (questionsEvaluationTest.exercise?.variant === VariantsExercisesType.FULL_SCREEN) {
              return ( 
                <BasicVocabularyCompletionFullScreenExercise
                  titleContent={questionsEvaluationTest?.exercise?.name ?? 'Michigan Master'}
                  colorSecondary={colorSecondary}
                  descriptionContent={memoryQuestion?.exercise?.description ?? ""}
                  renderType={type}
                />
              );
            }
            break;
          case ExercisesType.DRAG_DROP:
            if (questionsEvaluationTest.exercise?.variant === VariantsExercisesType.FULL_SCREEN) {
              if (questionsEvaluationTest?.exercise?.drag_drop_questions?.length > 0) {
                switch (questionsEvaluationTest.exercise.drag_drop_questions[0]?.type) {
                  case TypeDragDrop.WORD_BOX_V1:
                    return ( 
                      <ParagraphDragDrop
                        renderType={type}
                        colorSecondary={colorSecondary}
                        titleContent={questionsEvaluationTest?.exercise?.name}
                        descriptionContent={questionsEvaluationTest?.description}
                        showContent={(e) => setShowContent(e)}
                      />
                    );
                  case TypeDragDrop.WORD_BOX_V2:
                    return ( 
                      <WordBoxContent
                        renderType={type}
                        colorSecondary={colorSecondary}
                        titleContent={questionsEvaluationTest?.exercise?.name}
                        descriptionContent={questionsEvaluationTest?.description}
                      />
                    );
                }
              }
            }
            break;
          case ExercisesType.TRUE_FALSE:
            if (questionsEvaluationTest.exercise?.variant === VariantsExercisesType.FULL_SCREEN) {
              return ( 
                <TrueFalseFullExercise
                  titleContent={questionsEvaluationTest?.exercise?.name}
                  descriptionContent={questionsEvaluationTest?.description}
                  colorSecondary={colorSecondary}
                  renderType={type}
                />
              );
            }
            break;
          case ExercisesType.HISTORY_WRITING:
            if (questionsEvaluationTest.exercise?.variant === VariantsExercisesType.FULL_SCREEN) {
              return ( 
                <HistoryWritingFullExercise
                  titleContent={questionsEvaluationTest?.exercise?.name}
                  descriptionContent={questionsEvaluationTest?.description}
                  colorSecondary={colorSecondary}
                  renderType={type}
                />
              );
            }
            break;
          case ExercisesType.MOVIE_WRITING:
            if (questionsEvaluationTest.exercise?.variant === VariantsExercisesType.FULL_SCREEN) {
              return ( 
                <MovieWritingFullExercise
                  // titleContent={questionsEvaluationTest?.exercise?.name}
                  // descriptionContent={questionsEvaluationTest?.description}
                  colorSecondary={colorSecondary}
                  renderType={type}
                />
              );
            }
            break;
          case ExercisesType.AUDIO_TEST:
            if (questionsEvaluationTest.exercise?.variant === VariantsExercisesType.FULL_SCREEN) {
              return ( 
                <AudioTestFullScreenExercise
                  titleContent={questionsEvaluationTest?.exercise?.name ?? 'Michigan Master'}
                  colorSecondary={colorSecondary}
                  descriptionContent={questionsEvaluationTest?.exercise?.description ?? ''}
                  renderType={type}
                />
              );
            }
            break;
          default:
            return null;
        }
      }
    }
    return null;
  }, [typeEvaluation, modeCourse, modeTest, stepEvaluation, stepEvaluationTest, memoryQuestion?.order, questionsEvaluationTest?.order, colorSecondary, showEndEvaluation, modeViewAnswer]);

  return ( 
    <div className=''>
       {/* <video ref={videoRef} controls autoPlay style={{ maxWidth: '100%' }} /> */}
      {<>
      <div className=''>
        <HeaderCourses logoCourse={logoCourse} nameTopic={nameTopic} colorCourse={colorPrimary} evaluationType={ typeEvaluation }/>
      </div>
      <div className='' style={{ marginTop: '3%' }}>
        {typeEvaluation === EvaluationType.SINGLE_QUIZ && (
          <MainCardContent
            colorSecondary={colorSecondary}
            titleContent={memoryQuestion?.exercise?.name ?? 'Michigan Master'}
            descriptionContent={memoryQuestion?.description ?? ''}
          />
        )}
        {typeEvaluation !== EvaluationType.SINGLE_QUIZ && <>
          <div className="row d-none d-md-block">
            <div className='d-flex justify-content-end row' style={{ marginTop: '-2%' }}>
              <div className='col-10 d-flex justify-content-end align-items-end'>
                <img
                  src={evaluationLesson?.cover?.location}
                  alt="michigan"
                  className='position-absolute'
                  style={{ width: '400px', top: '0px' }}
                />
              </div>
              <div className='col-2 d-flex justify-content-end align-items-end flex-column'>
                <GlossaryDrawer />
                <TipsDrawer />
              </div>
            </div>
            <div className='col-12' style={{ marginTop: '-5%' }}>
              <div className='row '>
                <div
                  style={{ zIndex: 2, padding: 0, backgroundColor: colorSecondary }}
                  className='col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12  card-with-extended-background content-course-card-left'>
                  <div style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    width: '100%',
                  }}>
                    <TitleContentCourse
                      title={memoryQuestion?.name ?? ''}
                      description={memoryQuestion?.description ?? ''}
                    />
                    <RenderingContentFullScreen 
                      isDragButton={showContent} 
                      exerciseType={memoryQuestion?.exercise.type}>
                      {renderExercise('left')}
                    </RenderingContentFullScreen>
                  </div>
                </div>
                
                <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12 content-course-card-right" style={{ zIndex: 1, position: 'relative' }}>
                  <div style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%'
                  }}>
                    {renderExercise('right')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="row d-block d-md-none">
            <div className='col-12 '>
              <div className="row ">
                { showEndEvaluation ?
                  <>
                    <div
                      style={{ zIndex: 2,paddingLeft:12,paddingRight:12,paddingTop:40,borderRadius:'30px 30px 0px 0px', background:colorSecondary}}
                      className='col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12  '>
                          <TitleContentCourse 
                              title={"Feedback"}
                              description={`este es un feedback de su ${evaluationLesson?.name}`}
                          />
                            <RenderingContentFullScreen 
                              isDragButton={showContent}
                              exerciseType={memoryQuestion?.exercise.type}>
                            {renderExercise('left')}
                          </RenderingContentFullScreen>
                    </div>
                    
                    <div 
                      className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12"  
                      style={{paddingLeft:12,paddingRight:12}}>
                        {renderExercise('right')}
                    </div>
                  </> 
                  :
                  <>
                    <div
                      style={{ zIndex: 2,paddingLeft:12,paddingRight:12,paddingTop:40,borderRadius:'30px 30px 0px 0px', background:colorSecondary}}
                      className='col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12  '>
                          <TitleContentCourse 
                              title={memoryQuestion?.name ?? ''}
                              description={memoryQuestion?.description ?? ''}
                          />
                            <RenderingContentFullScreen 
                              isDragButton={showContent}
                              exerciseType={memoryQuestion?.exercise.type}>
                            {renderExercise('left')}
                          </RenderingContentFullScreen>
                    </div>
                    
                    <div 
                      className="col-md-6 col-lg-6 col-xl-6 col-sm-12 col-xs-12"  
                      style={{paddingLeft:12,paddingRight:12}}>
                        {renderExercise('right')}
                    </div>
                  </>
                } 
                <div className='col-12 row mx-1'>
                  <div className='col-6 d-flex justify-content-center align-items-center'>
                    <GlossaryDrawer />
                  </div>
                  <div className='col-6 d-flex justify-content-center align-items-center'>
                    <TipsDrawer />
                  </div>
              </div>
              </div>
            </div>
          </div>
        </>}
      </div>
      </>}
    </div>
  );
}

export default React.memo(ContainerFather);