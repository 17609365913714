import useAuth from "../../../../../api/main";
import { showSimpleAlert } from "../../../../../commonHooks/alert";
import { TeacherResponseExercise } from "../../../../admin/exercises/interfaces";
import { api } from "../../../../auth/services/auth.services";
import { ThemeAnswer } from "../components/item";
import { CheckWritingQuestionAnswerIE } from "../interfaces";

const { token, logout, authChecked } = useAuth.getState();
interface ResponseCheckingResponse {
  description?: string;
  isActive: boolean;
  isReviewed: boolean;
  isPending: boolean;
  answers: CheckWritingQuestionAnswerIE[];
  task: string;
  exercises: string;
  percentage: number;
  answerThemes: ThemeAnswer[];

}

export const AddCheckWrittingResponse = async (data: ResponseCheckingResponse): Promise<any> => {
    try {
      const response: any = await api.post(
        '/check-writing-response',
        {
          ...data,
        },
        {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSimpleAlert({ message: 'Ok!', icon: 'success' })
      return response.data
      
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      //@ts-ignore
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
  
      throw 'Error'
    }
  }

  export const AddTeacherCheckWritingResponse = async (id: string, data: TeacherResponseExercise): Promise<any> => {
    try {
      const response: any = await api.patch(
        `/check-writing-response/${id}`,
        {
          ...data,
        },
        {
          headers: { 
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSimpleAlert({ message: 'Ok!', icon: 'success' })
      return response.data
      
    } catch (e:any) {
      //@ts-ignore
      if (e.response.status === 401) {
        logout()
      }
      //@ts-ignore
      if (e.response.status === 400) {
        e.response.data.message.map((x)=>{
          //@ts-ignore
          showSimpleAlert({ message: x, icon: 'error' })
        })
        throw 'Error'
      }
  
      throw 'Error'
    }
  }