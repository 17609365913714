import React, { useRef, useState } from 'react'
import { HeaderBook } from '../header'
import { ToolbarBook } from '../toolbar'
import { RenderBook } from '../render-book'
import { UseBookStudentModule } from '../../modules/screen/store/main'
import '../toolbar/ColorPicker.css'
import { RenderItemExercise } from '../interactions/RenderItemExercise'
import { TaskStatus } from '../../../admin/tasks/interfaces'
import { useLocation } from 'react-router-dom'
import { UseBookContainerModule } from '../../store/main'
import { CurrentPage } from '../../modules/test/store/main'
import { MessageHeader } from '../header/messageHeader'

type Props = {}

const ContainerBook: React.FC<Props> = () => {
  const location = useLocation();
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    lessonData,
    currentInteractiveItems,
    task,
    statePage,
    currentPage,
    setStatePage,
    setCurrentsItemsTest,
    setCurrentPage,
    setDeleteCurrentItem,
    setAllAnswered,
    resetCurrentItems,
  } = UseBookStudentModule()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  React.useEffect(() => {
    if (task) {
      if (task.status === TaskStatus.APPROVED || task.status === TaskStatus.IN_REVIEW || task.status === TaskStatus.QUALIFIED) {
        setAllAnswered(true);
      } else {
        setAllAnswered(false);
      }
    }
  }, [task]);

  React.useEffect(() => {
    setCurrentPage(1);
  }, []);

  React.useEffect(() => {
    resetCurrentItems();

    window.addEventListener('beforeunload', resetCurrentItems);
    window.addEventListener('popstate', resetCurrentItems);
    window.addEventListener('hashchange', resetCurrentItems);

    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && (event.key === '+' || event.key === '-' || event.key === '0')) {
        event.preventDefault(); // Previene el zoom con Ctrl + (+/-/0)
      }
    };

    const handleWheel = (event: WheelEvent) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault(); // Previene el zoom con Ctrl + Scroll
      }
    };

    const handleTouchStart = (event: TouchEvent) => {
      // Aquí puedes manejar eventos táctiles si es necesario
      if (event.touches.length > 1) {
        event.preventDefault(); // Previene el zoom con gestos multitáctiles
      }
    };

    const handleTouchMove = (event: TouchEvent) => {
      // Aquí puedes manejar el movimiento táctil si es necesario
      if (event.touches.length > 1) {
        event.preventDefault(); // Previene el zoom con gestos multitáctiles
      }
    };

    window.addEventListener('keydown', handleKeyDown, { passive: false });

    if (containerRef.current) {
      containerRef.current.addEventListener('wheel', handleWheel, { passive: false });
      containerRef.current.addEventListener('touchstart', handleTouchStart, { passive: false });
      containerRef.current.addEventListener('touchmove', handleTouchMove, { passive: false });
    }

    return () => {
      window.removeEventListener('beforeunload', resetCurrentItems);
      window.removeEventListener('popstate', resetCurrentItems);
      window.removeEventListener('hashchange', resetCurrentItems);
      window.removeEventListener('keydown', handleKeyDown);
      if (containerRef.current) {
        containerRef.current.removeEventListener('wheel', handleWheel);
      }
    };

  }, [location]);

  const { toolsFullscreen } = UseBookContainerModule()

  const handleSetPage = async (e: CurrentPage) => {
    if ((e === 0 || e === 3)) {
      if (!isLoading) {
        setIsLoading(true)
        try {
          await setCurrentPage(e)
        } finally {
          setIsLoading(false)
        }
      }
    } else {
      setCurrentPage(e)
    }
  }
  return (
    <div ref={containerRef}>
      {!toolsFullscreen && (
        <HeaderBook
          mode='STUDENT'
          logoCourse={lessonData ? lessonData.chapter.path.logo.location : ''}
          colorCourse={lessonData ? lessonData.chapter.path.colorBase : ''}
          lessonName={lessonData ? lessonData.name : 'Michigan Master'}
        />
      )}

      {task.status === TaskStatus.APPROVED &&
        <div className='d-none d-lg-flex'>
          <div
            style={{
              position: 'fixed',
              top: 64,
              right: 300,
              display: 'grid',
              gridTemplateRows: 'auto 1fr auto',
              zIndex: 2,
              borderRadius: '8px',
              padding: '5px',
            }
            }
          >
            <MessageHeader
              color=''
              showBreath
              message='Esta leccion ya esta aprobada, recuerda que no tiene efecto en el agendador.' 
              colorText='red' />
          </div>
        </div>
      }

      <div className='d-none d-lg-flex'>
        <RenderBook
          numberPage={currentPage}
          numberRenderPage={statePage}
          pagesBook={
            lessonData?.book_pages ? lessonData.book_pages.sort((a, b) => a.page - b.page) : []
          }
        />
      </div>
      <div className='d-flex d-lg-none'>
        <RenderBook
          numberPage={currentPage}
          numberRenderPage={1}
          pagesBook={
            lessonData?.book_pages ? lessonData.book_pages.sort((a, b) => a.page - b.page) : []
          }
        />
      </div>
      <ToolbarBook
        onChangeStatePage={(e) => setStatePage(e ? 2 : 1)}
        statePage={statePage === 1 ? false : true}
        currentPage={currentPage}
        setCurrentPage={(e) => handleSetPage(e)}
        mode='STUDENT'
      />
      <RenderItemExercise
        typeUser={'STUDENT'}
        exercisesLesson={lessonData?.evaluation?.evaluation_item ?? []}
        currentExercises={currentInteractiveItems}
        onDeleteExercise={(e) => setDeleteCurrentItem(e)}
        setExercise={(e) => setCurrentsItemsTest(e, false)}
        addClickedInteractionsId={(e) => { }}
      />
    </div>
  )
}

export { ContainerBook }
