// Word.tsx
import React from 'react';
import { useDrag } from 'react-dnd';
import { WordItem } from './types';

interface WordProps {
  word: WordItem;
  isUsed: boolean;
  exerciseId: string
  onWordClick: (word:WordItem | null)=>void
  selectedWord: WordItem|null
}

const WordWordBoxReading: React.FC<WordProps> = ({ word,isUsed, exerciseId, onWordClick, selectedWord }) => {
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: `word-${exerciseId}`, 
    item: word,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: !isUsed,
  }), [exerciseId, word, isUsed]);
    
  const handleClick = () => {
    if(isUsed) {
      onWordClick(null)
    }else {
      onWordClick(word); // Selecciona la palabra
    }
  };
  const isSelected = selectedWord?.id === word.id
  return (  <>
    <div
      ref={dragRef}
      className='poppins-light'
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: isUsed ? 'default' : 'move', // Cambia el cursor si la palabra está utilizada
        margin: '0px',
        padding: '0px 5px 0px 5px',
        textAlign:'center',
        backgroundColor:isSelected? "yellow" :isUsed?'#d0ff00':'#ffffff', // Fondo transparente
        border: isSelected? "1px black":isUsed?'1px solid #3e94ff':'', // Borde blanco
        color: isSelected? "black" :isUsed?'#3e94ff':'#3e94ff', // Texto blanco
        borderRadius: '10px',
        fontSize:14,

        // textDecoration:isUsed?'line-through':''
      }}
      onClick={handleClick}
      // onMouseDown={()=>{
      //   setOffDraggable(true)
      // }}
    >
      {word.text}
    </div>
 
    </>);
};

export default WordWordBoxReading;
