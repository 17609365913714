import React from "react";

type Props = {
  disable? : boolean
  justGoBack? : boolean
  goBackAction?: () =>void
  onAction: () => void;
}

const ButtonTryAgain: React.FC<Props> = ({ onAction, justGoBack, goBackAction, disable}) => {
  return(<>
    <button type="button" onClick={justGoBack? goBackAction: onAction} 
      disabled={disable}
      className="btn champ-bold py-1 btn-light-primary btn-outline-white"
      style={{ 
        display:'flex', 
        fontSize: 14, 
        backgroundColor: 'white', 
        borderRadius: '20px', 
        paddingLeft:"0px",
        transition: 'color 0.3s ease', 
      }}
    >
      {justGoBack? 
      <span>
          <i className="bi bi-chevron-left me-2"></i>Back
      </span>
      :
      <span style={{display:"flex", alignItems:'center', color: 'inherit' }} >
        <i className="bi bi-chevron-left mx-0" style={{ fontSize:'22px', verticalAlign: 'middle', color: 'inherit' }} ></i>
      <span style={{ fontSize: '18px', marginLeft: '5px', color: 'inherit'  }}>Try again</span>
      </span>
      }
    </button>
  </>)
};

export {ButtonTryAgain};