import React, { useEffect, useState } from 'react';
import { UseExerciseTimerModule } from "../store/main";


type Props = {
  onNext: (v:any) => any;
  exerciseId: string | undefined;
  color?: string
}

const ButtonIntroEvaluationItem: React.FC<Props> = ({ onNext, exerciseId, color }) => {

  const { timeLeft } = UseExerciseTimerModule();
  const currentTimer = timeLeft.find(element => element.exerciseId === exerciseId);  
  const [NotAllowed, setNotAllowed] = useState<boolean>(true)
  useEffect(()=>{
    if(currentTimer){
      setNotAllowed((currentTimer.time) > 0)
    }
  },[timeLeft])
  return (
    <div
    className='cursor-pointer'
    style={{ marginTop:'auto', justifyContent:'end', display:'flex', cursor:`${NotAllowed? 'not-NotAllowed' : 'pointer'}`}}
    >
    <button
        type='button'
        disabled = {NotAllowed}
        onClick={()=>onNext(true)}
        title={NotAllowed?"Vea todo el contenido" : "Next"}
        className='poppins-light text-cursor-basic-vocabulary-right align-items-end' 
        style={{ border: 'none', backgroundColor: 'transparent', color: `${NotAllowed ? "#c1b7b7" : color}`, cursor:`${NotAllowed? 'not-NotAllowed' : 'pointer'}` }}
    >
        {NotAllowed?
        <span>
            {`Wait ${currentTimer?.time ?? ""}s`}
        </span>
        :
        <span style={{marginRight:'10px', verticalAlign:'middle', fontSize: '23px'}}>
            next <i className="bi bi-chevron-right" style={{ fontSize:'28px', verticalAlign: 'middle', color:color , marginLeft: '3px'  }} ></i> 
        </span>}
    </button>

</div>
  );
};

export { ButtonIntroEvaluationItem };
