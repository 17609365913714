import React, { useEffect, useState } from 'react'
import { BooleanCell } from '../../../../components/common/BooleanCell'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import { DayPickerRangeSelector } from '../../../../components/common/DayRangePicker/indext'
import { Campus } from '../../../../../app/modules/abroad/campus/interfaces'
import { UseCampusAll } from '../../../../../app/modules/abroad/campus/services'
import { UseBlockHourAll } from '../../../../../app/modules/abroad/block-hours/services'
import { BlockHour } from '../../../../../app/modules/abroad/block-hours/interfaces'
import { UseUserTypePaginate } from '../../../../../app/modules/admin/user/services'
import { Classroom } from '../../../../../app/modules/admin/classroom/interfaces'
import { UseClassroomAll } from '../../../admin/classroom/services'
import { UseActivityAll } from '../../../abroad/activity/services'
import { t } from 'i18next'
import { UseAppointmentAllPaginate, UseTrailClassAllPaginate } from '../../services/appoitment-report'
import { AppointmentListReport, AppointmentReport, TrailClassReport, TrailClassReportResult } from '../../interface'
import moment from 'moment'
import AllExcel from '../../../../components/common/AllExcel'
import { UseTrailClassFilterModules } from '../../store/trail-class-filter.store'
import { calculatePercentages, calculateStatistics, countAppointmentsByDay, Statistics } from './utils'
import { UserSearchStudent } from '../user-search'
import { WidgetTrailClassResume } from './wideget'
import { DayHourData } from './table-hour-resume'
import { TraditionalLevelEnum } from '../../../abroad/road-map-step/interfaces'

type PaginatorSetter = {
    currentPage: number;
    items: number;
    total: number;
    totalPage: number;
}
export interface DateRange {
    before: Date
    after: Date
}
type Props = {

}
const TrailClassReportTableList: React.FC<Props> = ({ }) => {
    const [modeTable, setModeTable] = useState<boolean>(true);
    const [summary, setSummary] = useState<DayHourData[]>();
    const [statistics, setStatistics] = useState<Statistics | null>(null);
    const [dataResume, setDataResume] = React.useState<TrailClassReport>();
    const [dataBlockHour, setDataBlockHour] = React.useState<BlockHour[]>([]);
    const [teacherData, setTeacherData] = React.useState<any[]>([]);
    const [classroomData, setClassroomData] = React.useState<Classroom[]>([]);
    const [activityData, setActivityData] = React.useState<any[]>([]);
    const [campusData, setCampusData] = React.useState<Campus[]>([]);


    const {
        typeClassroom,
        blockHour,
        campus,
        classroom,
        limit,
        currentPage,
        isActive,
        where,
        teacher,
        student,
        after,
        before,
        withStudents,
        setCurrentPage,
        setLimit,
        setIsActive,
        setStudent,
        setActivity,
        activity,
        setToAdd,
        setTeacher,
        setDate,
        setClassroom,
        setBlockHour,
        setTypeClassroom,
        setCampus,
        setWithStudents,
        level,
        setLevel
    } = UseTrailClassFilterModules()

    const [dateRange, setDateRange] = React.useState<DateRange>({
        after: after,
        before: before
    })
    let [page, setPage] = React.useState<PaginatorSetter>({
        currentPage: 1,
        items: 0,
        total: 0,
        totalPage: 0
    });
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const changeDate = (date: DateRange) => {
        setDate(date)
    };
    const changePage = (number: number) => {
        setCurrentPage(number)
    };
    const changeLimit = (number: number) => {
        setLimit(number)
    }

    const fetchData = async () => {
        setIsLoading(true)
        try {
            let response = await UseTrailClassAllPaginate();
            setDataResume(response)
        } catch (error) {
            console.error("Error invalidating queries:", error)
        } finally {
            setIsLoading(false)
        }
    }

 

    const handleChangeBlockHour = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setBlockHour(parseInt(e.target.value));
    };

    const handleChangeTeacher = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setTeacher(e.target.value);
    };

    const handleChangeActivity = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setActivity(e.target.value);
    };

    const handleChangeClassroom = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setClassroom(e.target.value);
    };

    const handleChangeTypeClassroom = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setTypeClassroom(e.target.value);
    };
    const handleChangeCampus = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCampus(e.target.value);
    };

    const handleChangeWithStudents = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setWithStudents(e.target.value as "YES" | "NO" | "");
    };

    const fetchBlocksHours = async () => {
        try {
            const response = await UseBlockHourAll();
            setDataBlockHour(response);
        } catch (e) {
            showSimpleAlert({ message: 'Error al encontrar bloques horarios!', icon: 'error' })
            throw 'Error'
        }
    };

    const fetchTeacher = async () => {
        try {
            let teacher = await UseUserTypePaginate('TEACHER');
            setTeacherData(teacher)
        } catch (e) {
            showSimpleAlert({ message: 'Error al encontrar profesores!', icon: 'error' })
            throw 'Error'
        }
    };

    const fetchClassroom = async () => {
        try {
            let classroomResponse = await UseClassroomAll();
            setClassroomData(classroomResponse)
        } catch (e) {
            showSimpleAlert({ message: 'Error al encontrar salones!', icon: 'error' })
            throw 'Error'
        }
    };

    const fetchAllActivity = async () => {
        try {
            let classroomResponse = await UseActivityAll();
            setActivityData(classroomResponse)
        } catch (e) {
            showSimpleAlert({ message: 'Error al encontrar actividades!', icon: 'error' })
            throw 'Error'
        }
    };

    const fetchAllCampus = async () => {
        try {
            let response = await UseCampusAll();
            setCampusData(response);
        } catch (e) {
            showSimpleAlert({ message: 'Error al encontrar actividades!', icon: 'error' })
            throw 'Error'
        }
    };


    const theadName = [
        'Dia',
        "6:00",
        "7:00",
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00"
    ];

    React.useEffect(() => {
        fetchBlocksHours();
        fetchTeacher();
        fetchClassroom();
        fetchAllActivity();
        fetchAllCampus();
    }, []);

    useEffect(() => {
        if (dataResume && dataResume.result.length > 0) {
            const stats = calculateStatistics(dataResume.result);
            const _summary = countAppointmentsByDay(dataResume.result);
            console.log(_summary)
            setStatistics(stats);
            setSummary(_summary)

        }
    }, [dataResume]);

    return (<>




        <div className={`card `}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Clases</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>Gestión de Clases</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >
                </div>



                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >



                    <button
                        onClick={() => setIsActive(!isActive)}
                        className='btn btn-sm btn-light-secondary  mr-8 p-2'
                    // data-bs-toggle='modal'
                    // data-bs-target='#kt_modal_invite_friends'
                    >
                        <BooleanCell value={isActive} />
                    </button>
                </div>
            </div>
            {/* end::Header */}

            <div className='py-3 row'>
                {/* begin::Filters */}
                <div className='mb-5 d-flex align-items-center overflow-x-auto px-4'>
                    <div className='col-auto d-flex align-items-center mx-3'>
                        <label className='form-label mb-0 me-2'>Actividades</label>
                        <div style={{ display: 'inline-block', width: 'auto', minWidth: '100px', maxWidth: '200px' }}>
                            <select
                                name='activity'
                                value={activity}
                                onChange={handleChangeActivity}
                                className='form-select form-select-sm'
                                aria-label='Default select example'
                                style={{ width: '100%' }}
                            >
                                <option value={undefined}>
                                    {' '}
                                </option>
                                {activityData?.map((x) => (
                                    <option key={x.id} value={x.id}>
                                        {`${x.name}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="col-auto d-flex align-items-center mx-3">
                        <label className='form-label mb-0 me-2'>Salón</label>
                        <div style={{ display: 'inline-block', width: 'auto', minWidth: '100px', maxWidth: '200px' }}>
                            <select
                                name='classroom'
                                value={classroom}
                                onChange={handleChangeClassroom}
                                className='form-select form-select-sm'
                                aria-label='Default select example'
                            >
                                <option value={undefined}>
                                    {' '}
                                </option>
                                {classroomData?.map((x) => (
                                    <option key={x.id} value={x.id}>
                                        {`${x.name}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className='mb-10 col-1'>
                        <label className='form-label mb-3'>Level</label>
                        <select
                            name='level'
                            value={level}
                            onChange={(e)=>setLevel(e.target.value)}
                            className='form-select'
                            aria-label='Default select example'
                        >
                            {Object.keys(TraditionalLevelEnum).map((x) => (
                                <option key={x} value={x}>
                                    {t(x)}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-auto d-flex align-items-center mx-3">
                        <label className='form-label mb-0 me-2'>Tipo salon</label>
                        <div style={{ display: 'inline-block', width: 'auto', minWidth: '100px', maxWidth: '200px' }}>
                            <select
                                name={'typeClassroom'}
                                value={typeClassroom}
                                onChange={handleChangeTypeClassroom}
                                className="form-select form-select-sm"
                                aria-label="Default select example"
                            >
                                <option value={undefined}></option>
                                <option value={'VIRTUAL'}>Virtual</option>
                                <option value={'FACE'}>Presencial</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-auto d-flex align-items-center mx-3">
                        <label className='form-label mb-0 me-2'>Bloque horario</label>
                        <div style={{ display: 'inline-block', width: 'auto', minWidth: '100px', maxWidth: '200px' }}>
                            <select
                                name='dataBlockHour'
                                value={blockHour}
                                onChange={handleChangeBlockHour}
                                className='form-select form-select-sm pe-0'
                                aria-label='Default select example'
                            >
                                <option value={undefined}>
                                    {' '}
                                </option>
                                {dataBlockHour?.map((x) => (
                                    <option key={x.id} value={x.order}>
                                        {`${x.start} ${x.end}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="col-auto d-flex align-items-center mx-3">
                        <label className='form-label mb-0 me-2'>Canceladas</label>
                        <select
                            name='withStudents'
                            value={withStudents}
                            onChange={handleChangeWithStudents}
                            className='form-select form-select-sm pe-0'
                            aria-label='Default select example'
                        >
                            <option value={undefined}>
                                {' '}
                            </option>
                            <option value={"YES"}>Si</option>
                            <option value={"NO"}>No</option>
                        </select>
                    </div>

                    <div className="col-auto d-flex align-items-center mx-3">
                        <label className='form-label mb-0 me-2'>Sede</label>
                        <select
                            name='campus'
                            value={campus}
                            onChange={handleChangeCampus}
                            className='form-select form-select-sm'
                            aria-label='Default select example'
                        >
                            <option value={undefined}>
                                {' '}
                            </option>
                            {campusData?.map((x) => (
                                <option key={x.id} value={x.id}>
                                    {x.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {/* end::Filters */}
            </div>
            {/* begin::Body */}
            <div className='py-3 row'>
                {/* begin::Table container */}
                <div className='mb-10 col-3'>
                    <UserSearchStudent
                        setStudent={(e) => setStudent(e.id)}
                    />
                    {summary && <AllExcel
                        dataExcel={summary}
                        filename={`classes` + moment().format('yyyymmddhhmmsss')}
                        sheetName='Estudiantes'
                        theadName={theadName}
                        type='all'
                        backgroundColor={'green'}
                        titleColor={'white'}
                    />}
                    <DayPickerRangeSelector
                        after={after}
                        before={before}
                        changeDate={(e) => setDate({
                            after: e.after,
                            before: e.before
                        })}
                    />

                </div>
                <div className='mb-10 col-9'>

                    <div className="btn-group" role="group" aria-label="Basic example">
                        <button onClick={() => setModeTable(false)} type="button" className="btn btn-primary">Resumen</button>
                        <button onClick={() => setModeTable(true)} type="button" className="btn btn-primary">Lista de reservas</button>
                        <button disabled={isLoading} onClick={() => fetchData()} type="button" className="btn btn-primary">
                            {isLoading?"Cargando..":"Buscar"}
                        </button>
                    </div>
                    {statistics && dataResume && dataResume.result.length > 0 && !modeTable && summary && <WidgetTrailClassResume
                        data={summary}
                        length={dataResume?.result.length}
                        statistics={statistics} />}
                    <div className='table-responsive' style={{ marginTop: 0 }}>
                        {isLoading ?
                            <span className='indicator-progress d-flex justify-content-center  align-items-center ms-5'>
                                <span className='spinner-border spinner-border-lg align-middle text-primary' />
                            </span>
                            : dataResume && modeTable &&
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className='fw-bold text-muted  text-start'>
                                        <th className='min-w-140px text-start'>Actividad</th>
                                        <th className='min-w-140px text-start'>Salon</th>
                                        <th className='min-w-120px text-start'>Fecha Hora</th>

                                        <th className='min-w-120px text-start'>Leads</th>
                                        <th className='min-w-120px  text-start'>Active</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {dataResume?.result.map((x: TrailClassReportResult) => (
                                        <tr key={x.id}
                                            className={`p-0 rounded`}
                                        >
                                            <td className='p-0'>
                                                <div className='d-flex align-items-center'>

                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className=' fw-bold text-hover-primary fs-6'>
                                                            {x?.student_setting.user.fullName}
                                                        </a>
                                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                            {x?.student_setting.user.email}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='p-0'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className='fw-bold text-hover-primary fs-6'>
                                                            {x?.student_setting.user.document_id}
                                                        </a>
                                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                            {x?.student_setting.level}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className='p-0'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='d-flex justify-content-start flex-column'>
                                                        <a href='#' className='fw-bold text-hover-primary fs-6'>
                                                            {x?.appointment.activity.name}
                                                        </a>
                                                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                            {x?.appointment.activity.limit}

                                                        </span>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className='text-start'>


                                            </td>
                                            <td className='text-start'>
                                                <BooleanCell value={x.assistantStudent} />
                                            </td>

                                        </tr>
                                    ))}
                                </tbody>

                            </table>
                        }
                    </div>

                </div>
            </div>
        </div>

    </>
    )
}

export { TrailClassReportTableList }
