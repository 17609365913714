import { TrailClassReportResult } from "../../interface";
import moment from 'moment';

export interface Statistics {
    totalStudents: number;
    blockHourCount: { [key: number]: number };
    isSuspenseCount: { true: number, false: number };
    assistantStudentCount: { true: number, false: number };
    campusCount: { [key: string]: number };
    statusAppointmentCount: { [key: string]: number };
    levelCount: { [key: string]: number };
    classroomTypeCount: { [key: string]: number };
    dayOfWeekCount: { [key: string]: number }; // Nueva propiedad para contar por día de la semana
    blockHourCountByDay: { [day: string]: { [key: number]: number } }; // Contar blockHour.order por día de la semana
}

export const calculateStatistics = (result: TrailClassReportResult[]): Statistics => {
    const studentIds = new Set<string>();
    const blockHourCount: { [key: number]: number } = {};
    const isSuspenseCount = { true: 0, false: 0 };
    const assistantStudentCount = { true: 0, false: 0 };
    const campusCount: { [key: string]: number } = {};
    const statusAppointmentCount: { [key: string]: number } = {};
    const levelCount: { [key: string]: number } = {};
    const classroomTypeCount: { [key: string]: number } = {};
    const dayOfWeekCount: { [key: string]: number } = {}; // Para contar por día de la semana
    const blockHourCountByDay: { [day: string]: { [key: number]: number } } = {}; // Para contar blockHour.order por día de la semana

    result.forEach(r => {
        // Estudiantes únicos
        studentIds.add(r.student_setting.user.id);

        // Horarios (blockHour.order)
        r.appointment.blockHour.forEach(bh => {
            blockHourCount[bh.order] = (blockHourCount[bh.order] || 0) + 1;
        });

        // Cancelaciones (isSuspense true/false)
        if (r.isSuspense) {
            isSuspenseCount.true++;
        } else {
            isSuspenseCount.false++;
        }

        // Asistencia del profesor (assistantStudent true/false)
        if (r.assistantStudent) {
            assistantStudentCount.true++;
        } else {
            assistantStudentCount.false++;
        }

        // Citas por campus
        const campusName = r.appointment.campus.name;
        campusCount[campusName] = (campusCount[campusName] || 0) + 1;

        // Contar citas por status_appointment
        const status = r.appointment.status_appointment;
        statusAppointmentCount[status] = (statusAppointmentCount[status] || 0) + 1;

        // Contar citas por level de los estudiantes
        const level = r.student_setting.level;
        levelCount[level] = (levelCount[level] || 0) + 1;

        // Contar tipos de classroom
        const classroomType = r.appointment.classroom.type;
        classroomTypeCount[classroomType] = (classroomTypeCount[classroomType] || 0) + 1;

        // Contar por día de la semana usando moment
        // Verificar si la fecha de la cita existe y es válida
        const appointmentDate = r.appointment.start;
        if (appointmentDate) {
            const dayOfWeek = moment(appointmentDate).format('dddd'); // Obtener el día de la semana

            // Incrementar conteo de día de la semana
            dayOfWeekCount[dayOfWeek] = (dayOfWeekCount[dayOfWeek] || 0) + 1;

            // Inicializar objeto para el día si no existe
            if (!blockHourCountByDay[dayOfWeek]) {
                blockHourCountByDay[dayOfWeek] = {};
            }

            // Contar blockHour.order por cada día de la semana
            r.appointment.blockHour.forEach(bh => {
                blockHourCountByDay[dayOfWeek][bh.order] = (blockHourCountByDay[dayOfWeek][bh.order] || 0) + 1;
            });
        } else {
            // Contar las citas que no tienen fecha o tienen una fecha inválida
            dayOfWeekCount['Unknown'] = (dayOfWeekCount['Unknown'] || 0) + 1;
        }
    });

    return {
        totalStudents: studentIds.size,
        blockHourCount,
        isSuspenseCount,
        assistantStudentCount,
        campusCount,
        statusAppointmentCount,
        levelCount,
        classroomTypeCount,
        dayOfWeekCount, // Incluir conteo por días de la semana en la salida
        blockHourCountByDay, // Incluir blockHour por día de la semana
    };
};

// Calcular porcentajes
export const calculatePercentages = (total: number, count: number): number => {
    return (count / total) * 100;
};

export interface DaySummary {
    day: string;
    totalByDay: number;
    sixHour: number;
    sevenHour: number;
    eightHour: number;
    nineHour: number;
    tenHour: number;
    elevenHour: number;
    twelveHour: number;
    thirteenHour: number;
    fourteenHour: number;
    fifteenHour: number;
    sixteenHour: number;
    seventeenHour: number;
    eighteenHour: number;
    nineteenHour: number;
    twentyHour: number;
    twentyOneHour: number;
}

export const countAppointmentsByDay = (results: TrailClassReportResult[]) => {
    const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    // Inicializa los días con horas de 6:00 a 22:00
    const dayHourCounts = daysOfWeek.map(day => ({
      day,
      "6:00": 0,
      "7:00": 0,
      "8:00": 0,
      "9:00": 0,
      "10:00": 0,
      "11:00": 0,
      "12:00": 0,
      "13:00": 0,
      "14:00": 0,
      "15:00": 0,
      "16:00": 0,
      "17:00": 0,
      "18:00": 0,
      "19:00": 0,
      "20:00": 0,
      "21:00": 0,
      "22:00": 0
    }));
  
    // Itera sobre los resultados y cuenta las citas por hora y día
    results.forEach(result => {
      const appointment = result.appointment;
      const dayOfWeek = moment(appointment.start).format('dddd'); // Obtiene el día de la semana
  
      // Excluye los domingos
      if (dayOfWeek !== 'Sunday') {
        appointment.blockHour.forEach(block => {
          const blockStartHour = moment(block.start, 'HH:mm').hour(); // Hora de inicio
          const blockEndHour = moment(block.end, 'HH:mm').hour(); // Hora de fin
  
          // Contar horas dentro del rango de 6 AM a 10 PM
          for (let hour = blockStartHour; hour <= blockEndHour; hour++) {
            if (hour >= 6 && hour <= 22) {
              const hourFormatted = `${hour}:00`;
  
              // Encuentra el objeto correspondiente al día actual y aumenta el contador en la hora correspondiente
              const dayObj = dayHourCounts.find(dayObj => dayObj.day === dayOfWeek);
              if (dayObj) {
                dayObj[hourFormatted]++;
              }
            }
          }
        });
      }
    });
  
    return dayHourCounts;
};