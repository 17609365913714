import {Route, Routes} from 'react-router-dom'
import { ReportDashboardList } from '../components/dashboard-selector'
import { StudentFilterReportTableList } from '../components/list-students'
import { AppointmentReportTableList } from '../components/list-appoitment'
import { TrailClassReportTableList } from '../components/list-trail-class'
import { SurveySectionResponseReportList } from '../components/list-survey-section'
import { TrailClassReportByWorkshopTableList } from '../components/list-by-workshop'
 
// import { AppointmentDetails } from '../components/details'
// import { AppointmentTableList } from '../components/list'


const ReportPage = () => (
  <Routes>
      {/* <Route path='/details' element={<ResumeDetails />} /> */}
      <Route path='/trail-class-filter' element={<TrailClassReportTableList />} />
      <Route path='/appointment-filter' element={<AppointmentReportTableList />} />
      <Route path='/student-filter' element={<StudentFilterReportTableList />} />
      <Route path='/resume-by-workshop' element={<TrailClassReportByWorkshopTableList />} />
      <Route path='/survey-section-filter' element={<SurveySectionResponseReportList />} />
      <Route path='/' element={<ReportDashboardList />} />
  </Routes>
)

export {ReportPage}
