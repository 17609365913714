import { TraditionalLevelEnum } from "../../../../abroad/road-map-step/interfaces";

export interface DataPaginatePromotionHistoryResponse {
    status:  string;
    message: string;
    data:    DataPromotionHistory;
}

export interface DataAllPromotionHistoryResponse {
    status:  string;
    message: string;
    data:    PromotionHistory[];
}

export interface DataPromotionHistory {
    id?: string;
    description:string;
    name:string;
    code:string;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface PromotionHistory  {
    id?: string;
    description?:string;
    name:string;
    code:string;
    isActive: boolean;
    createdAt?:string;
    updatedAt?:string;
}

export interface Pagination {
    result: PromotionHistory[],
    total: number,
    limit: number,
    current_page: number,
    total_page: number
}

export enum PromotionHistoryType {
    UPGRADE = 'UPGRADE',
    DOWNGRADE = 'DOWNGRADE'
}

export enum IsStartStep {
    YES= 'YES',
    NO = 'NO'
}

export interface PromotionHistoryTableItem {
    id?: string
    date: string
    promotionType: PromotionHistoryType | 'empty'
    previous_level: TraditionalLevelEnum
    new_level: TraditionalLevelEnum
    target_lesson: string
    changeBy: string
    description: string
}