// FillTheGaps.tsx
import React, {useEffect, useState} from 'react'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {FillTheGapsProps, WordItem} from './types'
import Gap from './Gap'
import './index.css'
import {RightCardContent} from '../../../../components/common/right-card-content/indext'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {UseParagraphDragDropModule} from './store'
import {showSimpleAlert} from '../../../../commonHooks/alert'
import WordWordBox from './word-word-box'
import {UseEvaluationCourseModule} from '../../screen/store/main'
import {UseModeCourseModule} from '../../components/front-page/store/main'
import {AddDragDropResponse} from './services'
import useAuth from '../../../../api/main'
import {UseExerciseTimerModule} from '../common/store/main'
import {validationPercentageObtained} from '../../utils/validatePercentage'
import useIsImportantEvaluation from '../../hooks/useIsImportantEvaluation'
import {ButtonNextFullScreen} from '../../utils/buttonNextFullScreen'

const WordBoxDragAndDrop: React.FC<FillTheGapsProps> = ({
  renderType,
  titleContent,
  descriptionContent,
  colorSecondary,
}) => {
  const {typeUser} = useAuth()
  let {
    setSentencesConstructed,
    allWords,
    sentences: sentence,
    setWords,
    percentage,
    sentenceNumber,
    setSentenceNumber,
    filledGaps,
    setFilledGaps,
    usedWords,
    setUsedWords,
    hasChecked,
    setHasChecked,
    handleRemoveWord,
    words,
    clickedWord,
    setClickedWord
  } = UseParagraphDragDropModule()
  const {questionsEvaluation, currentTaskId, isLoading, setEvaluationResponses} = UseEvaluationCourseModule()
  const {isPercentageMin, setIsPercentageMin} = UseModeCourseModule()
  const {exerciseBeforeTime} = UseExerciseTimerModule()
  const [resetFlag, setResetFlag] = useState(0)
  const [gapsCorrectness, setGapsCorrectness] = useState<{[key: string]: boolean}>({})

  const isImportantQuiz = useIsImportantEvaluation()
  const handleFillGap = (gapId: string, wordId: string) => {
    setFilledGaps({[gapId]: wordId}, false)
    setUsedWords({[wordId]: true}, false)
    setSentencesConstructed(getUserConstructedSentence())
  }

  function calculateAccuracyPercentage(totalQuestions: number, correctAnswers: number): number {
    if (totalQuestions > 0) {
      const percentage: number = (correctAnswers / totalQuestions) * 100
      return percentage
    } else {
      return 0
    }
  }

  const checkSentence = () => {
    const totalGaps = sentence.reduce((acc, sent) => acc + (sent.match(/\{.*?\}/g) || []).length, 0)

    // if (totalGaps > Object.keys(filledGaps).length) {
    //   showSimpleAlert({ message: 'Error! Please fill all the gaps before checking.', icon: 'error' });
    //   return;
    // }
    setHasChecked(true)
    let _trueWords: string[] = []
    let _falseWords: string[] = []
    let _allWords: string[] = []
    const newGapsCorrectness = Object.keys(filledGaps).reduce((acc, gapId) => {
      _allWords.push(gapId)
      // alert(gapId)
      const wordId = filledGaps[gapId]
      const wordItem = words.find((word) => word.id === wordId)
      const isCorrect = wordItem ? wordItem.text === gapId : false
      if (isCorrect) {
        _trueWords.push(gapId)
      }
      if (isCorrect === false) {
        _falseWords.push(gapId)
      }
      acc[gapId] = isCorrect
      return acc
    }, {} as {[key: string]: boolean})

    setGapsCorrectness(newGapsCorrectness)
    const isOverallCorrect = Object.values(newGapsCorrectness).every((value) => value)
    // alert(isOverallCorrect ? 'Correct!' : 'Incorrect, try again.');
    let totalPercentage = calculateAccuracyPercentage(totalGaps, _trueWords.length)
    if (!isImportantQuiz) {
      validationPercentageObtained(questionsEvaluation?.minPercentage ?? 50, totalPercentage)
    }
    setWords({
      percentage: totalPercentage,
      allWords: {
        filledGaps: filledGaps,
        usedWords: usedWords,
      },
      hasChecked: true,
    })
  }

  const resetExercise = () => {
    setFilledGaps({}, true)
    setUsedWords({}, true)
    setHasChecked(false)
    setSentenceNumber(0)
    setIsPercentageMin(false)
    setResetFlag((flag) => flag + 1)
  }
  useEffect(()=>{
    console.log(usedWords);
    
  },[usedWords])
  // Función para incrementar el número
  const increment = () => {
    setSentenceNumber(sentenceNumber + 1)
  }
  
  // Función para disminuir el número
  const decrement = () => {
    setSentenceNumber(sentenceNumber - 1)
  }
  const getUserConstructedSentence = () => {
    if (!sentence) {
      console.error('sentence is undefined')
      return ''
    }
    return sentence
      .map((sent) => {
        const parts = sent.split(/(\{.*?\})/).filter((part) => part)
        const completedParts = parts.map((part) => {
          if (part.startsWith('{') && part.endsWith('}')) {
            const gapId = part.replace(/[{}]/g, '')
            const wordId = filledGaps[gapId]
            const wordItem = words.find((word) => word.id === wordId)
            return wordItem ? wordItem.text : part
          }
          return part
        })
        return completedParts.join('')
      })
      .join(' ')
  }

  const handleSubmit = async () => {
    // ResetStepByExercises();
    setEvaluationResponses({
      percentage: percentage,
      exercise_title: questionsEvaluation?.exercise.name ?? 'Titulo ejercicio',
      responses: [],
      isDragDropExercise: {
        sentences: sentence,
        responses: [''],
        percentage: percentage,
        isPercentageMin: isPercentageMin,
      },
    })
    if (typeUser === 'STUDENT' || typeUser === 'COURTESY' || typeUser === 'PLACEMENT_TEST') {
      try {
        await AddDragDropResponse({
          percentage: percentage,
          description: '',
          isActive: true,
          true_answer: allWords,
          exercises: questionsEvaluation?.exercise?.id ?? 'xx',
          task: currentTaskId ?? 'xx',
        })
      } catch (e) {
        showSimpleAlert({message: 'Error al cargar respuesta.', icon: 'error'})
        console.error(e)
      }
    }
    setResetFlag((flag) => flag + 1)
  }

  const handleWordDrop = () => {
    setClickedWord(null); // Resetea la palabra después de soltarla
  };
  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {renderType && renderType === 'right' && (
          <>
            <RightCardContent>
              <h4 className='champ-light word-box-title mb-0 pb-0' style={{color: '#3e94ff'}}>
                {titleContent}
              </h4>
              <p className='instruction-word-box' style={{color: '#3e94ff'}}>
                <strong>Instruction:</strong>
                {descriptionContent}
              </p>

              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  gap: '10px', // Espaciado entre elementos
                  maxHeight: '70px',
                  overflowY: 'auto',
                }}
              >
                {words.map((word) => (
                  <WordWordBox key={word.id} word={word} isUsed={!!usedWords[word.id]} exerciseId={questionsEvaluation?.exercise?.id} selectedWord={clickedWord} setSelectedWord={setClickedWord}/>
                ))}
              </div>

              <hr style={{color: '#3e94ff', height: 0, marginBottom: 4}} />
              <p
                className='champ-light text-end my-0'
                style={{
                  color: '#3e94ff',
                  fontSize: 14,
                }}
              >
                {`${sentenceNumber + 1}${'/'}${sentence.length}`}
              </p>
              {sentence.map((sentence, sIndex) => (
                <div
                  className='d-flex poppins-light'
                  key={sIndex}
                  style={{
                    color: '#3e94ff',
                    fontSize: 16,
                    display: 'flex',
                    flexWrap: 'wrap',
                    maxHeight: '180px',
                    overflowY: 'auto',
                  }}
                >
                  {sentence
                    .split(/(\{.*?\})/)
                    .filter((part) => part)
                    .map((part, pIndex) => {
                      // Comprobación para partes que deben ir en negrita
                      const boldPattern = /.+:\s+/ // Busca cualquier texto seguido de dos puntos y un espacio
                      const matchResult = part.match(boldPattern) // Almacena el resultado de match
                      if (matchResult) {
                        // Verifica si matchResult no es null
                        const boldPart = matchResult[0] // Asume que hay al menos una coincidencia
                        const restOfPart = part.substring(boldPart.length)
                        return (
                          <span
                            key={`${sIndex}-${pIndex}`}
                            style={{display: sIndex === sentenceNumber ? '' : 'none'}}
                          >
                            <strong>{boldPart}</strong>
                            {restOfPart}
                          </span>
                        )
                      } else if (part.startsWith('{') && part.endsWith('}')) {
                        // Manejo de los huecos
                        const gapId = part.replace(/[{}]/g, '')
                        return (
                          <Gap
                            key={`${sIndex}-${pIndex}`}
                            size={15}
                            gap={{id: gapId, correctWordId: gapId}}
                            resetFlag={resetFlag}
                            onFill={handleFillGap}
                            reviewMode={false}
                            colorString='#3e94ff'
                            onRemove={handleRemoveWord}
                            isCorrect={gapsCorrectness[gapId]}
                            hasChecked={hasChecked}
                            display={sIndex === sentenceNumber ? 'inline-block' : 'none'}
                            words={words}
                            exerciseId={questionsEvaluation?.exercise?.id}
                            onWordDrop={handleWordDrop}
                            selectedWord={clickedWord}
                          />
                        )
                      } else {
                        // Partes de la oración que no requieren tratamiento especial
                        return (
                          <span
                            key={`${sIndex}-${pIndex}`}
                            style={{display: sIndex === sentenceNumber ? '' : 'none'}}
                          >
                            {part}
                          </span>
                        )
                      }
                    })}
                </div>
              ))}
              <div className='row mt-auto'>
                <div className='col-6 text-start'>
                  {hasChecked && !isImportantQuiz ? (
                    <button
                      type='button'
                      disabled={isLoading}
                      className='cursor-pointer  poppins-light border border-0 bg-transparent'
                      onClick={() => resetExercise()}
                    >
                      <img
                        className=''
                        height={30}
                        src={toAbsoluteUrl(`/media/svg/michigan-icons/elements/blue/try_again.svg`)}
                        alt=''
                      />
                    </button>
                  ) : isImportantQuiz ? (
                    <>
                      {sentenceNumber > 0 && !hasChecked && (
                        <button
                          type='button'
                          className='cursor-pointer bg-transparent border border-0'
                          onClick={() => {
                            if (isImportantQuiz && !hasChecked) {
                              decrement()
                            } else if (!isImportantQuiz) {
                              decrement()
                            }
                          }}
                        >
                          <img
                            className=''
                            height={30}
                            src={toAbsoluteUrl(
                              `/media/svg/michigan-icons/elements/blue/back_active.svg`
                            )}
                            alt=''
                          />
                        </button>
                      )}
                    </>
                  ) : (
                    <div>
                      {sentenceNumber > 0 && (
                        <button
                          type='button'
                          className='cursor-pointer border border-0 bg-transparent'
                          onClick={() => {
                            if (isImportantQuiz && !hasChecked) {
                              decrement()
                            } else if (!isImportantQuiz) {
                              decrement()
                            }
                          }}
                        >
                          <img
                            className=''
                            height={28}
                            src={toAbsoluteUrl(
                              `/media/svg/michigan-icons/elements/blue/back_active.svg`
                            )}
                            alt=''
                          />
                        </button>
                      )}
                    </div>
                  )}
                </div>
                <div className='col-6 text-end'>
                  {hasChecked && (isPercentageMin || isImportantQuiz) ? (
                    <ButtonNextFullScreen onAction={handleSubmit} />
                  ) : sentenceNumber + 1 === sentence.length ? (
                    //ultimo next del ejercicio
                    <button
                      type='button'
                      className='cursor-pointer border border-0 bg-transparent'
                      onClick={() => checkSentence()}
                    >
                      <img
                        height={30}
                        src={toAbsoluteUrl(
                          `/media/svg/michigan-icons/elements/blue/next_active.svg`
                        )}
                        alt=''
                      />
                    </button>
                  ) : (
                    <div className='d-flex justify-content-end'>
                      <button
                        type='button'
                        title={exerciseBeforeTime ? 'Vea todo el contenido' : 'Next'}
                        style={{
                          backgroundColor: 'transparent',
                          border: 'none',
                          cursor: `${exerciseBeforeTime ? 'not-allowed' : 'pointer'}`,
                        }}
                        onClick={() => increment()}
                        disabled={exerciseBeforeTime}
                      >
                        <img
                          height={30}
                          src={toAbsoluteUrl(
                            `/media/svg/michigan-icons/elements/blue/next_active.svg`
                          )}
                          alt=''
                        />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </RightCardContent>
          </>
        )}
        <style>
          {`.card-with-extended-background::before {
                background-color: ${colorSecondary};
                }`}
        </style>
      </DndProvider>
    </>
  )
}

export default React.memo(WordBoxDragAndDrop)
