import {create} from 'zustand';
import { persist } from 'zustand/middleware';
import { Evaluation, EvaluationItem } from '../../../../admin/evaluation/interfaces';
import { Lessons } from '../../../../abroad/learning-path/modules/lessons/interfaces';
import { setQuestionsEbook } from '../../../utils/setQuestionsEBook';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { UseModeCourseModule } from '../../../../courses/components/front-page/store/main';
import { CurrentPage } from '../../test/store/main';

export interface StudentEbook {
    trail_class_id: string
    studentName: string
    studentEmail: string
    sessionType: string
    observations: string
    assistantStudent: boolean
    assistantTeacher: boolean
    isSuspense: boolean
    lesson: string;
    lateArrival: boolean
    studentSetting: string;
    avatar?:any
}
interface BookTeacherModules {
    currentsItemsTest: EvaluationItem[];
    evaluationBookTest?: Evaluation;
    lessonData?: Lessons;
    statePage: 1 | 2;
    currentPage: 1 | 2;
    studentsList?: StudentEbook[]; // tipar
    setStatePage:(value: 1 | 2)=>void;
    setCurrentsItemsTest:(value: EvaluationItem)=>void;
    setEvaluationBookTest:(value: Evaluation)=>void;
    setLessonData:(values: Lessons)=>void;
    setCurrentPage:(value: CurrentPage, appointment: string)=>void;
    setDeleteCurrentItem:(value: string)=>void;
    setStudentList:(value: StudentEbook[])=>void;
    resetCurrentItems:()=>void;
}

export const UseBookTeacherModule = create(persist<BookTeacherModules>( (set, get) => ({
    currentsItemsTest: [],
    currentPage: 1,
    statePage: 2,
    resetCurrentItems:()=>{
        set({currentsItemsTest: []});
    },
    setCurrentsItemsTest:(value)=>{
        const findItem = get().currentsItemsTest.find(x => x.id === value.id);
        const findTypeExercise = get().currentsItemsTest.find(x => x.exercise?.type === value.exercise?.type);
        let currentItems: EvaluationItem[]; 

        if (findItem) {
            currentItems = [...get().currentsItemsTest];
        } else if (findTypeExercise) {
            currentItems =[...get().currentsItemsTest.filter(x => x.id !== findTypeExercise.id), value]
        } else {
            currentItems = [...get().currentsItemsTest, value];
        }

        currentItems?.forEach((x) => {
            setQuestionsEbook(x);
        });
        set({currentsItemsTest: currentItems});
    },
    setEvaluationBookTest:(value)=>{
        set({evaluationBookTest: value});
    },
    setLessonData:(value)=>{
        set({lessonData: value});
    },
    setStatePage:(value)=>{
        set({statePage: value});
    },
    setCurrentPage:(value, appointment)=> {
        /* 
            @param Value 0 es volver a la leccion anterior
            Value 1 es poner la primera pagina
            Value 2 es poner la segunda pagina
            Value 3 es pasar a la siguiente leccion
        */  
        const lessonId = get().lessonData
        if (lessonId && lessonId.book_pages && lessonId.book_pages.length > 0) {
            const lessons = UseModeCourseModule.getState().lessonsId;
            let currentChapter: undefined | any = undefined;
            if (lessons && lessonId) {
                currentChapter = lessons.find(x => x.id === lessonId.id)
            }
            if (value === 3) {
                if (currentChapter && lessons) {
                    const chapterLessons = lessons.filter(x => x.chapter_order === currentChapter.chapter_order).sort((a, b) => a.order - b.order);
                    let nextLesson = chapterLessons.find(x => x.order > currentChapter.order);
                    if (nextLesson) {
                        if(nextLesson.typeScreen === 'BOOK'){
                            window.location.replace(`class?lesson=${nextLesson.id}&appointment=${appointment}&mode=TEACHER`)
                        } else {
                            window.location.replace(`/evaluation/screen/course/${nextLesson.evaluationId}`);
                        }
                    } else {
                        const nextChapter = lessons.filter(x => x.chapter_order > currentChapter.order).sort((a, b) => a.chapter_order - b.chapter_order)[0];
                        if(nextChapter){
                            const nextChapterLessons = lessons.filter(x => x.chapter_order === nextChapter.chapter_order).sort((a, b) => a.order - b.order);
                            nextLesson = nextChapterLessons.find(x => x.order >= 0);
                            if (nextLesson) {
                                if(nextLesson.typeScreen === 'BOOK'){
                                    window.location.replace(`class?lesson=${nextLesson.id}&appointment=${appointment}&mode=TEACHER`)
                                } else {
                                    window.location.replace(`/evaluation/screen/course/${nextLesson.evaluationId}`);
                                }
                            } else {
                                showSimpleAlert({message:'No hay lecciones posteriores', icon:'info'})
                            }
                        } else {
                            showSimpleAlert({message:'No hay lecciones posteriores', icon:'info'})
                        }
                    }
                }
            } 
            else if ( value === 0){
                if( currentChapter && lessons){
                    const chapterLessons = lessons.filter(x => x.chapter_order === currentChapter.chapter_order).sort((a, b) => b.order - a.order);
                    let prevLesson = chapterLessons.find(x=> x.order < currentChapter.order)
                    if (prevLesson){
                        if(prevLesson.typeScreen === 'BOOK'){
                            window.location.replace(`class?lesson=${prevLesson.id}&appointment=${appointment}&mode=TEACHER`)
                        } else {
                            window.location.replace(`/evaluation/screen/course/${prevLesson.evaluationId}`);
                        }
                    } else {
                        const prevChapter = lessons.filter(x => x.chapter_order < currentChapter.order).sort((a, b) => a.chapter_order - b.chapter_order)[0];
                        if (prevChapter) {
                            const prevChapterLessons = lessons.filter(x => x.chapter_order === prevChapter.chapter_order).sort((a, b) => b.order - a.order);
                            prevLesson = prevChapterLessons[0];  // Tomar la última lección del capítulo anterior
                            if (prevLesson) {                                
                                if(prevLesson.typeScreen === 'BOOK'){
                                    window.location.replace(`class?lesson=${prevLesson.id}&appointment=${appointment}&mode=TEACHER`);
                                } else {
                                    window.location.replace(`/evaluation/screen/course/${prevLesson.evaluationId}`);
                                }
                            } else {
                                showSimpleAlert({ message: 'No hay lecciones anteriores', icon: 'info' });
                            }
                        } else {
                            showSimpleAlert({message: 'No hay lecciones anteriores', icon: 'info'})	
                        }
                    }
                } 
            } else {
                set({currentPage: value})
                return
            }
        }
        set({currentPage: 1});
    },
    setDeleteCurrentItem:(value)=> {
        const filteredItems = get().currentsItemsTest.filter(x=>x.id !== value)
        set({currentsItemsTest: filteredItems})
    },
    setStudentList:(value)=>{
        set({studentsList: value});
    }
    }),

    {
        name: 'content-book-class'
    }
));