import {create} from 'zustand';
import { persist } from 'zustand/middleware';
import { UseEvaluationTestModule } from '../../../../admin/evaluation/modules/test/store/main';
import { TopicsDrawerTrail } from '../../../../../../_metronic/partials';
import { EvaluationType } from '../../../../admin/evaluation/interfaces';


interface LessonItem {
    name: string;
    order: number;
}

export interface GroupedLesson {
    id: string;
    name: string;
    order: number;
    typeScreen: string | null;
    evaluation_id: string | null;
    withTeacher: boolean;
    taskId?: string;
    statusTask?: string;
    items?: LessonItem[];
    isDisabled?: boolean;
}

interface LessonsEbookTopicsDrawerType {
    name: string;
    lessons: GroupedLesson[]
}


export interface TopicsEbookDrawerType {
    chapter_order: number,
    chapter_id: string;
    chapter_name: string;
    lessons: LessonsEbookTopicsDrawerType[];
}

export interface TopicsDrawerType {
    chapter_order: number,
    chapter_id: string;
    chapter_name: string;
    lessons: GroupedLesson[];
}

export interface LessonsIdType {
    id: string;
    order: number;
    chapter_order: number;
    with_teacher: boolean;
    task: string;
    typeScreen: EvaluationType;
    evaluationId: string;
    statusTask: string;
    isDisabled: boolean;
}

interface ModeCourseModule {
    modeCourse: boolean;
    modeViewAnswer: boolean;
    modeFrontPage: boolean;
    modeTest: boolean;
    currentCourseId:string;
    modePublic: boolean;
    lessonsId?: LessonsIdType[];
    currentLesson?:{name: string, order: number}
    currentFile?:any;
    lessonsMenu?: TopicsEbookDrawerType[];
    lessonsMenuTopics?: TopicsDrawerType[];
    isPercentageMin: boolean;
    color: string;
    setIsPercentageMin:(value: boolean)=>void;
    setModeTest:(value:boolean)=>void;
    setCurrentCourse:(value: string)=>void;
    setModeCourse:(value:boolean)=>void,
    setLessonsMenu:(value: TopicsEbookDrawerType[] | TopicsDrawerType[], type: 'EBOOK' | 'NORMAL')=>void;
    modeCourseChecked:()=>void,
    resetModeCourse:()=>void;
    setCurrentFile:(value: any)=>void;
    setLessonsId:(value: LessonsIdType[])=>void;
    setCurrentLesson:(value: {name: string, order: number})=>void;
    setModeViewAnswer:(value: boolean) => void;
    setModePublic:(value: boolean) => void;
    setColor:(Value:string) => void;
}

export const UseModeCourseModule = create(persist<ModeCourseModule>( 
    (set) => ({
        modeViewAnswer: false,
        modePublic: false,
        currentCourseId: '',
        modeCourse: false,
        modeFrontPage: false,
        modeTest: false,
        isPercentageMin: false,
        color: '',
        setModeViewAnswer:(value) => {
            set({modeViewAnswer: value});
        },
        setIsPercentageMin:(value)=>{
            set({isPercentageMin: value});
        },
        setModeCourse:(value)=>{
            set({modeCourse: value});
        },
        setModeTest:(value)=>{
            set({modeTest: value});
        },
        setLessonsMenu:(value, type)=>{
            if (type === 'EBOOK' && isTopicsEbookDrawerArrayType(value)) {
                set({lessonsMenu: value});
            } else if (isTopicsDrawerArrayType(value)) {
                set({lessonsMenuTopics: value})
            }
        },
        setCurrentCourse:(value)=> {
            set({currentCourseId: value});
        },
        modeCourseChecked:()=>{
            const {resetEvaluationTest} = UseEvaluationTestModule.getState();
            const location = window.location.href;;
            if (location.toLocaleLowerCase().includes('/courses/container')) {
                set({ modeCourse: true, modeFrontPage: false, modeTest: false })
            } else if (location.toLocaleLowerCase().includes('/evaluation/test')) {
                set({modeTest: true, modeCourse: false})
            } else if (location.toLowerCase().includes('/evaluation/screen')) {
                set({modeCourse: true, modeTest: false});
            } else if (location.toLowerCase().includes('/courses')) {
                set({modeCourse: false, modeTest: false, modeFrontPage: true});
            } else {
                TemplateModifiedCourse('');
                set({modeCourse: false, modeTest: false, modeFrontPage: false });
                resetEvaluationTest();
            }
        },
        resetModeCourse:()=>{
            set({
                modeCourse:false,
            })
        },
        setCurrentFile:(value)=>{
            set({currentFile: value});
        },
        setLessonsId:(value)=>{
            set({lessonsId: value});
        },
        setCurrentLesson:(value)=>{
            set({currentLesson: value});
        },
        setModePublic:(value) => {
            set({modePublic: value});
        },
        setColor:(value) => {
            set({color: value})
        }
    }),
    {
        name: 'mode-course'
    }
));

export const TemplateModifiedCourse = (colorCourse:string) => {
    const contentElement = document.querySelector('.toolbar-enabled.toolbar-fixed') as HTMLElement | null;
    const headerElement = document.querySelector('.container-fluid') as HTMLElement | null;
    if (contentElement) {
        contentElement.style.backgroundColor = colorCourse;
        contentElement.style.width = '100%';
        contentElement.style.height = '100vh';
    }
    if (headerElement) {
        headerElement.style.backgroundColor = colorCourse;
    }
}

function isTopicsEbookDrawerArrayType(arr: any[]): arr is TopicsEbookDrawerType[] {
    return Array.isArray(arr) && arr.every(item => isTopicsEbookDrawerType(item));
}

function isTopicsEbookDrawerType(obj: any): obj is TopicsEbookDrawerType {
    return typeof obj === 'object' && obj !== null
}


function isTopicsDrawerArrayType(arr: any[]): arr is TopicsDrawerType[] {
    return Array.isArray(arr) && arr.every(item => isTopicsDrawerType(item));
}

function isTopicsDrawerType(obj: any): obj is TopicsDrawerType {
    return typeof obj === 'object' && obj !== null
}