import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik, } from 'formik'
import { KTIcon } from '../../../../../../../_metronic/helpers'
import { SettingModalityType, SettingStudentStatus } from '../../../interfaces'
import { UserAdd, UserUpdate } from '../../../services'
import { UseUserModule } from '../../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import { showSimpleAlert } from '../../../../../../commonHooks/alert'
import { RoadMapStep, SettingPlacementTestStatus, SettingVerificationStatus, SettingWelcomeDayStatus, TraditionalLevelEnum } from '../../../../../abroad/road-map-step/interfaces'
import { StudentSettingsAdd, StudentSettingsUpdate } from '../../../services/student/StudentSettingsService'
import { RoadMap } from '../../../../../abroad/road-map/interfaces'
import { UseRoadMapAll } from '../../../../../abroad/road-map/services'
import { RoadMapStepByRoadMap } from '../../../../../abroad/road-map-step/services'
import { setTypeToSpanish } from '../../../utils/setTypeToSpanish'
import { UseCampusAll } from '../../../../../abroad/campus/services'
import { Campus } from '../../../../../abroad/campus/interfaces'
import { useTranslation } from 'react-i18next'
import { PlacementType } from '../../../../placement-test/interfaces'
import { UsePlacementTestModule } from '../../../../placement-test/store/main'
import { usePlacementTestState } from '../../../../placement-test/hooks/usePlacementTestRepo'
import { UserForm } from '..'
import { FormFields as FormUser } from '../index';
import { ReContractAdd } from '../../../../re-contract/services'

export type FormFields = {
  id?: string
  email: string
  phoneNumber: string
  fullName: string
  isActive: boolean
  type?: string
  avatar?: any
  password?: string
  country?: string
  document_id?: string;
}

export type FormFieldsStudentSettings = {
  code: string;
  codeContract: string;
  roadMap: string;
  currentStep: string;
  description?: string;
  isActive: boolean;
  start: string;
  finish: string;
  hours: number;
  hoursActivity: number;
  hoursUnits: number;
  level: TraditionalLevelEnum;
  placementTest?: SettingPlacementTestStatus
  applyResolution?: boolean;
  welcomeDay?: SettingWelcomeDayStatus
  verification?: SettingVerificationStatus
  daysFreezingAvailable: number;
  classAvailable: number;
  modality: SettingModalityType;
  status: SettingStudentStatus;
  campus: string;
};

const studentScheme = Yup.object().shape({
  code: Yup.string().required('field is required').min(1, 'at least 1 character'),
  codeContract: Yup.string().required('field is required').min(1, 'at least 1 character'),
  roadMap: Yup.string().required('field is required').min(1, 'at least 1 character'),
  currentStep: Yup.string().required('field is required').min(1, 'at least 1 character'),
  start: Yup.string().required('field is required').min(1, 'at least 1 character'),
  finish: Yup.string().required('field is required').min(1, 'at least 1 character'),
  level: Yup.string().oneOf(Object.values(TraditionalLevelEnum)).required('field is required'),
  placementTest: Yup.string().oneOf(Object.values(SettingPlacementTestStatus)).required('field is required'),
  welcomeDay: Yup.string().oneOf(Object.values(SettingWelcomeDayStatus)).required('field is required'),
  verification: Yup.string().oneOf(Object.values(SettingVerificationStatus)).required('field is required'),
  classAvailable: Yup.number().min(0).required('field is required'),
  status: Yup.string().oneOf(Object.values(SettingStudentStatus)).required('field is required'),
  campus: Yup.string().required('field is required'),
  daysFreezingAvailable: Yup.number().min(0).required('field is required'),
  hoursActivity: Yup.number().min(0).required('field is required'),
  hoursUnits: Yup.number().min(0).required('field is required'),
  applyResolution: Yup.boolean().required('field is required'),
  modality: Yup.string().oneOf(Object.values(SettingModalityType)).required('field is required')
});

type Props = {
  mode: 'ADD' | 'EDIT' | 'VIEW' | 'CREDENTIALS';
}
const StudentSettingsForm: React.FC<Props> = (props: Props) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { itemSelected, itemSelectedStudent, mode, type, resetData } = UseUserModule();
  const { data, isLoading } = usePlacementTestState();
  const { where, type: typeWelcome, setType, setWhere } = UsePlacementTestModule();
  const [campus, setCampus] = useState<Campus[]>([])
  const [roadMap, setRoadMap] = React.useState<RoadMap[]>([]);
  const [roadMapStep, setRoadMapStep] = React.useState<RoadMapStep[]>([])
  const [selectedWelcome, setSelectedWelcome] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [tabLabel, setTabLabel] = React.useState<string>('');
  const [user, setUser] = React.useState<FormUser>();

  const formRef = React.useRef<HTMLFormElement>(null);

  const memorizedValues = React.useMemo(() => ({ where, typeWelcome }), [where, typeWelcome]);

  const { values,
    errors,
    touched,
    resetForm,
    setFieldValue,
    handleChange,
    isValid,
    handleSubmit } =
    useFormik<FormFieldsStudentSettings>({
      initialValues: {
        code: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.code ?? '' : '',
        applyResolution: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.applyResolution ?? false : false,
        codeContract: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.codeContract ?? '' : '',
        roadMap: (mode === 'EDIT' || mode === 'VIEW') ? (itemSelectedStudent?.roadMap && itemSelectedStudent.roadMap?.id) ?? '' : '',
        currentStep: (mode === 'EDIT' || mode === 'VIEW') ? (itemSelectedStudent?.currentStep && itemSelectedStudent?.currentStep?.id) ?? '' : '',
        isActive: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.isActive ?? true : true,
        start: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.start ?? '' : '',
        finish: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.finish ?? '' : '',
        hours: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.hours ?? 0 : 0,
        level: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.level ?? TraditionalLevelEnum.PRE_A1_1 : TraditionalLevelEnum.PRE_A1_1,
        placementTest: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.placementTest ?? SettingPlacementTestStatus.PENDING : SettingPlacementTestStatus.PENDING,
        welcomeDay: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.welcomeDay ?? SettingWelcomeDayStatus.PENDING : SettingWelcomeDayStatus.PENDING,
        verification: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.verification ?? SettingVerificationStatus.APPROVED : SettingVerificationStatus.APPROVED,
        classAvailable: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.classAvailable ?? 0 : 0,
        status: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.status ?? SettingStudentStatus.ACTIVE : SettingStudentStatus.ACTIVE,
        campus: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.campus?.id ?? '' : '',
        daysFreezingAvailable: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.daysFreezingAvailable ?? 30 : 30,
        hoursActivity: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.hoursActivity ?? 0 : 0,
        hoursUnits: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.hoursUnits ?? 0 : 0,
        modality: (mode === 'EDIT' || mode === 'VIEW') ? itemSelectedStudent?.modality ?? SettingModalityType.VIRTUAL : SettingModalityType.VIRTUAL
      },
      validationSchema: studentScheme,

      onSubmit: async (formData) => {
        if (tabLabel === 'RE_CONTRACT') {
          try {
            setLoading(true);
            await ReContractAdd({
              ...formData,
              user: itemSelected?.id ?? ''
            })
            resetData();
            setLoading(false);
            queryClient.invalidateQueries({ queryKey: ['student-setting'] })
          } catch (e) {
            setLoading(false);
          }
        } else if (mode === 'ADD') {
          try {
            if (!user) return;
            setLoading(true);
            // const response = await UserAdd({ 
            //   ...user,
            //   email: user.email.toLowerCase()
            // });

            await StudentSettingsAdd({
              ...formData,
              isActive: user.isActive ?? true,
              evaluationId: selectedWelcome ?? ''
            }, {
              ...user,
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['student-setting'] })
          } catch (e) {
            setLoading(false);
          }
        }
        else {
          try {
            if (!user) return;
            setLoading(true);
            await UserUpdate({
              ...user,
              email: user.email.toLowerCase()
            })
            await StudentSettingsUpdate({
              ...formData,
              isActive: user.isActive,
              user: itemSelected?.id ?? ''
            })
            resetData();
            setLoading(false)
            queryClient.invalidateQueries({ queryKey: ['student-setting'] })

          } catch (e) {
            setLoading(false);
          }
        }
      },
    })

  const fetchCampus = React.useCallback(async () => {
    try {
      let campus = await UseCampusAll()
      setCampus(campus)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontar sedes academicas!', icon: 'error' })
      throw 'Error'
    }
  }, []);

  const fetchRoadMap = React.useCallback(async () => {
    try {
      let roadMapData = await UseRoadMapAll();
      setRoadMap(roadMapData)
    } catch (e) {
      showSimpleAlert({ message: 'Error al encontrar Road Map!', icon: 'error' })
      throw 'Error';
    }
  }, []);

  const disableInputs = (formRef: React.RefObject<HTMLFormElement>, disable: boolean) => {
    if (formRef.current) {
      const elements = formRef.current.elements;
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i] as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
        if (element.tagName.toLowerCase() === 'button' || element.type === 'button') {
          continue;
        }

        element.disabled = disable;
      }
    }
  };

  useEffect(() => {
    if (user && !user.isActive) {
      if (tabLabel === 'RE_CONTRACT') {
        disableInputs(formRef, false)
        resetForm({
          values: {
            code: '',
            codeContract: '',
            roadMap: '',
            currentStep: '',
            isActive: true,
            start: '',
            finish: '',
            hours: 0,
            level: TraditionalLevelEnum.PRE_A1_1,
            placementTest: SettingPlacementTestStatus.PENDING,
            welcomeDay: SettingWelcomeDayStatus.PENDING,
            verification: SettingVerificationStatus.APPROVED,
            classAvailable: 0,
            status: SettingStudentStatus.ACTIVE,
            campus: '',
            daysFreezingAvailable: 30,
            hoursActivity: 0,
            hoursUnits: 0,
            modality: SettingModalityType.VIRTUAL
          }
        })
      } else {
        disableInputs(formRef, !user.isActive)
        resetForm({
          values: {
            code: itemSelectedStudent?.code ?? '',
            applyResolution: itemSelectedStudent?.applyResolution ?? false,
            codeContract: itemSelectedStudent?.codeContract ?? '',
            roadMap: (itemSelectedStudent?.roadMap && itemSelectedStudent?.roadMap.id) ?? '',
            currentStep: (itemSelectedStudent?.currentStep && itemSelectedStudent?.currentStep.id) ?? '',
            isActive: itemSelectedStudent?.isActive ?? true,
            start: itemSelectedStudent?.start ?? '',
            finish: itemSelectedStudent?.finish ?? '',
            hours: itemSelectedStudent?.hours ?? 0,
            level: itemSelectedStudent?.level ?? TraditionalLevelEnum.PRE_A1_1,
            placementTest: itemSelectedStudent?.placementTest ?? SettingPlacementTestStatus.PENDING,
            welcomeDay: itemSelectedStudent?.welcomeDay ?? SettingWelcomeDayStatus.PENDING,
            verification: itemSelectedStudent?.verification ?? SettingVerificationStatus.APPROVED,
            classAvailable: itemSelectedStudent?.classAvailable ?? 0,
            status: itemSelectedStudent?.status ?? SettingStudentStatus.ACTIVE,
            campus: itemSelectedStudent?.campus?.id ?? '',
            daysFreezingAvailable: itemSelectedStudent?.daysFreezingAvailable ?? 30,
            hoursActivity: itemSelectedStudent?.hoursActivity ?? 0,
            hoursUnits: itemSelectedStudent?.hoursUnits ?? 0,
            modality: itemSelectedStudent?.modality ?? SettingModalityType.VIRTUAL
          }
        })
      }
    }
  }, [user?.isActive, tabLabel]);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['placement-test'] });
  }, [memorizedValues]);

  useEffect(() => {
    setType(PlacementType.WELCOME_DAY);
    fetchRoadMap();
    fetchCampus();
  }, []);

  useEffect(() => {
    if (values.roadMap) {
      const fetchRoadMapStep = async () => {
        try {
          let response = await RoadMapStepByRoadMap(values.roadMap);
          if (response && response.length > 0 && mode === 'ADD') {
            setFieldValue('currentStep', response[0].id);
            setFieldValue('level', response[0].level);
          };
          setRoadMapStep(response);
        } catch (e) {
          showSimpleAlert({ message: 'Error al encontrar Road Map Step!', icon: 'error' })
          throw 'Error';
        }
      };
      fetchRoadMapStep();
    }
  }, [values.roadMap, mode]);

  React.useEffect(() => {
    setFieldValue('classAvailable', values.hoursUnits + values.hoursActivity);
  }, [values.hoursUnits, values.hoursActivity]);

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form ref={formRef} onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item mb-10 col-4 d-flex justify-content-center'>
              <a
                className={`nav-link text-active-primary me-6 ` +
                  (tabLabel === '' && 'active')}
                onClick={() => setTabLabel('')}>
                <span style={{ paddingLeft: '5px' }}>Student</span>
                <KTIcon iconName={'user'} className='icon' />
              </a>
            </li>
            <li className='nav-item mb-10 col-4 d-flex justify-content-center'>
              <a
                className={`nav-link text-active-primary me-6 ` +
                  (tabLabel === 'SETTINGS' && 'active')}
                onClick={() => setTabLabel('SETTINGS')}>
                <span style={{ paddingLeft: '5px' }}>Settings</span>
                <KTIcon iconName={'setting'} className='icon' />
              </a>
            </li>
            {mode !== 'ADD' && itemSelected?.isActive === false && itemSelectedStudent &&
              <li className='nav-item mb-10 col-4 d-flex justify-content-center'>
                <a
                  className={`nav-link text-active-primary me-6 ` +
                    (tabLabel === 'RE_CONTRACT' && 'active')}
                  onClick={() => setTabLabel('RE_CONTRACT')}>
                  <span style={{ paddingLeft: '5px' }}>Recontrato</span>
                  <KTIcon iconName={'setting'} className='icon' />
                </a>
              </li>
            }
          </ul>

          <div hidden={tabLabel !== ''}>
            <UserForm
              mode={mode}
              byStudent
              setValuesUser={(v) => setUser(v)}
            />
          </div>

          {(tabLabel === 'SETTINGS' || tabLabel === 'RE_CONTRACT') && <>

            {/* <div className='mb-10 col-6'>
              <label className='form-label mb-0'>Nivel</label>
              <select
                disabled={mode === 'VIEW'}
                name='level'
                value={values.level}
                onChange={handleChange}
                className='form-select'
                aria-label='Default select example'
              >
                {Object.keys(TraditionalLevelEnum).map((x) => (
                  <option key={x} value={x}>
                    {setTypeToSpanish(x)}
                  </option>
                ))}
              </select>
              {errors.level && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.level}</span>
                  </div>
                </div>
              )}
            </div> */}
            <div className='mb-10 col-12'>
              <label className='form-label mb-3'>¿Aplica Resolución?</label>
              <div className="form-check form-switch">
                <input
                  name={'applyResolution'}
                  checked={values.applyResolution}
                  value={values.applyResolution ? 1 : 0}
                  onChange={handleChange}
                  className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
              </div>
            </div>
            <div className='mb-10 col-6'>
              <label className='form-label mb-0'>Campus</label>
              <select
                disabled={mode === 'VIEW'}
                name={'campus'}
                value={values.campus}
                onChange={handleChange}
                className="form-select" aria-label="Default select example">
                <option value={undefined}></option>
                {campus.map((x) => (<option key={x.id} value={x.id}>{x.name}</option>))}
              </select>
              {errors.campus && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.campus}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='mb-10 col-6'>
              <label className='form-label mb-0'>Modalidad</label>
              <select
                disabled={mode === 'VIEW'}
                name='modality'
                value={values.modality}
                onChange={handleChange}
                className='form-select'
                aria-label='Default select example'
              >
                {Object.keys(SettingModalityType).map((x) => (
                  <option key={x} value={x}>
                    {t(x)}
                  </option>
                ))}
              </select>
              {errors.modality && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.modality}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='mb-10 col-6'>
              <label className='form-label mb-0'>Verificación</label>
              <select
                disabled={mode === 'VIEW'}
                name='verification'
                value={values.verification}
                onChange={handleChange}
                className='form-select'
                aria-label='Default select example'
              >
                {Object.keys(SettingVerificationStatus).map((x) => (
                  <option key={x} value={x}>
                    {setTypeToSpanish(x)}
                  </option>
                ))}
              </select>
              {errors.level && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.level}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='mb-10 col-6'>
              <label className='form-label mb-0'>Welcome Day</label>
              <select
                disabled={mode === 'VIEW'}
                name='welcomeDay'
                value={values.welcomeDay}
                onChange={handleChange}
                className='form-select'
                aria-label='Default select example'
              >
                {Object.keys(SettingWelcomeDayStatus).map((x) => (
                  <option key={x} value={x}>
                    {setTypeToSpanish(x)}
                  </option>
                ))}
              </select>
              {errors.level && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.level}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-10 col-6'>
              <label className='form-label mb-0'>Placement Test Status</label>
              <select
                disabled={mode === 'VIEW'}
                name='placementTest'
                value={values.placementTest}
                onChange={handleChange}
                className='form-select'
                aria-label='Default select example'
              >
                {Object.keys(SettingPlacementTestStatus).map((x) => (
                  <option key={x} value={x}>
                    {setTypeToSpanish(x)}
                  </option>
                ))}
              </select>
              {errors.level && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.level}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='mb-10 col-6'>
              <label className='form-label mb-0'>Status</label>
              <select
                disabled={mode === 'VIEW'}
                name='status'
                value={values.status}
                onChange={handleChange}
                className='form-select'
                aria-label='Default select example'
              >
                {Object.keys(SettingStudentStatus).map((x) => (
                  <option key={x} value={x}>
                    {setTypeToSpanish(x)}
                  </option>
                ))}
              </select>
              {errors.level && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.level}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='mb-10 col-6'>
              <label className='form-label mb-3'>Code</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'code'}
                value={values.code}
                onChange={handleChange}
              />
              {touched.code && errors.code && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.code}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='mb-10 col-6'>
              <label className='form-label mb-3'>Code Contract</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'codeContract'}
                value={values.codeContract}
                onChange={handleChange}
              />
              {touched.codeContract && errors.codeContract && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.codeContract}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-10 col-6'>
              <label className='form-label mb-3'>Dias de Congelamiento</label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'daysFreezingAvailable'}
                value={values.daysFreezingAvailable}
                onChange={handleChange}
              />
              {touched.daysFreezingAvailable && errors.daysFreezingAvailable && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.daysFreezingAvailable}</span>
                  </div>
                </div>
              )}
            </div>
            {/* <div className='mb-10 col-6'>
              <label className='form-label mb-3'>Horas de experiencia</label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled = {true}
                name={'hours'}
                value={values.hours}
                onChange={handleChange}
              />
              {touched.hours && errors.hours && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.hours}</span>
                  </div>
                </div>
              )}
            </div> */}

            <div className='mb-10 col-6'>
              <label className='form-label mb-3'>Horas permitidas por unidades</label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'hoursUnits'}
                value={values.hoursUnits}
                onChange={handleChange}
              />
              {touched.hoursUnits && errors.hoursUnits && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.hoursUnits}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-10 col-6'>
              <label className='form-label mb-3'>Horas permitidas por actividades</label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'hoursActivity'}
                value={values.hoursActivity}
                onChange={handleChange}
              />
              {touched.hoursActivity && errors.hoursActivity && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.hoursActivity}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='mb-10 col-6'>
              <label className='form-label mb-3'>Total de clases por semana</label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled
                name={'classAvailable'}
                value={values.classAvailable}
                onChange={handleChange}
              />
              {touched.classAvailable && errors.classAvailable && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.classAvailable}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='mb-10 col-6'>
              <label className='form-label mb-3'>Buscar Welcome Day</label>
              <div className='input-group'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder=''
                  disabled={mode === 'VIEW'}
                  name={'selectedWelcome'}
                  value={where}
                  onChange={(e) => setWhere(e.target.value)}
                />
                <span className='input-group-text border-0' id='search-icon'>
                  <i className='fas fa-search'></i>
                </span>
              </div>
            </div>

            <div className='mb-10 col-6'>
              <label className='form-label mb-3'>Welcome Day</label>
              <select
                disabled={mode === 'VIEW'}
                name='welcomeDay'
                value={selectedWelcome}
                onChange={(e) => setSelectedWelcome(e.target.value)}
                className='form-select'
                aria-label='Default select example'
              >
                <option value={undefined}></option>
                {data && data.result && data.result.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </select>
              {errors.level && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.level}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='mb-10 col-12'>
              <label className='form-label mb-0'>Road Map</label>
              <select
                name='roadMap'
                className='form-select'
                aria-label='Default select example'
                onChange={handleChange}
                value={values.roadMap}
                disabled={mode === 'VIEW'}
              >
                <option value='' selected>

                </option>
                {roadMap?.map((x, i) => (
                  <option value={x.id} key={i}>
                    {x.name}
                  </option>
                ))}
              </select>
            </div>

            {/* <div className='mb-1 col-6'>
              <label className='form-label mb-0'>Road Map Step</label>
              <select
                name='currentStep'
                className='form-select'
                aria-label='Default select example'
                onChange={handleChange}
                value={values.currentStep}
                disabled={mode === 'VIEW'}
              >
                <option value='' selected>

                </option>
                {roadMapStep?.map((x, i) => (
                  <option value={x.id} key={i}>
                    {x.name}
                  </option>
                ))}
              </select>
            </div> */}

            <div className='mb-1 col-6'>
              <label className='form-label mb-0'>Fecha De Inicio</label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'start'}
                value={values.start}
                onChange={handleChange}
              //   onChange={(e)=>{
              //    if(handleHourChange(e)){
              //      handleChange(e)
              //    }
              //   }
              // }
              />
              {touched.start && errors.start && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.start}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-1  col-6'>
              <label className='form-label mb-0'>Fecha De Cierre</label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid'
                placeholder=''
                disabled={mode === 'VIEW'}
                name={'finish'}
                value={values.finish}
                onChange={handleChange}
              //   onChange={(e)=>{
              //     if(handleHourEndChange(e)){
              //       handleChange(e)
              //     }
              //    }
              //  }
              />
              {touched.finish && errors.finish && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{errors.finish}</span>
                  </div>
                </div>
              )}
            </div>
          </>}

        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading || mode === 'VIEW' || !isValid}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export { StudentSettingsForm }
