import { create } from 'zustand';

type DateRange = {
  before: Date
  after: Date
}

interface SurveySectionResponseFilterModulesIE {
  before: Date;
  after: Date;
  isActive: boolean,
  activity?: string
  campus?: string,
  classroom?: string,
  country?: string;
  teacher?: string;
  stars?: number;
  typeClassroom?: string;
  typeClass?: string;
  student?: string;
  level?: string
  setTypeClassroom: (value: string) => void;
  setTypeClass: (value: string) => void;
  setStars: (value: number) => void;
  setTeacher: (value: string) => void;
  setStudent: (value: string) => void;
  setDate: (value: DateRange) => void;
  setIsActive: (mode: boolean) => void;
  setActivity: (mode: string) => void;
  setCampus: (value: string) => void;
  setLevel: (value: string) => void;
  setClassroom: (value: string) => void;
}

export const UseSurveySectionResponseFilterModules = create<SurveySectionResponseFilterModulesIE>((set) => ({
  before: new Date(),
  after: new Date(),
  isActive: true,
  setDate: (value) => {
    set({
      before: value.before,
      after: value.after,
    });
  },
  setStars: (value) => {
    set({ stars: value });
  },
  setTeacher: (value) => {
    set({ teacher: value });
  },
  setTypeClass: (value) => {
    set({ typeClass: value });
  },
  setTypeClassroom: (value) => {
    set({ typeClassroom: value });
  },
  setStudent: (value) => {
    set({ student: value });
  },
  setIsActive: (value) => {
    set({ isActive: value })
  },
  setActivity: (value) => {
    set({ activity: value })
  },
  setCampus: (value) => {
    set({ campus: value })
  },
  setLevel: (value) => {
    set({ level: value })
  },
  setClassroom: (value) => {
    set({ classroom: value })
  },
}));