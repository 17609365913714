import useAuth from "../../../api/main";
import { catchServerError } from "../../../hooks/useCatchError";
import { UseStudentReportFilterModule } from "../store/student-filter.store";
import { api } from '../../auth/services/auth.services'
const { token, logout, authChecked } = useAuth.getState()

export const UseStudentFilterReport = async (): Promise<any> => {
    const { isActive,after,before,level,filterStudent,modality} = UseStudentReportFilterModule.getState()
    try {
      let url = `/dashboard/students_by_level?isActive=${isActive===true?1:0}`;
      if (after) {
        url += `&after=${after}`;
      }
      if (level) {
        url += `&level=${level}`;
      }
      if (before) {
        url += `&before=${before}`;
      }
    //   if (filterStudent){
    //     url += `&filterStudent=${filterStudent}`;
    //   }
      if (modality){
        url += `&modality=${modality}`;
      }
      const response = await api.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response.data
    } catch (e: any) {
      catchServerError(e.response);
      throw 'Error'
    }
  }