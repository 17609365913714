import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {ErrorMessage, Field, useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Avatar} from '../../interfaces'

import { AvatarAdd, AvatarUpdate } from '../../services'
import { UseAvatarModule } from '../../store/main'
import { useQueryClient } from '@tanstack/react-query'
import FileUploadComponent from '../../../../../components/common/SingleFile'
import { favIconAppSelector } from '../../../../../hooks/useAppSelectorAssetsMode'

export type FormFields = {
  name: string
  isActive: boolean
  description?:string
  category:string
  avatar:string
}

const entitySchema = Yup.object().shape({
  name: Yup.string().required('First name is required'),
  type_category: Yup.string(),
})

type Props ={
  mode: 'ADD'|'EDIT'|'VIEW'
}
const AvatarForm: React.FC<Props>  = (props:  Props) => {
  const queryClient = useQueryClient()
  const {modal,itemSelected, mode,setModal,resetData,setSelectedToEdit,setSelectedToView} = UseAvatarModule();
  const [data, setData] = useState<Avatar>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const [avatar, setAvatar] = React.useState<any>()
  
  const {values, errors, touched, setFieldValue, handleChange, handleSubmit} =
  useFormik<FormFields>({
    initialValues: {
      category: (mode==='EDIT' || mode==='VIEW')? itemSelected?.category??'': '',
      avatar: (mode==='EDIT' || mode==='VIEW')? itemSelected?.avatar.id??'': '',
      name:(mode==='EDIT' || mode==='VIEW')? itemSelected?.name??'': '',
      isActive:(mode==='EDIT' || mode==='VIEW')? itemSelected?.isActive??true:true,
      description:(mode==='EDIT' || mode==='VIEW')? itemSelected?.description??'':'',
    },
    validationSchema: entitySchema,
    
    onSubmit:async (formData)=> {
      if(props.mode==='ADD'){
        try{
          setLoading(true);
          await AvatarAdd({...formData})
          resetData();
          setLoading(false)
          queryClient.invalidateQueries({ queryKey: ['avatar'] })
          
        }catch(e){
          setLoading(false);
        }

      }
      else{
        try{
          setLoading(true);
          await  AvatarUpdate({...formData})
          resetData();
          setLoading(false)
          queryClient.invalidateQueries({ queryKey: ['avatar'] })
          
        }catch(e){
          setLoading(false);
        }
          }
      },
    })
    const  changeAvatar =async (obj:any)=> {  
        setAvatar(obj)
        setFieldValue('avatar',obj.id)
    }

    useEffect(() => {
      setAvatar(itemSelected?.avatar)
    }, [itemSelected])
  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form onSubmit={handleSubmit} noValidate className='form'>
        <div className='card-body border-top p-9 row'>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Activo ?</label>
            <div className="form-check form-switch">
              <input
                name={'isActive'}
                checked={values.isActive}
                value={values.isActive?1:0}
                onChange={handleChange}
                className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
            </div>
          </div>

          <div className='mb-10 col-12 mx-auto d-flex'>

            { (avatar && (avatar.url || avatar.location)) ? 
                
                <img  className="img-fluid" width={'50%'} src={(avatar.url||avatar.location)??toAbsoluteUrl(favIconAppSelector())} alt='Metornic' />
                :
                <img  className="img-fluid" width={'50%'} src={toAbsoluteUrl(favIconAppSelector())} alt='Metornic' />
          }
 
                 <FileUploadComponent onChangeFile={(e)=>changeAvatar(e)} onChangeLoading={(e)=>setLoading(e)}  />

  
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Name</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'name'}
              value={values.name}
              onChange={handleChange}
            />
            {touched.name && errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.name}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-6'>
            <label className='form-label mb-3'>Category</label>
            <select
              disabled={mode === 'VIEW'}
              name={'category'}
              value={values.category}
              onChange={handleChange}
              className="form-select" aria-label="Default select example">
                <option selected></option>
                
                <option value='ONLY_TEACHER'>Solo Para Tutores</option>
                <option value='FANTASY'>Fantasía</option>
                <option value='SCIENCE_FICTION'>Ciencia Ficción</option>
                <option value='ANIMALS'>Animales</option>
                <option value='PROFESSIONS'>Profesiones</option>
                <option value='SPORTS'>Deportes</option>
                <option value='HISTORY_CULTURE'>Historia y Cultura</option>
                <option value='SUPERHEROES_VILLAINS'>Superhéroes y Villanos</option>
                <option value='NATURE'>Naturaleza</option>
                <option value='MUSIC_ENTERTAINMENT'>Música y Entretenimiento</option>
                <option value='LIFESTYLE_FASHION'>Estilo de Vida y Moda</option>
                <option value='COMICS_ANIME'>Cómics y Anime</option>
                <option value='TECHNOLOGY_CYBERPUNK'>Tecnología y Cyberpunk</option>
                <option value='GHOSTS_SUPERNATURAL'>Fantasmas y Criaturas Sobrenaturales</option>
                <option value='VEHICLES_MACHINES'>Vehículos y Máquinas</option>
                <option value='ABSTRACT_ARTISTIC'>Abstracto y Artístico</option>
            </select>
            {errors.category && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.category}</span>
                </div>
              </div>
            )}
          </div>

          <div className='mb-10 col-12'>
            <label className='form-label mb-3'>Description</label>
            <textarea
              className='form-control form-control-lg form-control-solid'
              placeholder=''
              disabled={mode==='VIEW'}
              name={'description'}
              value={values.description}
              onChange={handleChange}
            />
            {touched.description && errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading ||mode==='VIEW'}>
            {!loading && 'Save'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  )
}

export {AvatarForm}
