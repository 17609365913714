import React from 'react';
import { ExercisesType, VariantsExercisesType } from '../../admin/exercises/interfaces';
import { HighlightWord } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseSeven/ExerciseSeven';
import { CorrectSentence } from '../../courses/exercises/CorrectSentence';
import { WritingCheckList } from '../../courses/exercises/WrintingCheckList';
import { BoosterEx } from '../../courses/exercises/booster';
import { Coordinates } from '../../courses/exercises/coordinates/ExerciseEleven';
import { GroupWords } from '../../courses/exercises/group-words/ExerciseTwelve';
import { BeyondReadingWritingExerciseOne } from '../../courses/exercises/history-wrinting/beyond-reading-one';
import { MultipleSelectionEx } from '../../courses/exercises/multiple-selection';
import { OrderSentences } from '../../courses/exercises/order-sentence';
import { DragDropBeyondReading } from '../../courses/exercises/paragraph-drag-drop/drag-drop-beyond-reading';
import { SpeakOutExercise } from '../../courses/exercises/speak-out';
import { TrueOrFalseBookContainer } from '../../courses/exercises/true-false/book-container';
import { TrueOptions } from '../../courses/exercises/true-options';
import { TrueWords } from '../../courses/exercises/true-words';
import { AudioInteraction } from './interactions/audio';
import { CheckWritingEx } from '../../courses/exercises/check-writing';
import { ExerciseOne } from '../../../components/abroad/e-book/beyong-the-reading/ExerciseOne/ExerciseOne';
import { showSimpleAlert } from '../../../commonHooks/alert';
import { CrosswordExercise } from '../../courses/exercises/Crossword';

export type ModeTypeExercise =  'ADMIN' | 'TEACHER' | 'STUDENT';

type Props = {
  exercise: any;
  userMode?: ModeTypeExercise;
};

const RenderExerciseBook: React.FC<Props> = ({ exercise, userMode }) => {
  return(<>
    {exercise.variant === VariantsExercisesType.BOOK &&
      exercise.type === ExercisesType.CROSSWORD ?
      <CrosswordExercise userMode={userMode}/>
      : exercise.type === ExercisesType.AUDIO_INTERACTION ? 
      <AudioInteraction />
      : exercise.type === ExercisesType.ORDER_SENTENCES ? 
      <OrderSentences userMode={userMode}/>
      : exercise.type === ExercisesType.WRITING_CHECK_LIST ? 
      <WritingCheckList userMode={userMode} /> // ?Tener dos ejercicios del mismo tipo no encuentra alguno de los dos 
      : exercise.type === ExercisesType.HIGHLIGHT_WORD ? 
      <HighlightWord userMode={userMode}/> 
      : exercise.type === ExercisesType.TRUE_OPTIONS ? 
      <TrueOptions userMode={userMode} variant={exercise.true_option[0]?.type}/>  
      : exercise.type === ExercisesType.MULTIPLE_SELECTION ? 
      <MultipleSelectionEx userMode={userMode} variant={exercise.multiple_selection[0]?.type}/>  
      : exercise.type === ExercisesType.DRAG_DROP ?  
      <DragDropBeyondReading userMode={userMode}/>  
      : exercise.type === ExercisesType.CORRECT_SENTENCES ?   
      <CorrectSentence userMode={userMode ? userMode : "ADMIN"} /> 
      : exercise.type === ExercisesType.TRUE_WORDS ? 
      <TrueWords userMode={userMode}/>        
      : exercise.type === ExercisesType.HISTORY_WRITING ?  //?No existe ninguno en el programa, si es necesario entonces?
      <BeyondReadingWritingExerciseOne userMode={userMode}/>//!Traer los datos del feedback
      : exercise.type === ExercisesType.TRUE_FALSE ? 
      <TrueOrFalseBookContainer userMode={userMode} /> 
      : exercise.type === ExercisesType.GROUP_WORDS ? 
      <GroupWords userMode={userMode}/>     
      : exercise.type === ExercisesType.COORDINATES ? 
      <Coordinates userMode={userMode}/>    
      : exercise.type === ExercisesType.SPEAK_OUT ? 
      <SpeakOutExercise userMode={userMode}/>  //?Tener dos ejercicios del mismo tipo duplica una respuesta y no trae el feedback de la otra 
      : exercise.type === ExercisesType.BOOSTER ?
      <BoosterEx userMode={userMode}/> 
      : exercise.type === ExercisesType.CHECK_WRITING ?
      <CheckWritingEx userMode={userMode}  //? No tiene mucho sentido Traer los datos del feedback (todos son de tipo single)
      type={exercise.check_writing[0]?.type} />
      : exercise.type === ExercisesType.COMPLETE_SENTENCES ?  
        <ExerciseOne instruction={exercise.description} userMode={userMode}/>
      : 
      showSimpleAlert({message: 'No se reconoce el ejercicio', icon: 'error'})
    }
  </>);
};

export default React.memo(RenderExerciseBook);
