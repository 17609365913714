import React, {useEffect} from 'react'
import {KTIcon, KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {CreateModal} from '../modal'
import {useQueryClient} from '@tanstack/react-query'
import {useTemplateTableTimeState} from '../../hooks/useTemplateTableTimeRepo'
import {UseTemplateTableTimeModule} from '../../store/main'
import {TemplateTableTime} from '../../interfaces'
import {BooleanCell} from '../../../../../components/common/BooleanCell'
import * as Swal from 'sweetalert2'
import {TemplateTableTimeDelete} from '../../services'
import {showSimpleAlert} from '../../../../../commonHooks/alert'
import GenericPaginator from '../../../../../components/common/Paginator'
import supabase from '../../../../../supabase/supabase'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
type PaginatorSetter = {
  currentPage: number
  items: number
  total: number
  totalPage: number
}
type Props = {}
const TemplateTableTimeTableList: React.FC<Props> = ({}) => {
  const queryClient = useQueryClient()
  const {
    paginator,
    modal,
    limit,
    currentPage,
    isActive,
    where,
    setCurrentPage,
    setLimit,
    setIsActive,
    setSelectedToEdit,
    setSelectedToView,
    setModal,
    setWhere,
    setToAdd,
  } = UseTemplateTableTimeModule()
  const {data, isLoading} = useTemplateTableTimeState()

  let [page, setPage] = React.useState<PaginatorSetter>({
    currentPage: 1,
    items: 0,
    total: 0,
    totalPage: 0,
  })

  const deleteItem = async (id: string) => {
    //@ts-ignore
    Swal.fire({
      title: 'Are you sure?',
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, do it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await TemplateTableTimeDelete(id)
          showSimpleAlert({message: 'Ok!', icon: 'success'})
        } catch (e) {}
      }
    })
  }
  const changePage = (number: number) => {
    setCurrentPage(number)
  }
  const changeLimit = (number: number) => {
    setLimit(number)
  }

  // const test = async () => {
  //   supabase
  // .channel('any')
  // .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'country' }, (payload) => {
  //   console.log()
  //   showSimpleAlert({
  //     message: '', icon: 'info'
  //   })
  // })
  // .subscribe()
  // }
  // useEffect(()=>{
  //   test()
  // },[])
  useEffect(() => {
    queryClient.invalidateQueries({queryKey: ['template-table-time']})
  }, [isActive, where, currentPage, limit])
  return (
    <>
      <div className={`card `}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'> Plantillas </span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Gestión de Plantillas </span>
          </h3>
          <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to add a user'
          >
            <div className='position-relative my-1'>
              <KTSVG
                path='/media/svg/icons/earth.svg'
                className='svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-10'
              />
              <input
                type='text'
                onChange={(e) => setWhere(e.target.value)}
                className='form-control form-control-sm form-control-solid w-350px ps-10'
                name='Search'
                value={where}
                placeholder='Search'
              />
            </div>
            <button
              onClick={() => setToAdd()}
              className='btn btn-sm btn-light-primary mr-8'
              // data-bs-toggle='modal'
              // data-bs-target='#kt_modal_invite_frien ds'
            >
              <KTIcon iconName='plus' className='fs-3' />
            </button>
            <button
              onClick={() => setIsActive(!isActive)}
              className='btn btn-sm btn-light-secondary  mr-8 p-2'
              // data-bs-toggle='modal'
              // data-bs-target='#kt_modal_invite_friends'
            >
              <BooleanCell value={isActive} />
            </button>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          {!isLoading && data && (
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-140px'>País</th>
                    <th className='min-w-120px'>Descripción</th>
                    <th className='min-w-120px'>Active</th>
                    <th className='min-w-100px text-end'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {isLoading && (
                    <tr className='text-center'>
                      <img
                        className='rounded mx-auto d-block'
                        width={'70%'}
                        src={toAbsoluteUrl('/media/svg/icons/loading.svg')}
                        alt=''
                      />
                    </tr>
                  )}
                  {data.result.map((x: TemplateTableTime) => (
                    <tr key={x.id} className='p-0'>
                      <td className='p-0'>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-45px me-5'>
                            <img src={toAbsoluteUrl('/media/svg/icons/earth.svg')} alt='' />
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {x.name}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'></span>
                          </div>
                        </div>
                      </td>
                      <td className='p-0'>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {x.description}
                        </span>
                      </td>
                      <td className='text-start'>
                        <BooleanCell value={x.isActive} />
                      </td>
                      <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <OverlayTrigger overlay={<Tooltip>View</Tooltip>} placement='bottom'>
                            <button
                              onClick={() => setSelectedToView(x)}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <i style={{color: 'grey', fontSize: 17}} className='fas fa-eye'></i>
                            </button>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip>Edit</Tooltip>} placement='bottom'>
                            <button
                              onClick={() => setSelectedToEdit(x)}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            >
                              <i style={{color: 'blue', fontSize: 17}} className='fas fa-pen'></i>
                            </button>
                          </OverlayTrigger>
                          {/* <OverlayTrigger overlay={<Tooltip>{x.isActive? "Inactivate" : "Activate"}</Tooltip>} placement='bottom'>
                            <button
                              onClick={() => deleteItem(x.id??'xxx')}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              >
                              <BooleanCell value={!x.isActive} />
                            </button>
                          </OverlayTrigger> */}
                          <OverlayTrigger
                            overlay={<Tooltip>Select item</Tooltip>}
                            placement='bottom'
                          >
                            <a
                              href={`/template-table-time/item/${x.id}`}
                              // onClick={() => setSelectedItem(x)}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                            >
                              <i
                                style={{color: 'blue', fontSize: 17}}
                                className='bi bi-arrow-right-short'
                              ></i>
                            </a>
                          </OverlayTrigger>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
              <GenericPaginator
                limit={limit}
                currentPage={paginator.current_page}
                items={paginator.result.length}
                total={paginator.total}
                totalPage={paginator.total_page}
                changePage={changePage}
                changeLimit={changeLimit}
              />
              {/* end::Table */}
            </div>
          )}
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <CreateModal show={modal} handleClose={() => alert()} />
    </>
  )
}

export {TemplateTableTimeTableList}
