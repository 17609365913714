import React from 'react'
import moment from 'moment'
import './index.css'
import {PromotionHistoryTableItem, PromotionHistoryType} from '../../interfaces'
import {useThemeMode} from '../../../../../../../_metronic/partials'

type Props = {
  item: PromotionHistoryTableItem
}

const TableItem: React.FC<Props> = ({item}) => {
  const {mode} = useThemeMode()
  const isAdjustment: boolean = item.new_level === item.previous_level

  return (
    <>
      <tr key={item.id} className='text-center fs-5' style={{color: mode === 'light' ? '#2d30e1' : '#FFFFFF'}}>
        <td className='p-0' >
          <span className='d-block poppins-light'>
            {moment(item.date.split(' ')[0]).format('DD/MM/YYYY')}
          </span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-light'>
            {
            isAdjustment? 
              <div className='text-warning-emphasis'>
                <i className='bi bi-arrow-down-up text-warning-emphasis'/>
                ADJUSTMENT
              </div>
            : item.promotionType === PromotionHistoryType.UPGRADE ? (
              <div style={{color: "green"}}>
                <i className='bi bi-arrow-up'  style={{color: "green"}}/>
                {item.promotionType}
              </div>
            ) : item.promotionType === 'empty' ? (
              <></>
            ) : (
              <div style={{color: "red"}}>
                <i className='bi bi-arrow-down' style={{color: "red"}} />
                {item.promotionType}
            </div>
            )}
          </span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-light'>{item.previous_level}</span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-light'>{item.new_level}</span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-light'>{item.target_lesson}</span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-light'>{item.changeBy}</span>
        </td>
        <td className='p-0'>
          <span className='d-block poppins-light'>{item.description}</span>
        </td>
      </tr>
    </>
  )
}

export {TableItem}