//import { Country, DataAllCountryResponse, DataPaginateCountryResponse } from '../interfaces/index'
import useAuth from '../../../../api/main'

import { catchServerError } from '../../../../hooks/useCatchError'
import { useStore } from 'zustand'
 
import { api } from '../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import { UseBookingModule } from '../../store'
import { UseMyRoadmapModule } from '../../../profile/components/my-road-step/store'
import { UseModeCourseModule } from '../../../courses/components/front-page/store/main'

const { token, logout, authChecked } = useAuth.getState()
const {modeCourseChecked} = UseModeCourseModule.getState();
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}
export interface BookingFind {
  id?: string
  after:string
  before:string;
  isSuspense?:boolean;
  limit?:number;
  currentPage?:number;
    blockHour?: number;
  }
  export const UseFindPaginatedTrailClass = async ({id, before ,after,isSuspense,limit,currentPage}:BookingFind): Promise<any> => {
  const { settings,settingChecked } = UseMyRoadmapModule.getState();
  try {
    if(id){
      modeCourseChecked();
      let url = `/trail-class/paginated?before=${before}&after=${after}&root=${id}&limit=${limit??10000}&page=${currentPage??1}`;
      if (typeof isSuspense !== 'undefined') {
        if (isSuspense || !isSuspense) {
          url += `&isSuspense=${isSuspense ? 1 : 0}`
        }
      }
      
      const response: any = await api.get(
        `${url}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } else {
      let responseSetting = settings ? settings : await settingChecked();
      modeCourseChecked();
      let url = `/trail-class/paginated?before=${before}&after=${after}&root=${responseSetting ?responseSetting.id:settings ? settings.id : ''}&limit=${limit??10000}&page=${currentPage??1}`;
      if (typeof isSuspense !== 'undefined') {
        if (isSuspense || !isSuspense) {
          url += `&isSuspense=${isSuspense ? 1 : 0}`
        }
      }
      
      const response: any = await api.get(
        `${url}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    }
    
  } catch (e) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const UseAddTrialClass = async (appointment:string): Promise<any> => {
  const { settings,settingChecked } = UseMyRoadmapModule.getState();
  try {
    let responseSetting = settings ? settings : await settingChecked()
        const response: any = await api.post(
          `/trail-class/booking`,{
            appointment:appointment,
            student:responseSetting ? responseSetting.id : settings ?settings.id:''
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        return response.data.result
    
  } catch (e) {
    catchServerError(e);
  }
}

export const UseAddTrialClassInAppointment = async (appointment:string, setting: string): Promise<any> => {
  try {
        const response: any = await api.post(
          `/trail-class/booking`,{
            appointment:appointment,
            student:setting
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        return response.data.result
    
  } catch (e) {
    //@ts-ignore
    if (e.response.status === 401) {
     // logout()
    }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}