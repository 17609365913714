import {create} from 'zustand';
import { Answer, AnswerDragDrop } from '../interfaces';
type StatusQuiz = 'ANSWER'|'RESUME'|'THROW_ERROR'|'TITLE';
export interface WordItem {
    id: string;
    text: string;
}
interface Question {
    sentences:string[]
    words:WordItem[]
}
interface ResponseWordsInterface {
    allWords:AnswerDragDrop
    hasChecked:boolean
    percentage:number
}

interface ParagraphDragDropInterface {
    sentenceNumber: number
    status:StatusQuiz
    title:string;
    instructions:string;
    percentage:number;
    answerStudent?:AnswerDragDrop
    allWords: AnswerDragDrop
    hasChecked:boolean
    sentences:string[]
    words:WordItem[]
    sentencesConstructed:string
    total:number
    exerciseId?: string;
    filledGaps:{[key: string]: string}
    usedWords:{[key: string]: boolean}
    clickedWord: WordItem | null
    setUsedWords:(value: {[key: string]: boolean}, resetData:boolean)=>void 
    setFilledGaps:(value:{[key: string]: string}, resetData:boolean)=>void
    handleRemoveWord:(gapId: string, wordId: string)=>void
    setSentences:(value:Question)=>void;
    setSentencesConstructed:(value:string)=>void;
    setStatus:(value:StatusQuiz)=>void
    setStep:(value:number)=>void;
    startQuiz:()=>void;
    setWords:(response:ResponseWordsInterface)=>void;
    setQuestionsParagraphDrag:(
        exerciseId: string,
        words:WordItem[], 
        sentences: string[], 
        title: string, 
        instructions: string)=>void;    
    resetData:()=>void;
    setAnswerStudent:(value: AnswerDragDrop)=>void;
    setSentenceNumber:(value: number)=>void;
    setHasChecked: (value: boolean) =>void;
    setClickedWord: (value: WordItem | null)=>void;
}

export const UseParagraphDragDropModule = create<ParagraphDragDropInterface>( (set, get) => ({
    status:'TITLE',
    title:"",
    instructions:"",
    questions:[],
    sentencesConstructed:'',
    allWords:{
        filledGaps: {},
        usedWords: {}
    },
    usedWords:{},
    filledGaps:{},
    hasChecked:false,
    sentences:[
        //  "Leo: Es un hecho establecido hace {demasiado} tiempo que un lector se distraerá con el contenido del texto de un sitio mientras que mira su {diseño}. Juan : {Rios} este es un apellido.",
        // "Luis: {Rel Madrid} es el equipo mas grande del mundo.",
        // "Leo: {Lionel Pessi} es la {cabra} Lionel Pessi."
    ],
    words:[
        //  { id: 'word_1', text: 'diseño' },
        //  { id: 'word_2', text: 'demasiado' },
        //  { id: 'word_3', text: 'Rel Madrid' },
        //  { id: 'word_4', text: 'very' },
        //  { id: "word_5", text:"Lionel Pessi"},
        //  { id: "word_7", text:"Lionel Pessi"},
        //  { id: "word_10", text:"Lionel Pessi"},
        //  { id: "word_6", text:"cabra"},
        //  { id: "word_12", text:"Rios"}
    ],
    step:0,
    percentage:0,
    list:[],
    total:0,
    sentenceNumber: 0,
    clickedWord: null,
    setWords:({...value}:ResponseWordsInterface)=>{
        set({
            allWords: value.allWords,
            hasChecked:value.hasChecked,
            percentage:value.percentage
        })
    },
    setSentencesConstructed:(value:string)=>{
        set({
            sentencesConstructed:value
        })
    },
    setSentences:(value:Question)=>{
        set({
            words:value.words,
            sentences:value.sentences
        })
    },
    setAnswer:(value:Answer)=>{
        let status:StatusQuiz ='ANSWER';
        if(value.isTrue){
            set({ 
                status:status
            })
        }
        if(!value.isTrue){
            set({
                status:status
            })
        }
    },
    setStep:(value:number)=>{
        let status:StatusQuiz ='THROW_ERROR';
        set({
            status:status
        })
    },
    startQuiz(){
        set({
            status:'ANSWER'
        })
    },
    setStatus:(value:StatusQuiz)=>{
        if(value==='ANSWER'){
            set({
                status:'ANSWER'
            })
        }
        if(value==='THROW_ERROR'){
            set({
                status:'THROW_ERROR'
            })
        }
        if(value==='RESUME'){
            set({
                status:'RESUME'
            })
        }
    },
    setQuestionsParagraphDrag:(exerciseId, words, sentences, title, instruction) =>{
        set({ words, sentences, title, instructions: instruction, exerciseId, answerStudent: undefined });
    },
    resetData:()=>{
        set({
            sentenceNumber: 0,
            filledGaps: {},
            usedWords: {},
            hasChecked: false,
            sentences: [],
            words: [],
            allWords:{
                filledGaps: {},
                usedWords: {}
            },
            percentage:0,
            total:0,
            status: 'TITLE'
        })
    },
    setAnswerStudent:(value)=>{
        set({answerStudent: value});
    },
    setSentenceNumber: (value)=>{
        set({sentenceNumber: value})
    },
    setFilledGaps: (value, resetData)=>{
        if(resetData){
            set({filledGaps:value})
        } else {
            const newFill = {...get().filledGaps, ...value}
            set({filledGaps: newFill})
        }
    },
    setUsedWords: (value, resetData)=>{
        if(resetData){
            set({usedWords:value})
        }else {
            const newFill = {...get().usedWords, ...value}
            set({usedWords: newFill})
        }
    },
    setHasChecked: (value)=>{
        set({hasChecked:value})
    },
    handleRemoveWord: (gapId, wordId)=>{
        const newStateFilledGap = {...get().filledGaps}
        delete newStateFilledGap[gapId]
        const newStateUsedWord = {...get().usedWords}
        delete newStateUsedWord[wordId]
        set({
            filledGaps: newStateFilledGap,
            usedWords: newStateUsedWord
        })
    },
    setClickedWord: (value)=>{
        set({clickedWord: value})
    }
}));