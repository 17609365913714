import { DataSurveySectionResponse, DataAllSurveySectionResponseResponse, DataPaginateSurveySectionResponseResponse } from '../interfaces/index'
import useAuth from '../../../../../../api/main'

import { catchServerError } from '../../../../../../hooks/useCatchError'
import { useStore } from 'zustand'
import { UseSurveySectionResponseModule } from '../store/main'
import { api } from '../../../../../auth/services/auth.services'
import { showSimpleAlert } from '../../../../../../commonHooks/alert'
import { UseSurveySectionResponseFilterModules } from '../../../../../report/store/survey-section-res-filter.store'

const { token, logout, authChecked } = useAuth.getState()
export const createItem = async (data: any) => {
  const response = await api.post('/items', data)
  return response.data
}
export const useSurveySectionResponseAll = async (): Promise<any> => {
  try {
    const response = await api.get(`/survey-section-response/simple?type_category=all`,
    {
      headers: {
        Authorization: 'Bearer YOUR_ACCESS_TOKEN_HERE',
      },
    })
    return response.data
  } catch (e) {
    //@ts-ignore
    catchServerError(e.response)
  }
}
export const useSurveySectionResponseAllPaginate = async (): Promise<any> => {
  try {
    // await authChecked()
    const { setPaginator, limit, currentPage, where, isActive } = await UseSurveySectionResponseModule.getState()

    const response: any = await api.get(
      `/survey-section-response/paginated?isActive=${isActive ? 1 : 0}&limit=${limit}&page=${currentPage}&where=${where}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    setPaginator(response.data)
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const SurveySectionResponseAdd = async (data: DataSurveySectionResponse): Promise<any> => {
  try {
    const response: any = await api.post(
      '/survey-section-response',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}
export const SurveySectionResponseUpdate = async (data: DataSurveySectionResponse): Promise<any> => {
  try {
    const { itemSelected } = UseSurveySectionResponseModule.getState()
    const response: any = await api.patch(
      `/survey-section-response/${itemSelected?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data
  } catch (e:any) {
    //@ts-ignore
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         //@ts-ignore
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    //@ts-ignore
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const GetOneSurveySectionResponse = async (studentId: string, appointmentId: string): Promise<any> => {
  try {
    // await authChecked()

    const response: any = await api.get(
      `/survey-section-response/${studentId}/${appointmentId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (e:any) {
    if (e.response.status === 401) {
      logout()
    }
    if (e.response.status === 400) {
       e.response.data.message.map((x)=>{
         showSimpleAlert({ message: x, icon: 'error' })
       })
       throw 'Error'
     }
    showSimpleAlert({ message: 'Error!', icon: 'error' })
    throw 'Error'
  }
}

export const FindResumeSurveySectionUseCase = async (): Promise<any> => {
  const {
    level,
    campus,
    activity,
    teacher,
    stars,
    typeClass,
    student,
    typeClassroom,
    before, 
    after} = UseSurveySectionResponseFilterModules.getState();
  try {
    let url = `/dashboard/survey_response_resume?before=${before}&after=${after}`;
    if (level) url += `&level=${level}`;
    if (campus) url += `&campus=${campus}`;
    if (activity) url += `&activity=${activity}`;
    if (teacher) url += `&teacher=${teacher}`;
    if (stars) url += `&stars=${stars}`;
    if (typeClass) url += `&typeClass=${typeClass}`;
    if (student) url += `&student=${student}`;
    if (typeClassroom) url += `&typeClassroom=${typeClassroom}`;

    const response: any = await api.get(
      url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (e:any) {
    catchServerError(e);
  }
}