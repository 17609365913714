import { api } from '../../../../auth/services/auth.services';
import useAuth from '../../../../../api/main';
import { showSimpleAlert } from '../../../../../commonHooks/alert';
import { UseUserModule } from '../../store/main'
import { TrailUpgradeStudent } from '../../interfaces';
import { PromotionHistoryType } from '../../../student-actions/promotion-history/interfaces';
import { catchServerError } from '../../../../../hooks/useCatchError';

const { token, logout, authChecked } = useAuth.getState()

type Props = {
  root: string
  isActive: boolean
  before?: Date,
  after?: Date,
  limit: number,
  page: number
}

interface UpgradeStudentInterface {
  studentSetting: string,
  newCurrentStep: string,
  promotionType: PromotionHistoryType,
  date: string,
  hasLessons: boolean,
  taskId?: string,
  additionalHours?: number,
  description?: string
}

export const UpgradeStudentAdd = async (data: TrailUpgradeStudent): Promise<any> => {
  try {
    const response: any = await api.post(
      '/trail-upgrade-student',
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    showSimpleAlert({ message: 'Ok!', icon: 'success' })
    return response.data

  } catch (e: any) {
    catchServerError(e.response);
  }
}

export const UpgradeStudentUpdate = async (data: TrailUpgradeStudent): Promise<any> => {
  try {
    const { itemSelected } = UseUserModule.getState()
    const response: any = await api.patch(
      `/trail-upgrade-student/${itemSelected?.id}`,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (e: any) {
    catchServerError(e.response);

  }
}

export const UpgradeStudentAll = async (): Promise<any> => {
  try {
    const response: any = await api.patch(
      `/trail-upgrade-student`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    return response.data
  } catch (e: any) {
    catchServerError(e.response);
  }
}

export const UseTrailUpgradeStudent = async ({ root, isActive, before, after, limit, page }: Props): Promise<any> => {

  try {
    const response = await api.get(`/trail-upgrade-student/user?root=${root}&isActive=${isActive ? 1 : 0}&before=${before}&after=${after}&limit=${limit}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    return response.data
  } catch (e: any) {
    catchServerError(e.response);
  }
}

export const FindHoursExperienceByStudent = async (id: string): Promise<any> => {
  try {
    const response = await api.get(`/trail-upgrade-student/find-hours/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    return response.data;
  } catch (e: any) {
    catchServerError(e.response)
  }
}

export const UpgradeStudentManual = async (data: UpgradeStudentInterface): Promise<any> => {
  if (data.taskId === '') delete data.taskId
  try {
    const response = await api.patch(`/trail-upgrade-student/by-experience`,
      {
        ...data
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    return response.data;
  } catch (e: any) {
    catchServerError(e.response);
  }
}