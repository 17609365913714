import React from 'react';
import AllExcel from '../../../../components/common/AllExcel';
import moment from 'moment';

export interface DayHourData {
    day: string;
    "6:00": number;
    "7:00": number;
    "8:00": number;
    "9:00": number;
    "10:00": number;
    "11:00": number;
    "12:00": number;
    "13:00": number;
    "14:00": number;
    "15:00": number;
    "16:00": number;
    "17:00": number;
    "18:00": number;
    "19:00": number;
    "20:00": number;
    "21:00": number;
    "22:00": number;
}

interface Props {
    data: DayHourData[];
}

const TableHourResume: React.FC<Props> = ({ data }) => {
    const theadName = [
        'Dia',
        "6:00",
        "7:00",
        "8:00",
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00"
    ];
    return (
        <div className=" mt-5">
            <h5 className="mb-4">Resumen de Citas por Día y Hora</h5>
            {data &&
                <div style={{
                    width:60
                }}>
                    <AllExcel
                        dataExcel={data}
                        filename={`reservas` + moment().format('yyyymmddhhmmsss')}
                        sheetName='Estudiantes'
                        theadName={theadName}
                        type='all'
                        backgroundColor={'green'}
                        titleColor={'white'}
                    />
                </div>}
            <table className="table table-bordered table-striped table-sm my-1">
                <thead className="table-dark">
                    <tr>
                        <th scope="col">Day</th>
                        <th scope="col">6:00</th>
                        <th scope="col">7:00</th>
                        <th scope="col">8:00</th>
                        <th scope="col">9:00</th>
                        <th scope="col">10:00</th>
                        <th scope="col">11:00</th>
                        <th scope="col">12:00</th>
                        <th scope="col">13:00</th>
                        <th scope="col">14:00</th>
                        <th scope="col">15:00</th>
                        <th scope="col">16:00</th>
                        <th scope="col">17:00</th>
                        <th scope="col">18:00</th>
                        <th scope="col">19:00</th>
                        <th scope="col">20:00</th>
                        <th scope="col">21:00</th>
                        <th scope="col">22:00</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td>{item.day}</td>
                            <td>{item["6:00"]}</td>
                            <td>{item["7:00"]}</td>
                            <td>{item["8:00"]}</td>
                            <td>{item["9:00"]}</td>
                            <td>{item["10:00"]}</td>
                            <td>{item["11:00"]}</td>
                            <td>{item["12:00"]}</td>
                            <td>{item["13:00"]}</td>
                            <td>{item["14:00"]}</td>
                            <td>{item["15:00"]}</td>
                            <td>{item["16:00"]}</td>
                            <td>{item["17:00"]}</td>
                            <td>{item["18:00"]}</td>
                            <td>{item["19:00"]}</td>
                            <td>{item["20:00"]}</td>
                            <td>{item["21:00"]}</td>
                            <td>{item["22:00"]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableHourResume;