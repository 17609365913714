import React from 'react';
import { BasicVocabularyExerciseItem } from './components/item';
import { UseSpeakOutModule } from './store';
import { ModeTypeExercise } from '../../../book/components/RenderExerciseBook';

type Props = {
    userMode?: ModeTypeExercise
};

const SpeakOutExercise: React.FC<Props> = ({ userMode }) => {
    const { 
        studentAnswer,
        mode,
    } = UseSpeakOutModule();
    
    return (
    <>
        {mode &&
            <BasicVocabularyExerciseItem
                userMode={userMode}
                type={mode}
                studentAnswer= {studentAnswer}
            /> 
        }
    </>
    );
};

export { SpeakOutExercise };