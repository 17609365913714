import React from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { useStudentSettingState } from '../../../admin/user/hooks/useUserRepo'
import { UseUserModule } from '../../../admin/user/store/main'
import { FilterStudentSetting } from '../../../admin/user/interfaces'
import { showSimpleAlert } from '../../../../commonHooks/alert'
import { StudentSetting } from '../../../booking-dashboard/interfaces'
import Swal from 'sweetalert2'
import GenericPaginator from '../../../../components/common/Paginator'
import { AppointmentStatus } from '../../../admin/appointment/interfaces'
import { DraggableModal } from '../../../book/components/modal'

type Props = {
    setStudent: (value: StudentSetting) => void;
}
const UserSearchStudent: React.FC<Props> = ({setStudent}: Props) => {
    const queryClient = useQueryClient()
    const [show, setShow] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [studentSelected, setStudentSelected] = React.useState<StudentSetting>();
    const { data, isLoading, isFetching } = useStudentSettingState();
    const {
        limit,
        paginator,
        currentPage,
        filterStudent,
        where,
        setWhere,
        setLimit,
        setCurrentPage,
        setFilterStudent } = UseUserModule();

    const memorizedValues = React.useMemo(() => ({
        where, filterStudent, limit, currentPage
    }), [where, filterStudent, limit, currentPage]);

    const changePage = (number: number) => {
        setCurrentPage(number)
    }
    const changeLimit = (number: number) => {
        setLimit(number)
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            html: "<p class='poppins-light'>You won't be able to revert this!</p>",
            icon: 'warning',
            iconColor: '#2d30e1',
            showCancelButton: true,
            cancelButtonColor: '#d33',
            confirmButtonText: "<span class='poppins-light fs-4'>Yes!</span>",
            cancelButtonText: "<span class='poppins-light fs-4'>No</span>",
            customClass: {
                title: 'champ-light',
                confirmButton: 'confirm-button-sweet-alert',
                cancelButton: 'cancel-button-sweet-alert',
            },
        }).then(async (res) => {
            if (!studentSelected)
                return showSimpleAlert({ message: 'No hay ningun estudiante seleccionado', icon: 'error' });
            if (!res.isConfirmed) return;
            try {
                setLoading(true);
                showSimpleAlert({ message: 'Clase Reservada!', icon: 'success' })
            } catch (e) {

            } finally {
                setLoading(false);
            }
        })
        queryClient.invalidateQueries({ queryKey: ['appointment'] });
    };

    React.useEffect(() => {
        queryClient.invalidateQueries({ queryKey: ['student-setting'] });
        setStudentSelected(undefined)
    }, [memorizedValues]);

    return (
        <div style={{ width: 300 }}>


            {/* begin::Header */}

            <div className='card-body border-top p-8 row' /*style={{ height: 530 }}*/>
                <div className=''>
                    <div className='row'>
                        <div className='col-12'>
                            <div className=''>
                                <p className='d-flex'>
                                    Estudiante Seleccionado:
                                </p>
                                {studentSelected && <>
                                    <div className='d-flex align-items-center'>
                                        <div className='d-flex justify-content-start flex-column'>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                {studentSelected.user.fullName}
                                            </a>
                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                {studentSelected.user.email}
                                            </span>
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </div>
                        <div className='mb-1 col-12'>
                            <div className='position-relative my-1' style={{ width: '100%' }}>
                                <i className='fa-solid fa-magnifying-glass svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ps-10'></i>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid ps-10'
                                    placeholder=''
                                    name={'where'}
                                    value={where}
                                    onClick={()=>setShow(true)}
                                    onChange={(e) => setWhere(e.target.value)}
                                />
                            </div>
                            <div>
                            </div>
                            {/* <label className='form-label mb-0'>Inicio</label> */}
                        </div>
                        <div className='mb-1 col-12 d-flex align-items-center'>
                            <select
                                name='teacher'
                                value={filterStudent}
                                onChange={(e) => setFilterStudent(e.target.value as FilterStudentSetting)}
                                className='form-select-sm'
                                style={{ width: '100%' }}
                                aria-label='Default select example'
                            >
                                {Object.keys(FilterStudentSetting).map((x) => (
                                    <option key={x} value={x}>
                                        {x}
                                    </option>
                                ))}
                            </select>
                        </div>


                        {!studentSelected && show && <DraggableModal >
                            <div className='col-12 card p-2' style={{ width: 400,opacity:0.8,background:'blue'}}>
                                <h3 className='text-white text-center'> Estudiantes </h3>
                                <div className='card-body py-3'>
                                    {!isLoading && data && (
                                        <div className='table-responsive'>

                                            <ol className="list-group  ">
                                                {data.result.map((x: any) => (<li className="list-group-item d-flex justify-content-between align-items-start list-group-item-action cursor-pointer"
                                                    onClick={() => {
                                                        setStudentSelected(x)
                                                        setStudent(x)
                                                    }}
                                                >
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">{x.user.fullName}</div>
                                                        {x.user.email}
                                                    </div>

                                                </li>))}
                                            </ol>

                                            {/* <GenericPaginator
                                                limit={limit}
                                                currentPage={paginator.current_page}
                                                items={paginator.result.length}
                                                total={paginator.total}
                                                totalPage={paginator.total_page}
                                                changePage={changePage}
                                                changeLimit={changeLimit}
                                            /> */}
                                            {/* end::Table */}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </DraggableModal>}
                    </div>
                </div>
            </div>



        </div>
    )
}

export { UserSearchStudent }
